define("client/pods/blogs/lists/font-family/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model", "client/constants"], function (_exports, _adapter, _adaptersNew, _nventor, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const ss = ', sans-serif';
      const ms = ', monospace';
      const data = [{
        value: `Arial Black, Gadget${ss}`,
        label: 'Arial Black'
      }, {
        value: `Arial, Helvetica${ss}`,
        label: 'Arial'
      }, {
        value: `Brush Script MT${ss}`,
        label: 'Brush Script MT'
      }, {
        value: `Comic Sans MS, cursive${ss}`,
        label: 'Comic Sans MS'
      }, {
        value: `Courier New, Courier${ms}`,
        label: 'Courier New'
      }, {
        value: `Georgia, serif${ss}`,
        label: 'Georgia'
      }, {
        value: `Helvetica${ss}`,
        label: 'Helvetica'
      }, {
        value: `Impact, Charcoal${ss}`,
        label: 'Impact'
      }, {
        value: `Lucida Sans Unicode, Lucida Grande${ss}`,
        label: 'Lucida Sans Unicode'
      }, {
        value: `Noto Sans TC${ss}`,
        label: 'Noto Sans TC'
      }, {
        value: `Noto Serif TC${ss}`,
        label: 'Noto Serif TC'
      }, {
        value: `Tahoma, Geneva${ss}`,
        label: 'Tahoma'
      }, {
        value: `Times New Roman, Times, serif${ss}`,
        label: 'Times New Roman'
      }, {
        value: `Trebuchet MS, Helvetica${ss}`,
        label: 'Trebuchet MS'
      }, {
        value: `Verdana, Geneva${ss}`,
        label: 'Verdana'
      }];
      return _nventor.default.resolve(data);
    }
  });
});