define("client/pods/components/home/wizard-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "b9i1rVsO",
    "block": "{\"symbols\":[\"formInitData\",\"wizardItem\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@formGroupclassNames\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@isUserAllowed\",\"@formInitData\",\"@tab\"],[\"setupWizards\",false,true,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"setupWizards\",[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]]]]],[23,5,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"forms/form-group\",[[12,\"class\",[23,7,[]]]],[[\"@tab\",\"@context\",\"@formInitData\",\"@title\"],[[23,5,[]],\"wizards_group\",[23,1,[]],\"setup wizards\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"filteredList\"]]],null,{\"statements\":[[0,\"          \"],[5,\"home/wizard-container/wizard-card\",[],[[\"@wizardItem\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/home/wizard-container/template.hbs"
    }
  });
});