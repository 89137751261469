define("client/pods/components/flows/flows-editor/steps-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FlowsFlowsEditorStepsEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _class = class FlowsFlowsEditorStepsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dragAndDrop", _descriptor2, this);
      _initializerDefineProperty(this, "application", _descriptor3, this);
      _initializerDefineProperty(this, "currentStepId", _descriptor4, this);
      _initializerDefineProperty(this, "currentStep", _descriptor5, this);
      _initializerDefineProperty(this, "currentSubStep", _descriptor6, this);
      _initializerDefineProperty(this, "currentSubStepId", _descriptor7, this);
      _initializerDefineProperty(this, "currentDraggedIndex", _descriptor8, this);
      _initializerDefineProperty(this, "isAllValidated", _descriptor9, this);
      const steps = this.args.steps || [];
      const firstStep = steps?.firstObject || {};
      this.currentStep = firstStep;
      this.currentStepId = firstStep?._data?.stepId;
    }
    get allStepsAndSubSteps() {
      const steps = this.args.steps || [];
      return R.reduce((acc, step) => {
        const subSteps = R.propOr([], 'subSteps')(step);
        return R.concat(acc, [step, ...subSteps]);
      }, [])(steps);
    }
    checkIsAllValidated(steps) {
      return R.reduce((acc, step) => {
        if (acc === false) {
          return false;
        }
        const isSubStepsValidated = this.checkIsAllSubStepsValidated(step);
        if (!isSubStepsValidated) {
          return false;
        }
        return step?._data?.isValidated || false;
      }, null)(steps);
    }
    checkIsAllSubStepsValidated(step) {
      const subSteps = R.propOr([], 'subSteps')(step);
      let isSubStepsValidated = true;
      if (RA.isNotNilOrEmpty(subSteps)) {
        isSubStepsValidated = R.reduce((acc, subStep) => {
          if (acc === false) {
            return false;
          }
          return subStep?._data?.isValidated || false;
        }, null)(subSteps);
      }
      return isSubStepsValidated;
    }
    *completeStepsTask(onDone) {
      if (this.isAllValidated) {
        return onDone();
      }
      const validated = yield this.validateStepsTask.perform();
      if (validated) {
        return onDone();
      }
    }
    *validateStepsTask() {
      const allStepsAndSubSteps = this.allStepsAndSubSteps || [];
      const q = [];
      R.forEach(step => {
        q.push(this.validateStepTask.perform(step));
      })(allStepsAndSubSteps);
      const steps = yield (0, _emberConcurrency.all)(q);
      return steps;
    }
    *validateStepTask(step) {
      if (RA.isNilOrEmpty(step)) {
        step = this.currentStep;
      }
      if (!step) {
        return false;
      }
      const res = yield this.crud.validateRecordTask.perform({
        adapterName: 'flows/steps',
        model: step
      });
      let isValidated = false;
      if (RA.isNotNilOrEmpty(res)) {
        isValidated = true;
        const isAllSubStepsValidated = this.checkIsAllSubStepsValidated(step);
        if (!isAllSubStepsValidated) {
          isValidated = false;
        }
      }
      step.setData('isValidated', isValidated);
      const steps = this.args.steps || [];
      this.isAllValidated = this.checkIsAllValidated(steps);
      return step;
    }
    *validateSubStepTask(step, subStep) {
      if (RA.isNilOrEmpty(subStep)) {
        subStep = this.currentSubStep;
      }
      if (!subStep) {
        return false;
      }
      const res = yield this.crud.validateRecordTask.perform({
        adapterName: 'flows/steps',
        appendPath: '/sub',
        model: subStep
      });
      if (RA.isNilOrEmpty(res)) {
        subStep.setData('isValidated', false);
      } else {
        subStep.setData('isValidated', true);
      }

      // validate parent step
      yield this.validateStepTask.perform(step);
      return subStep;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "application", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentStepId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentStep", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentSubStep", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentSubStepId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentDraggedIndex", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isAllValidated", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkIsAllValidated", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "checkIsAllValidated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkIsAllSubStepsValidated", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "checkIsAllSubStepsValidated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completeStepsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "completeStepsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateStepsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "validateStepsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "validateStepTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateSubStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "validateSubStepTask"), _class.prototype), _class);
});