define("client/pods/components/menus/nav-tree/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+D+VUgzt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"nav-tree__item flex-container-row\"],[8],[0,\"\\n\"],[0,\"    \"],[7,\"div\",false],[12,\"class\",\"nav-tree__item-label\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"onClick\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"_data\",\"icon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[28,\"elements/element-icon\",null,[[\"classNames\",\"icon\"],[\"nav-tree__icon u-width-25\",[24,[\"item\",\"_data\",\"icon\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[23,0,[\"_translate\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"nav-tree__item-fav\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"pinTask\"]],[28,\"not-eq\",[[24,[\"item\",\"_data\",\"pinLabel\"]],\"favorites\"],null]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"item\",\"_data\",\"isPinned\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-pushbtn\",null,[[\"icon\",\"classNames\",\"isRunning\",\"onSubmit\"],[\"fas fa-star\",\"flex-container--align-content-end element-icon--s\",[23,0,[\"pinTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"pinTask\"]],[24,[\"wizardItem\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-pushbtn\",null,[[\"icon\",\"classNames\",\"isRunning\",\"onSubmit\"],[\"far fa-star\",\"flex-container--align-content-end element-icon--s\",[23,0,[\"pinTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"pinTask\"]],[24,[\"wizardItem\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-tree/nav-item/template.hbs"
    }
  });
});