define("client/pods/components/websites/links-editor/component", ["exports", "ramda", "ramda-extension", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, R, R_, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsLinksModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('websites'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _class = class GrapesjsLinksModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "websitesService", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _initializerDefineProperty(this, "selectedFilter", _descriptor6, this);
      _initializerDefineProperty(this, "selectedAdapter", _descriptor7, this);
      _initializerDefineProperty(this, "allFilters", _descriptor8, this);
      _initializerDefineProperty(this, "allSetupData", _descriptor9, this);
      _initializerDefineProperty(this, "query", _descriptor10, this);
      this.crud.addLists(this, ['websites/lists/types/links', 'websites/lists/types/links/targets']);
      this._setup.perform();
    }
    _setupFilters(linkType, setupData) {
      const allFilters = this.allFilters;
      const filter = this.search.setupFilters(setupData.filterData);
      allFilters[linkType] = filter;
      this.allFilters = allFilters;
    }
    *_setup() {
      let websiteKey = this.args?.websiteKey || '';
      let shopKey = this.args?.shopKey || '';
      if (shopKey) {
        websiteKey = shopKey;
      }
      const website = this.args.website;
      if (website) {
        websiteKey = website.getData('_key');
        shopKey = website.getData('shopKey');
      }
      const allSetupData = {
        pageAndCategory: {
          adapterName: 'websites/pages',
          filterData: {
            adapterName: 'websites/pages/filters',
            defaultFiltersData: {
              master: websiteKey,
              itemLabelKey: '_data.name',
              itemValueKey: '_data._key',
              resultsItemComponent: 'websites/search-results/links'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: 'pageName'
            },
            linkResourceName: {
              dotPath: 'pageName'
            },
            linkResourceType: {
              dotPath: '_data.type'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        product: {
          adapterName: 'channels/shop/campaigns/details',
          filterData: {
            adapterName: 'channels/shop/campaigns/details/filters',
            defaultFiltersData: {
              master: shopKey,
              itemLabelKey: '_data.item',
              itemValueKey: '_data._itemKey',
              resultsItemComponent: 'channels/shop/items/search-results/links'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.item'
            },
            linkResourceName: {
              dotPath: '_data.item'
            },
            linkResourceType: {
              value: 'product'
            },
            linkResourceKey: {
              dotPath: '_data.itemKey'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        blog: {
          adapterName: 'blogs',
          filterData: {
            adapterName: 'blogs/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'blogs/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'blog'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        news: {
          adapterName: 'news',
          filterData: {
            adapterName: 'news/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'news/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'news'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        storeLocation: {
          adapterName: 'store-location',
          filterData: {
            adapterName: 'store-location/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'store-location/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'store-location'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        flows: {
          adapterName: 'flows',
          filterData: {
            adapterName: 'flows/filters',
            defaultFiltersData: {
              itemLabelKey: '_data.name',
              itemValueKey: '_data._key',
              websiteKey,
              shopKey
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'flows'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        }
      };
      this.allSetupData = allSetupData;
      R.mapObjIndexed((setupData, linkType) => {
        this._setupFilters(linkType, setupData);
      })(allSetupData);
      yield this._selectTypeTask.perform();
    }
    *_selectTypeTask() {
      const model = this.args.model;
      const linkType = model?._data?.linkType;
      this.selectedFilter = this.allFilters[linkType];
      if (!this.selectedFilter) {
        this.selectedFilter = {};
        this.selectedAdapter = {};
        return;
      }
      const adapterData = this.allSetupData[linkType];
      this.selectedAdapter = adapterData;
      yield this.crud.searchRecordsTask.perform({
        adapterName: adapterData.adapterName,
        appendPath: adapterData.appendPath,
        filters: this.selectedFilter,
        component: this
      });
    }
    *onChangeTypeTask() {
      this.unloadResource();
      yield this._selectTypeTask.perform();
    }
    loadResource(selected) {
      const model = this.args.model;
      const linkType = model?._data?.linkType;
      const toLoadDataFromModel = R.pathOr({}, ['allSetupData', linkType, 'toLoadDataFromModel'])(this);
      R.mapObjIndexed((getValueInstructions, toSaveKey) => {
        if (getValueInstructions.value) {
          model.setData(toSaveKey, getValueInstructions.value);
          return;
        }
        const value = R_.dotPathOr('', getValueInstructions.dotPath)(selected);
        console.log('-----toSaveKey ' + toSaveKey + ' : ' + getValueInstructions.dotPath + ' = ' + value);
        model.setData(toSaveKey, value);
      })(toLoadDataFromModel);
    }
    unloadResource() {
      const model = this.args.model;
      model.setData('linkTitle', '');
      model.setData('linkResourceName', '');
      model.setData('linkResourceKey', '');
      model.setData('linkResourceType', '');
      model.setData('linkUrl', '');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websitesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedAdapter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "allFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allSetupData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_selectTypeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_selectTypeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeTypeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTypeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadResource", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadResource", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "unloadResource"), _class.prototype), _class);
});