define("client/pods/extensions/apps/tw/e-invoices/service", ["exports", "client/config/environment", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _environment, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj; // import AjaxMixin from '../../mixins/ajax'
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * performs and manages connections to servers
   */
  var _default = _exports.default = Ember.Service.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    server: Ember.inject.service(),
    intl: Ember.inject.service(),
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    host: _environment.default.APP.serverUrl,
    printerData: {},
    init() {
      this._super(...arguments);
      const printerData = Ember.Object.create({
        twEInvoiceUserKey: null,
        twEInvoicePrinterId: null,
        twEInvoiceAllowPrintingFrom: null,
        printerKey: null
      });
      this.set('printerData', printerData);
    },
    fetchToIssueEInvoicesTask: (0, _emberConcurrency.task)(function* ({
      component,
      adapterName = 'extensions/apps/tw/e-invoices/to-issue',
      appendPath,
      filters,
      period = {},
      dispatchProcess,
      includeBeforeStartDate,
      twEInvoiceUserKey,
      twEInvoiceAllowPrintingFrom,
      onlyRemaining = false
    } = {}) {
      const data = {
        isByDispatchProcess: !period.date,
        dateEnd: period.date || ''
      };
      if (!includeBeforeStartDate) {
        data.dateStart = period.monthStart || period.date;
        data.dateEnd = period.monthEnd;
      }
      if (dispatchProcess && !period.date) {
        data.dispatchProcess = dispatchProcess.serialize();
      }
      data.twEInvoiceUserKey = twEInvoiceUserKey;
      data.twEInvoiceAllowPrintingFrom = twEInvoiceAllowPrintingFrom;
      if (onlyRemaining) {
        data.onlyRemaining = onlyRemaining;
      }
      try {
        const results = yield this.crud.searchRecordsTask.perform({
          adapterName,
          appendPath,
          filters,
          data
        });
        filters.set('fullCount', results?.fullCount);
        Ember.set(component, 'toIssue', R.pathOr([], ['toIssue'])(results));
        Ember.set(component, 'invalid', R.pathOr([], ['invalid'])(results));
        Ember.set(component, 'noAvailableNumbers', R.pathOr([], ['noAvailableNumbers'])(results));
        Ember.set(component, 'invalidCount', R.pathOr([], ['invalidCount'])(results));
        Ember.set(component, 'toIssueCount', R.pathOr([], ['toIssueCount'])(results));
        return results;
      } catch (err) {
        console.error('error fetching to issue', err);
        // this.setValidationErrors('notifyErrors', err)
      }
    }),
    resetToIssueInvoicesData(component) {
      Ember.set(component, 'toIssue', []);
      Ember.set(component, 'invalid', []);
      Ember.set(component, 'noAvailableNumbers', []);
      Ember.set(component, 'invalidCount', []);
      Ember.set(component, 'toIssueCount', []);
    },
    fetchTrackNumbers: (0, _emberConcurrency.task)(function* ({
      component,
      resultProp = 'track',
      mm = '',
      yyyy = '',
      period
    } = {}) {
      if (period) {
        mm = period.mm;
        yyyy = period.yyyy;
      }
      if (!mm || !yyyy) {
        const currentPeriod = this.dateService.getPeriod();
        mm = currentPeriod.mm;
        yyyy = currentPeriod.yyyy;
      }
      try {
        const trackModel = yield this.crud.findTask.perform({
          adapterName: 'extensions/apps/tw/e-invoices/tracks',
          params: {
            mm,
            yyyy
          }
        });
        if (component && resultProp) {
          component[resultProp] = trackModel;
        }
        return trackModel;
      } catch (error) {
        console.error('Error while fetching track numbers: ', error);
      }
      return null;
    }),
    uploadBlanksTask: (0, _emberConcurrency.task)(function* ({
      mm = '',
      yyyy = '',
      period,
      uploadUnusedBlanks
    } = {}) {
      if (period) {
        mm = period.mm;
        yyyy = period.yyyy;
      }
      return yield this.server.call('POST', 'api/protected/extensions/apps/tw/e-invoices/upload-blanks', {
        mm,
        yyyy,
        uploadUnusedBlanks
      });
    }),
    customPaginateSearchRecordsTask: (0, _emberConcurrency.task)(function* (args) {
      const component = R.prop('component')(args);
      const filters = R.prop('filters')(args);
      const results = yield this.crud.paginateSearchRecordsTask.perform({
        filters
      });
      if (component) {
        Ember.set(component, 'toIssue', R.pathOr([], ['toIssue'])(results));
        Ember.set(component, 'invalid', R.pathOr([], ['invalid'])(results));
        Ember.set(component, 'invalidCount', R.pathOr([], ['invalidCount'])(results));
        Ember.set(component, 'toIssueCount', R.pathOr([], ['toIssueCount'])(results));
      }
    }),
    issueAllEInvoicesForPeriodTask: (0, _emberConcurrency.task)(function* (args = {}) {
      const {
        component,
        onCloseDialogAction,
        period = {},
        dispatchProcess,
        includeBeforeStartDate,
        twEInvoicePrint = false,
        twEInvoicePrinterId = null,
        printerKey = null,
        twEInvoiceUserKey = null,
        twEInvoiceAllowPrintingFrom = null
      } = args;
      const payload = {
        isByDispatchProcess: !period.date,
        dateEnd: period.date || '',
        twEInvoicePrint,
        twEInvoicePrinterId,
        printerKey,
        twEInvoiceUserKey,
        twEInvoiceAllowPrintingFrom
      };
      if (!includeBeforeStartDate) {
        payload.dateStart = period.monthStart || period.date;
        payload.dateEnd = period.monthEnd;
      }
      if (dispatchProcess && !period.date) {
        payload.dispatchProcess = dispatchProcess.serialize();
      }
      Ember.set(component, 'printErrors', []);
      try {
        Ember.set(component, 'hasResults', false);
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issueBatch({
          payload
        });
        onCloseDialogAction();
        const message = this.intl.t('tw_eInvoice_queued_successfully');
        window.alert(message);
        return results;
      } catch (err) {
        this.crud.setValidationErrors('printErrors', err, component);
        return false;
      }
    }).drop(),
    issueTask: (0, _emberConcurrency.task)(function* (component, onCloseDialogAction, document, custom, invoiceModel) {
      const invoiceKey = invoiceModel?._data?._key;
      const status = invoiceModel?._data?.status;
      const isUnresolved = invoiceModel?._data?.isUnresolved;
      if (status === this.constants.eInvoicesStatus.attentionRequired && isUnresolved) {
        custom.isUnresolved = false;
      }
      const payload = {
        _key: document.getData('_key'),
        invoiceKey,
        custom
      };
      Ember.set(component, 'issueErrors', []);
      try {
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
          payload
        });
        onCloseDialogAction();
        const message = this.intl.t('tw_eInvoice_queued_successfully');
        window.alert(message);
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }).drop(),
    /**
     * reprint successful e-invoices using printer
     */
    rePrintEInvoicesTask: (0, _emberConcurrency.task)(function* (component, document, custom) {
      const msg = this.intl.t('are you sure you want to reprint');
      if (window.confirm(msg)) {
        const appendPath = `/${document.getData('_key')}`;
        const payload = {
          printerId: custom?.twEInvoicePrinterId
        };
        Ember.set(component, 'issueErrors', []);
        try {
          return yield this.server.getAdapter('extensions/apps/tw/e-invoices').rePrint({
            appendPath,
            payload
          });
        } catch (err) {
          this.crud.setValidationErrors('issueErrors', err, component);
          return false;
        }
      }
    }).drop(),
    issueAndPrintTask: (0, _emberConcurrency.task)(function* (component, onCloseDialogAction, document, custom, invoiceModel) {
      const invoiceKey = invoiceModel?._data?._key;
      const status = invoiceModel?._data?.status;
      const isUnresolved = invoiceModel?._data?.isUnresolved;
      if (status === this.constants.eInvoicesStatus.attentionRequired && isUnresolved) {
        custom.isUnresolved = false;
      }
      custom.twEInvoicePrint = true;
      Ember.set(component, 'issueErrors', []);
      const payload = {
        _key: document.getData('_key'),
        invoiceKey,
        custom
      };
      let results;
      try {
        results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
          payload
        });
        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }).drop(),
    setupTaxDetails(model, companyDetails) {
      if (companyDetails.isUnsuccessful) {
        model.setData('companyName', '');
        model.setData('twEInvoiceCarrierId', '');
      }
      const value = companyDetails.value || companyDetails.taxNumber || '';
      const isMobileBarcode = value.startsWith('/');
      if (!value || isMobileBarcode && model?._data?.twEInvoiceCarrierType === 'b2b') {
        return;
      }
      let twEInvoiceCarrierType = '';
      const hasCompanyDetails = RA.isNotNilOrEmpty(companyDetails);
      if (hasCompanyDetails) {
        const companyName = companyDetails?.name || '';
        const taxNumber = companyDetails?.taxNumber || '';
        twEInvoiceCarrierType = 'b2b';
        model.setData('companyName', companyName);
        model.setData('taxNumber', taxNumber);
        model.setData('twEInvoiceCarrierId', '');
      } else {
        model.setData('companyName', '');
        model.setData('taxNumber', '');
      }
      if (isMobileBarcode) {
        twEInvoiceCarrierType = 'mobile';
        model.setData('twEInvoiceCarrierId', value);
        model.setData('taxNumber', '');
      } else {
        model.setData('twEInvoiceCarrierId', '');
      }
      model.setData('twEInvoiceCarrierType', twEInvoiceCarrierType);
    },
    fetchAvailableCountToIssueTask: (0, _emberConcurrency.task)(function* ({
      userKey
    } = {}) {
      let path = 'api/protected/extensions/apps/tw/e-invoices/available-count';
      if (userKey) {
        path = `${path}/${userKey}`;
      }
      const availableCountData = yield this.server.call('GET', path);
      return availableCountData?.data || {};
    })
  }, _applyDecoratedDescriptor(_obj, "setupTaxDetails", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupTaxDetails"), _obj), _obj));
});