define("client/pods/components/dispatch/processes-item-waybills-seven-eleven/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V08jyJrF",
    "block": "{\"symbols\":[\"groupData\",\"@waybillProcessModel\",\"@isRunning\"],\"statements\":[[4,\"if\",[[23,2,[\"showBtnForStatus\"]]],null,{\"statements\":[[0,\"  \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@classNames\",\"@hasBadge\",\"@badge\",\"@badgeClassNames\",\"@onSubmit\",\"@disabled\",\"@isRunning\"],[[23,2,[\"label\"]],[23,2,[\"btnClassNames\"]],[23,0,[\"hasWaybillProcessReportCount\"]],[23,0,[\"waybillProcessReportCount\"]],[23,0,[\"badgeClassNames\"]],[28,\"fn\",[[23,0,[\"displayWaybillProxy\"]],[28,\"hash\",null,[[\"waybillLastStatus\"],[\"\"]]]],null],[23,0,[\"isDispatchedWaybills\"]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isDispatchedWaybills\"]]],null,{\"statements\":[[4,\"each\",[[23,0,[\"waybillProcessReport\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"u-margin-left-10\"],[8],[0,\"\\n      \"],[5,\"dispatch/processes-item-waybills-seven-eleven/last-milestone\",[],[[\"@waybillProcessType\",\"@milestoneData\",\"@onSubmit\",\"@isRunning\"],[[23,2,[\"value\"]],[23,1,[]],[23,0,[\"displayWaybillProxy\"]],[23,3,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/processes-item-waybills-seven-eleven/template.hbs"
    }
  });
});