define("client/pods/components/websites/links-editor-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MpIn9dbf",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@modalTitleLabel\",\"@isReadonly\",\"@website\",\"@shopKey\",\"@websiteKey\"],\"statements\":[[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@label\",\"@translate\",\"@disabled\",\"@closeOnOverlayClick\"],[[23,2,[]],false,[23,3,[]],false]],{\"statements\":[[0,\"\\n  \"],[5,\"websites/links-editor\",[],[[\"@title\",\"@model\",\"@website\",\"@isReadonly\",\"@shopKey\",\"@websiteKey\"],[\"page url\",[23,0,[\"tempModel\"]],[23,4,[]],[23,3,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-save\",[],[[\"@label\",\"@onSubmit\"],[\"\",[28,\"fn\",[[23,0,[\"updateLink\"]],[23,1,[]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/links-editor-modal/template.hbs"
    }
  });
});