define("client/pods/components/barcode/generator/component", ["exports", "@glimmer/component", "jsbarcode", "ramda"], function (_exports, _component, _jsbarcode, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  /* global JsBarcode */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BarcodeGeneratorComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _class = class BarcodeGeneratorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "barcodeDomElement", _descriptor, this);
      _initializerDefineProperty(this, "hasBarcodeError", _descriptor2, this);
      _defineProperty(this, "svgId", `barcode-svg-${Ember.guidFor(this)}`);
    }
    get label() {
      const label = this.args.label;
      if (!label) {
        return '';
      }
      const labelLength = this.args.labelLength || 0;
      if (labelLength >= 1) {
        return label.slice(0, labelLength);
      }
      return label;
    }
    setBarcode(value, format) {
      try {
        const svgElement = document.querySelector(`#${this.svgId}`);
        this.barcodeDomElement = document.createElement('img');
        const barcodeValue = this.cropString(value, format);
        const options = {
          format,
          margin: 0,
          height: 70,
          width: 2,
          // fontSize: 18,
          textMargin: 0
          // flat: true
        };
        (0, _jsbarcode.default)(svgElement, barcodeValue, options);
      } catch (error) {
        this.hasBarcodeError = true;
      }
    }
    cropString(str = '', format) {
      // Max Length
      const upc = 12;
      const ean8 = 7;
      const ean5 = 5;
      const ean2 = 2;
      switch (format) {
        case 'UPC':
          return R.take(upc)(str);
        case 'EAN8':
          return R.take(ean8)(str);
        case 'EAN5':
          return R.take(ean5)(str);
        case 'EAN2':
          return R.take(ean2)(str);
        default:
          return str;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "barcodeDomElement", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasBarcodeError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setBarcode", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setBarcode"), _class.prototype), _class);
});