define("client/pods/components/user-login/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    auth: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),
    updates: Ember.inject.service(),
    username: '',
    password: '',
    code: '',
    step: 0,
    resentCode: false,
    twoFactorAuthTries: 0,
    init() {
      this.crud.addLists(this, ['languages']);
      this._super(...arguments);
    },
    goToVersion(version) {
      if (version === 'beta') {
        window.location.href = 'https://beta.bigordr.com';
      } else {
        window.location.href = 'https://app.bigordr.com';
      }
    },
    actions: {
      async login() {
        this.set('resentCode', false);
        this.set('code', '');
        this.set('step', 0);
        this.set('twoFactorAuthTries', 0);
        const credentials = {
          username: this.username,
          password: this.password
        };
        const loginResponse = await this.auth.get('loginTask').perform(credentials);
        if (loginResponse?.isTwoFactorAuth) {
          this.set('step', 1);
        }
      },
      async loginTwoFactor() {
        const code = this.code || '';
        if (!code) {
          // set error
          return;
        }
        const loginSuccessfully = await this.auth.get('loginTwoFactorTask').perform(code);
        this.twoFactorAuthTries = Number(this.twoFactorAuthTries || 0) + 1;
        if (!loginSuccessfully) {
          this.set('code', '');
          if (this.twoFactorAuthTries >= 3) {
            this.set('step', 0);
          }
        }
      },
      async resendTwoFactorCode() {
        const hasSentSuccessfully = await this.auth.get('resendTwoFactorCodeTask').perform();
        if (hasSentSuccessfully) {
          this.set('resentCode', true);
        } else {
          this.set('resentCode', false);
          this.set('step', 0);
        }
      },
      async cancelTwoFactorAuth() {
        this.set('code', '');
        this.set('resentCode', false);
        this.set('step', 0);
        this.set('twoFactorAuthTries', 0);
        this.auth.resetLogin();
      },
      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }
    }
  }, _applyDecoratedDescriptor(_obj, "goToVersion", [_dec], Object.getOwnPropertyDescriptor(_obj, "goToVersion"), _obj), _obj));
});