define("client/pods/components/dispatch/panels-summary/component", ["exports", "ramda", "client/mixins/date", "client/mixins/emails-dispatched", "client/mixins/doc-status", "ember-concurrency", "ramda-extension"], function (_exports, R, _date, _emailsDispatched, _docStatus, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, _docStatus.default, _emailsDispatched.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.tabsManager.setHeader(this.tabParent, this.intl.t('inventory status report'));
      this.fetchDispatchSummaryTask.perform();
    },
    dispatchProcessesList: Ember.computed('crud.lists.dispatch-lists-processes-menu.[]', function () {
      const dispatchProcessesList = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const isDispatchedProcessModel = this.isDispatchedProcessModel;
      return R.concat(dispatchProcessesList, [isDispatchedProcessModel]);
    }),
    fetchDispatchSummaryTask: (0, _emberConcurrency.task)(function* () {
      const filterData = {
        dispatchProcessesCriterias: {
          dispatchProcesses: [{
            criteria: 'notEquals',
            value: 'dispatched'
          }]
        }
      };
      const results = yield this.dispatch.fetchDispatchSummaryTask.perform({
        filterData
      });
      this.set('summaryData', results);
    })
  });
});