define("client/pods/components/color-preview/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ColorPreviewComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class ColorPreviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      // websites-lists-layout-btns-border-radius
      _initializerDefineProperty(this, "grapesjs", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "colors", _descriptor3, this);
      _defineProperty(this, "page", {
        pageDataJson: {
          html: `<div class=' fb_reset'
     id='fb-root'
>
  <div style='position: absolute; top: -10000px; width: 0px; height: 0px;'>
    <div></div>
  </div>
</div>
<div class='ember-view'
     id='ember3'
>
  <div id='ga-scripts'></div>
  <div>
    <div class='ember-view'
         id='ember4'
    >
      <div class='my-navbar-header'>
        <div class='my-navbar-container flex-container-row flex-container--justify-space-between'>
          <div class='flex-container-row flex-container--justify-start flex-container--align-items-center'>
            <span class='nav-item'></span>
            <span class='nav-item'>
                      <span class='logo-text button button--no-border button--no-border has-text-weight-bold' data-ember-action='' data-ember-action-5='5'>
                        <svg style='width: 30px'
                             viewBox='0 0 128 128'
                             xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M40.2,89l-12.8,28.8c-0.7,1.5,0,3.3,1.5,4c0.4,0.2,0.8,0.3,1.2,0.3c1.1,0,2.2-0.7,2.7-1.8l12.9-29c2,0.5,4.2,0.8,6.3,0.8 h23.6c2.2,0,4.4-0.3,6.5-0.9l12.9,29.1c0.5,1.1,1.6,1.8,2.7,1.8c0.4,0,0.8-0.1,1.2-0.3c1.5-0.7,2.2-2.4,1.5-4L87.9,88.9 c2.9-1.6,5.5-3.8,7.6-6.5c4.8-6.1,6.4-13.9,4.5-21.4l-9.4-37.5C88.1,13.2,79,6,68.4,6h-8.8C49,6,39.9,13.2,37.3,23.4l-9.4,37.5 c-1.9,7.5-0.2,15.3,4.5,21.4C34.6,85.1,37.3,87.4,40.2,89z M45.6,44.5l2.4-9.7C50.6,24.3,60,17,70.8,17h9.6 c2.1,2.1,3.7,4.8,4.5,7.9L94.2,62H59.3C50.1,62,43.4,53.4,45.6,44.5z M49.7,76.9c0.8,0.1,1.6,0.1,2.5,0.1h23.6 c8.3,0,15.2-4.5,19-11c0.2,4.5-1.1,9-4,12.7c-3.6,4.6-9.1,7.3-15,7.3H52.2c-3.3,0-6.4-0.8-9.1-2.3C42.6,80.2,45.2,76.3,49.7,76.9z'
                          ></path>
                        </svg>
                        Furniture Store
                      </span>
                    </span>
          </div>

          <div class='flex-container-row flex-container--justify-end flex-container--align-items-center'>
                    <span class='nav-item desktop-only desktop-only--inline-block'>
                      <div class='nav-menu__container horizontal right'>
                        <div class='nav-menu__content'>
                          <div id='ember3712' class='nav-tree ember-view'>
                            <!-- MENU DEPTH: 0 -->
                            <div id='ember3713' class='ember-view'>
                              <div class='nav-tree__item ' data-ember-action='' data-ember-action-3714='3714'>
                                <a href='/' class='nav-item__container is-expanded nav-item__link flex-container-row flex-container--justify-start flex-container--align-items-center   ' data-ember-action='' data-ember-action-3715='3715'>
                                    HOME
                                </a>
                              </div>
                            </div>
                            <!--  -->

                            <!-- MENU DEPTH: 0 -->
                            <div id='ember3716' class='ember-view'>
                              <div class='nav-tree__item ' data-ember-action='' data-ember-action-3717='3717'>
                                <a href='/shop/some-shop' class='nav-item__container is-expanded nav-item__link flex-container-row flex-container--justify-start flex-container--align-items-center   ' data-ember-action='' data-ember-action-3718='3718'>
                                    STORE
                                </a>
                              </div>
                            </div>
                            <!--  -->

                            <div id='ember3737' class='nav-nested ember-view'>
                              <div id='ember3738' class='ember-view'>
                                <!-- MENU DEPTH: 0 -->
                                <div class='nav-tree__item ' data-ember-action='' data-ember-action-3739='3739'>
                                  <div class='nav-item__container flex-container-row flex-container--justify-space-between flex-container--align-items-center'>
                                    <a href='/submenu-0' class='nav-item__link '>
                                        子選單
                                    </a>

                                    <span class='nav-item__toggler is-expandable'>
                                      <span>
                                        <i class='element-icon nav-item__toggler-icon fas fa-angle-up fa-rotate-180'></i>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <!--  -->
                              </div>

                              <div id='ember3769' class='nav-tree nav-tree__children-container nav-tree__children-container-scrollable ember-view'>
                                <div id='ember3770' class='nav-nested is-sub ember-view'>

                                  <div id='ember3771' class='ember-view'>
                                    <!-- MENU DEPTH: 1 -->
                                    <div class='nav-tree__item ' data-ember-action='' data-ember-action-3772='3772'>
                                      <div class='nav-item__container flex-container-row flex-container--justify-space-between flex-container--align-items-center'>
                                        <a href='/submenu-1' class='nav-item__link '>
                                            一些選項
                                        </a>

                                        <span class='nav-item__toggler is-expandable'>
                                          <span>
                                            <i class='element-icon nav-item__toggler-icon fas fa-angle-up fa-rotate-180'></i>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <!--  -->
                                  </div>

                                  <div id='ember3781' class='nav-tree nav-tree__children-container nav-tree__children-container-scrollable ember-view'>

                                    <div id='ember3782' class='nav-nested is-sub ember-view'>
                                      <div id='ember3783' class='ember-view'>
                                        <!-- MENU DEPTH: 2 -->
                                        <div class='nav-tree__item ' data-ember-action='' data-ember-action-3784='3784'>
                                          <div class='nav-item__container flex-container-row flex-container--justify-space-between flex-container--align-items-center'>
                                            <a href='/submenu-1-0' class='nav-item__link '>
                                                選項一
                                            </a>

                                            <span class='nav-item__toggler is-expandable'>
                                              <span>
                                                <i class='element-icon nav-item__toggler-icon fas fa-angle-up'></i>
                                              </span>

                                            </span>
                                          </div>
                                        </div>
                                        <!--  -->
                                      </div>
                                    </div>

                                    <div id='ember3785' class='nav-nested is-sub ember-view'>
                                      <div id='ember3786' class='ember-view'>
                                        <!-- MENUDEPTH: 2 -->
                                        <div class='nav-tree__item ' data-ember-action='' data-ember-action-3787='3787'>
                                          <div class='nav-item__container flex-container-row flex-container--justify-space-between flex-container--align-items-center'>
                                            <a href='/submenu-1-2' class='nav-item__link '>
                                                選項二
                                            </a>

                                            <span class='nav-item__toggler is-expandable'>
                                              <span>
                                                <i class='element-icon nav-item__toggler-icon fas fa-angle-up'></i>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <!--  -->
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <!-- MENU DEPTH: 1 -->
                                <div id='ember3773' class='ember-view'>
                                  <div class='nav-tree__item is-route' data-ember-action='' data-ember-action-3774='3774'>
                                    <a href='/other' class='nav-item__container is-expanded nav-item__link flex-container-row flex-container--justify-start flex-container--align-items-center   is-active' data-ember-action='' data-ember-action-3775='3775'>
                                        OTHER
                                    </a>
                                  </div>
                                </div>
                                <!--  -->
                              </div>
                            </div>

                            <!-- MENU DEPTH: 0 -->
                            <div id='ember3764' class='ember-view'>
                              <div class='nav-tree__item ' data-ember-action='' data-ember-action-3765='3765'>
                                <a href='/preview' class='nav-item__container is-expanded nav-item__link flex-container-row flex-container--justify-start flex-container--align-items-center   ' data-ember-action='' data-ember-action-3766='3766'>
                                    PREVIEW
                                </a>
                              </div>
                            </div>
                            <!--  -->
                          </div>
                        </div>
                      </div>

                    </span>

            <span class='nav-item'>
                      <a class='button button-right button--no-border'
                         data-ember-action=''
                         data-ember-action-16='16'
                      >
                        <i class='fas fa-search'></i>
                      </a>
                    </span>

            <span class='nav-item'>
                      <a class='button button-right button--no-border'
                         data-ember-action=''
                         data-ember-action-16='16'
                      >
                        <i class='fas fa-user'></i>
                      </a>
                    </span>


            <span class='nav-item'>
                      <a class='nav-menu-btn button button-right button--no-border'
                         data-ember-action=''
                         data-ember-action-17='17'
                      >
                        <span class='nav-btn ember-view'
                              id='ember18'
                        >
                          <span>
                            <span>
                              <i class='element-icon fas fa-bars'></i>
                            </span>

                          </span>
                        </span>
                      </a>
                    </span>
          </div>
        </div>
        <div class='sticky-footer'
             style='bottom: 25px;'
        >
          <div class='ember-view'
               id='ember19'
          >
          </div>
        </div>
      </div>

      <div class='my-navbar-container-spacer'
           style='height: 60px;'
      >
      </div>
    </div>
    <div class='columns page-container '>

      <div class='column page__content right'>
        <div class='message-block ember-view'
             id='ember20'
        >
        </div>

        <div class='ember-view'
             id='ember21'
        >
          <div id='page-custom-content' style='background-color: var(--themeBackgroundPrimaryColor);'>
            <div class='bigordr-inner-row'
                 id='i4nzj'
            >
              <div class='bigordr-cell'>
                <div id='icjq'>
                  <p>
                            <span style='color:#000000'>
                              <span style='font-family:Trebuchet MS,Helvetica,sans-serif'>
                                <span style='font-size:72px'>Modern Furniture</span>
                              </span>
                            </span>
                  </p>
                </div>
                <div class='bigordr-inner-row'
                     id='i3zq'
                >
                  <div class='bigordr-cell'
                       id='ize3'
                  ></div>
                </div>
                <div class='bigordr-inner-row'
                     id='icif'
                >
                  <div class='bigordr-cell'
                       id='i6q3'
                  >
                    <div id='igcp'>
                      <p style='text-align:center'>
                                <span style='color:#ffffff;'>
                                  <span style='font-size:20px'>
                                    <span
                                      style='background-color:#000000;'
                                    >&nbsp;Beautiful Hand Made Furniture&nbsp;
                                    </span>
                                  </span>
                                </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='bigordr-inner-row'
                     id='iffj'
                >
                  <div class='bigordr-cell'
                       id='ial9r'
                  >
                    <a class='button'
                       content='Shop Now'
                       rel='noreferrer noopener'
                       target='_blank'
                    >Shop Now
                    </a>
                  </div>
                </div>
                <div class='bigordr-inner-row'
                     id='iuns7'
                >
                  <div class='bigordr-cell'
                       id='ihioa'
                  ></div>
                </div>
              </div>
            </div>
            <div class='bigordr-inner-row'
                 id='i91nt'
            >
              <div class='bigordr-cell'>
                <div id='inofi'>
                  <p>
                    <span style='color:#000000;'><strong>Unique &amp; Beautiful</strong><br></span>
                  </p>
                  <p>
                    All designs are created in-house and are hand made by our carpenters.
                    <br>
                  </p>
                </div>
              </div>
            </div>

            <!-- SLIDER START -->
            <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
              <div class='bigordr-cell'>
                <div class='components-group checkout-form checkout-form__extra-max-width  components-group--grid bigordr-products-wrapper' id='ember238'>
                  <div class='has-animation-slow components-group-content   bigordr-products' id='ember238-content' style='position: relative;'>
                    <div class='store-item__card is-condensed '>
                      <div class='card-item__card-wrapper'>
                        <div class='card-image store-item__card-image-container '><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474159/brown-sunglasses' id='ember252'>
                          <!---->
                          <figure class='store-item__full-grid-position' style='background: none; width: 180px; height: 180px;'>
                            <img alt='image' class='image-display--defaults ' height='180' src='https://ucarecdn.com/0a5b897b-0bde-4468-a35e-6e4c827b92d9/-/scale_crop/180x180/smart/center/-/format/webp/-/progressive/yes/' srcset='https://ucarecdn.com/0a5b897b-0bde-4468-a35e-6e4c827b92d9/-/scale_crop/360x360/smart/center/-/quality/lighter/-/format/webp/-/progressive/yes/ 2x' style='visibility: visible;' width='180'>
                          </figure>
                        </a></div>
                        <div class='store-item__card-content card-content--center'><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474159/brown-sunglasses' id='ember254'>
                          <div class='is-condensed'>
                            <div class='store-item__card-name'> brown sunglasses</div>
                            <div class='price-container'>
                              <span class='store-item__price store-item__price--old ' style='color: var(--themeOriginalPriceTextColor);'>    NT 9,266    <!---->  </span>                    <!---->
                              <span class='store-item__price store-item__price--sale ' style='color: var(--themeSalePriceTextColor);'>    NT 8,266    <!---->  </span>                    <!---->
                            </div>
                          </div>                <!---->
                          <div><a class='view-more-btn is-condensed'> 更多... </a></div>
                        </a></div>
                        <footer class='store-item__card-footer is-condensed'><span class='ember-view' id='ember256'>          <div class='cart-add-btn__container flex-container--justify-center'>              <button class='has-animation-quick button button--action cart-add-btn                 button--solid                 is-condensed                 ' data-ember-action='' data-ember-action-258='258'>                  + 加入購物車              </button>    </div>      </span>
                          <!---->            </footer>
                      </div>
                    </div>
                    <div class='store-item__card is-condensed '>
                      <div class='card-item__card-wrapper'>
                        <div class='card-image store-item__card-image-container '><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474160/pendant-necklacec' id='ember262'>
                          <!---->
                          <figure class='store-item__full-grid-position' style='background: none; width: 180px; height: 180px;'>
                            <img alt='image' class='image-display--defaults ' height='180' src='https://ucarecdn.com/53cd4c82-ea03-460f-9ec8-b1b6d68792e2/-/scale_crop/180x180/smart/center/-/format/webp/-/progressive/yes/' srcset='https://ucarecdn.com/53cd4c82-ea03-460f-9ec8-b1b6d68792e2/-/scale_crop/360x360/smart/center/-/quality/lighter/-/format/webp/-/progressive/yes/ 2x' style='visibility: visible;' width='180'>
                          </figure>
                        </a></div>
                        <div class='store-item__card-content card-content--center'><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474160/pendant-necklacec' id='ember264'>
                          <div class='is-condensed'>
                            <div class='store-item__card-name'> pendant necklacec</div>
                            <div class='price-container'><span class='store-item__price '>    NT 8,487    <!---->  </span>                    <!----></div>
                          </div>                <!---->
                          <div><a class='view-more-btn is-condensed'> 更多... </a></div>
                        </a></div>
                        <footer class='store-item__card-footer is-condensed'><span class='ember-view' id='ember266'>          <div class='cart-add-btn__container flex-container--justify-center'>              <button class='has-animation-quick button button--action cart-add-btn                 button--solid                 is-condensed                 ' data-ember-action='' data-ember-action-268='268'>                  + 加入購物車              </button>    </div>      </span>
                          <!---->            </footer>
                      </div>
                    </div>
                    <div class='store-item__card is-condensed '>
                      <div class='card-item__card-wrapper'>
                        <div class='card-image store-item__card-image-container '><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474157/three-Vought-F4U-Corsair' id='ember272'>
                          <!---->
                          <figure class='store-item__full-grid-position' style='background: none; width: 180px; height: 180px;'>
                            <img alt='image' class='image-display--defaults ' height='180' src='https://ucarecdn.com/30f1fcce-e9f2-4daa-a21a-5a8f4b1f0934/-/scale_crop/180x180/smart/center/-/format/webp/-/progressive/yes/' srcset='https://ucarecdn.com/30f1fcce-e9f2-4daa-a21a-5a8f4b1f0934/-/scale_crop/360x360/smart/center/-/quality/lighter/-/format/webp/-/progressive/yes/ 2x' style='visibility: visible;' width='180'>
                          </figure>
                        </a></div>
                        <div class='store-item__card-content card-content--center'><a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474157/three-Vought-F4U-Corsair' id='ember274'>
                          <div class='is-condensed'>
                            <div class='store-item__card-name'> three Vought F4U Cor...</div>
                            <div class='price-container'><span class='store-item__price '>    NT 3,703    <!---->  </span>                    <!----></div>
                          </div>                <!---->
                          <div><a class='view-more-btn is-condensed'> 更多... </a></div>
                        </a></div>
                        <footer class='store-item__card-footer is-condensed'><span class='ember-view' id='ember276'>          <div class='cart-add-btn__container flex-container--justify-center'>              <button class='has-animation-quick button button--action cart-add-btn                 button--solid                 is-condensed                 ' data-ember-action='' data-ember-action-278='278'>                  + 加入購物車              </button>    </div>      </span>
                          <!---->            </footer>
                      </div>
                    </div>
                    <div class='store-item__card is-condensed '>
                      <div class='card-item__card-wrapper'>
                        <div class='card-image store-item__card-image-container '>
                          <a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474165/white-and-gold-colored-accessories' id='ember282'>
                            <!---->
                            <figure class='store-item__full-grid-position' style='background: none; width: 180px; height: 180px;'>
                              <img alt='image' class='image-display--defaults ' height='180' src='https://ucarecdn.com/18c2f665-e306-49b5-8e58-1a6133277823/-/scale_crop/180x180/smart/center/-/format/webp/-/progressive/yes/' srcset='https://ucarecdn.com/18c2f665-e306-49b5-8e58-1a6133277823/-/scale_crop/360x360/smart/center/-/quality/lighter/-/format/webp/-/progressive/yes/ 2x' style='visibility: visible;' width='180'>
                            </figure>
                          </a></div>
                        <div class='store-item__card-content card-content--center'>
                          <a class='this.linkToClassNamesComputed ember-view' href='/shop/bigordr-templates-boutique/item/3040094474165/white-and-gold-colored-accessories' id='ember284'>
                            <div class='is-condensed'>
                              <div class='store-item__card-name'> white and gold-color...</div>
                              <div class='price-container'><span class='store-item__price '>    NT 9,892    <!---->  </span>                    <!----></div>
                            </div>                <!---->
                            <div><a class='view-more-btn is-condensed'> 更多... </a></div>
                          </a></div>
                        <footer class='store-item__card-footer is-condensed'><span class='ember-view' id='ember286'>          <div class='cart-add-btn__container flex-container--justify-center'>              <button class='has-animation-quick button button--action cart-add-btn                 button--solid                 is-condensed                 ' data-ember-action='' data-ember-action-288='288'>                  + 加入購物車              </button>    </div>      </span>
                          <!---->            </footer>
                      </div>
                    </div>
                  </div>
                  <a class='button slider-button components-group-controls components-group-button-left'> <span class='icon'><i class='fas fa-angle-left'></i></span></a>
                  <a class='button slider-button components-group-controls components-group-button-right'> <span class='icon'><i class='fas fa-angle-right'></i></span></a>
                </div>
              </div>
            </div>
            <!-- SLIDER END -->
            <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
              <div class='bigordr-cell' style='display: flex;justify-content: center;'>
                <button class='button' style='margin: 0 10px; border-radius: var(--themeButtonBorderRadius) !important'>
                  button1
                </button>
                <button class='button button2' style='margin: 0 10px;'>
                  button2
                </button>
                <button class='button button3' style='margin: 0 10px;'>
                  button3
                </button>
                <button class='button button-variant' style='margin: 0 10px; border-radius: var(--themeVariantButtonBorderRadius) !important'>
                  variant button
                </button>
              </div>
            </div>

            <!--            PAYMENT STUFF-->
            <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
              <div class='bigordr-cell' style='display: flex;justify-content: center;'>
                <div class='checkout-form'>
                  <div class='page-steps-container ember-view'
                       id='ember713'
                  >
                    <div class='page-step current-step'
                         data-ember-action=''
                         data-ember-action-715='715'
                    >
                      <div class='page-step__number'>1</div>
                      <div>購物車內容</div>
                    </div>
                    <div class='page-step page-step__arrow'>
                    </div>
                    <div class='page-step next-step'
                         data-ember-action=''
                         data-ember-action-717='717'
                    >
                      <div class='page-step__number'>2</div>
                      <div>顧客資料</div>
                    </div>
                    <div class='page-step page-step__arrow'>
                    </div>
                    <div class='page-step next-step'
                         data-ember-action=''
                         data-ember-action-719='719'
                    >
                      <div class='page-step__number'>3</div>
                      <div>付款</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
              <div class='bigordr-cell' style='display: flex;justify-content: center;'>
                <div class='checkout-form'>
                  <div class='card ember-view'
                       id='ember721'
                       style='background-color:var(--themeBackgroundPrimaryColor);color:var(--themeTextColor);'
                  >
                    <header class='card-header page-card-header ember-view'
                            id='ember722'
                            style='background-color:var(--themeBackgroundPrimaryColor);color:var(--themeTextColor);'
                    >
                      <p class='card-header-title'>
                        購物車
                      </p>

                    </header>

                    <div class='card-content ember-view'
                         id='ember723'
                    >
                      <table class='checkout-table table is-fullwidth' style='background-color:var(--themeBackgroundPrimaryColor);color:var(--themeTextColor);'>

                        <tr>
                          <td colspan='3'>
                            <div class='flex-container-row flex-container--wrap flex-container--align-items-center'>
                              <div class='desc-child__item'>
                                <a class='text--main ember-view'
                                   href='/shop/bigordr-templates-boutique/item/3040094474163/white-and%20brown%20wooden%20table%20decor'
                                   id='ember727'
                                >
                                  <figure class='image cart-item__thumb'>
                                    <img alt='Placeholder image'
                                         src='https://ucarecdn.com/2caabed4-aeaf-45ab-918f-8261894a20ac/-/resize/100x/'
                                         srcset='https://ucarecdn.com/2caabed4-aeaf-45ab-918f-8261894a20ac/-/resize/100x/ 2x'
                                    >
                                  </figure>

                                </a>
                              </div>

                              <div class='desc-child__item'>
                                    <span class='store-item__qty-selector ember-view'
                                          id='ember728'
                                    >
                                      <div class='has-value'>
                                        <a class='button--square minus '
                                           data-ember-action=''
                                           data-ember-action-729='729'
                                        >
                                          -
                                        </a>

                                        <input autocomplete='off'
                                               class='ember-text-field ember-view input'
                                               type='text'
                                               value='3'
                                        >

                                        <a class='button--square plus '
                                           data-ember-action=''
                                           data-ember-action-731='731'
                                        >
                                          +
                                        </a>
                                      </div>
                                    </span>
                              </div>

                              <div class='flex-container-row flex-container--nowrap flex-container--align-items-center'>
                                <div class='desc-child__item'>
                                  <a class='text--main ember-view'
                                     href='/shop/bigordr-templates-boutique/item/3040094474163/white-and%20brown%20wooden%20table%20decor'
                                     id='ember733'
                                  >      <!---->            white and brown wooden table decor

                                  </a>
                                  <!---->
                                  <!---->

                                  <!---->        </div>

                                <!---->
                                <!---->      </div>

                              <div class='desc-child__item flex-push-right'>

                                NT 990

                              </div>
                            </div>
                          </td>
                        </tr>

                        <!---->
                        <!---->

                        <tr>
                          <td style='border-bottom: 0'></td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >
                            小計:
                          </td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >
                            NT 990
                          </td>
                        </tr>

                        <tr>
                          <td style='border-bottom: 0'></td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >
                            運費 - 運費:
                            <div class='has-text-danger'>
                              差「NT 10」就可免運費嘍!
                            </div>
                          </td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >
                            NT 100
                          </td>
                        </tr>

                        <!---->
                        <!---->
                        <tr>
                          <td style='border-bottom: 0'></td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >
                            總金額:
                          </td>
                          <td class='has-text-right'
                              style='border-bottom: 0'
                          >

                            NT 1,090

                          </td>
                        </tr>

                      </table>
                      <div>
                        <button class='button is-delete'
                                data-ember-action=''
                                data-ember-action-736='736'
                        >全部移除
                        </button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            PAYMENT STUFF-->
          </div>
        </div>


      </div>
    </div>

    <div class='chat-btn__container ember-view'
         id='ember22'
         style='bottom: 25px;'
    >
              <span class='chat-options__btn'>
                <i class='element-icon fab fa-facebook-messenger'
                   style='position: relative;top: 7px;'
                ></i>
              </span>
    </div>
    <div>
      <footer class='footer footer-margin'>
        <div class='container'>
          <div class='columns'>
            <div class='column'>
              <aside class='menu menu-list__footer-header-color'>
                <p class='menu-label'>
                  Sitemap
                </p>
                <ul class='menu-list menu-list__no-hover'>
                  <li><a href='/template003'>super products</a></li>
                </ul>
              </aside>
            </div>
            <div class='column'>
              <aside class='menu menu-list__footer-header-color'>
                <p class='menu-label'>
                  Support
                </p>
                <ul class='menu-list menu-list__no-hover'>
                  <li><a href='/shop/dev-tmptmp/faqs'>常見問題</a></li>
                  <li><a href='/shop/dev-tmptmp/orders'>訂單查詢</a></li>
                </ul>
              </aside>
            </div>
            <div class='column'>
              <aside class='menu menu-list__footer-header-color'>
                <p class='menu-label'>
                  customer service
                </p>
                <ul class='menu-list menu-list__no-hover'>
                  <li><a href='tel:+1-801-484-7311'>+1-801-484-7311</a></li>
                  <li><a>Tokyo Shin LTD.</a></li>
                  <li><a></a></li>
                </ul>
              </aside>
            </div>
            <div class='column'>
              <aside class='menu menu-list__footer-header-color'>
                <p class='menu-label'>
                  open hours
                </p>
                <ul class='menu-list menu-list__no-hover'>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>monday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>tuesday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>wednesday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>thursday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>friday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>saturday - 09:30~06:00</a>
                  </li>
                  <li class='flex-container-row flex-container--align-items-center'>
                    <a>sunday - 09:30~06:00</a>
                  </li>
                </ul>
              </aside>
            </div>
            <div class='column has-text-centered flex-item--align-self-center'>

              <div class='social-icons__container ember-view' id='ember467'><a href='https://google.co.uk' target='_blank'>
      <span>
    <i class='element-icon fab fa-facebook-square'></i>
  </span>

              </a>
                <a href='https://google.co.uk' target='_blank'>
      <span>
    <i class='element-icon fab fa-instagram'></i>
  </span>

                </a>
                <a href='https://google.co.uk' target='_blank'>
      <span>
    <i class='element-icon fab fa-youtube'></i>
  </span>

                </a>
                <a href='https://line.me/R/ti/p/123123123' target='_blank'>
      <span>
    <i class='element-icon fab fa-line'></i>
  </span>

                </a>
              </div>

            </div>
          </div>
          <div class='columns'>
            <div class='column has-text-centered flex-item--align-self-center'>
              <div class='section ember-view' id='ember470'>
                <div class='container'>
                  <div class='content-display ember-view' id='ember471'><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus congue pharetra. Etiam pretium eros nec lectus efficitur volutpat. Curabitur sit amet ligula
                                                                           congue, tristique odio vitae, facilisis dui. Ut elementum non elit sit amet aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>

                    <div class='flow-container ember-view' id='ember472'><!----></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
      `
        }
      });
      this.crud.addLists(this, ['websites/lists/layout/btns-border-radius']);
      this.updateColors(null, this.args.model);
    }
    removeOldStyles() {
      R.pipe(R.pathOr({}, ['args', 'editor']), editor => {
        if (editor.getStyle) {
          return editor.getStyle();
        }
        return {};
      }, R.pathOr([], ['models']), R.filter(R.pathEq(['attributes', 'selectorsAdd'], ':root')), R.forEach(m => {
        if (m.destroy) {
          m.destroy();
        }
      }))(this);
    }
    updateColors(updateStyle, model) {
      this.colors = R.pipe(R.pathOr({}, ['_data']), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme(?!Name)/))), R.sort(([a], [b]) => a.localeCompare(b)), R.map(R.zipObj(['key', 'value'])))(model);
      if (updateStyle) {
        updateStyle(model);
      }
    }
    open() {
      if (this.args.onOpen) {
        this.args.onOpen();
      }
    }
    save(onCloseDialogAction) {
      const saveTask = this.args.saveTask;
      if (saveTask.perform) {
        saveTask.perform();
      } else {
        saveTask();
      }
      if (this.args.editor) {
        this.removeOldStyles();
        this.args.editor.getStyle().add(this.grapesjs.genThemeFromWebsiteModel(this.args.model));
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "grapesjs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateColors", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateColors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
});