define("client/pods/websites/lists/link-thumbnail-image-ratio/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: '1:1',
        label: '1:1'
      }, {
        value: '2:3',
        label: '2:3'
      }, {
        value: '3:2',
        label: '3:2'
      }];
      return _nventor.default.resolve(data);
    }
  });
});