define("client/pods/components/lists/list-dropdown/component", ["exports", "client/pods/components/lists/mixins/dropdown", "client/mixins/translate", "ramda", "ramda-adjunct"], function (_exports, _dropdown, _translate, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * NOTES:
   * - Dropdown will find label based on value set on init (if set)
   *
   * - Dropdown will automatically update label when model.value changes dynamically (which is bound to selected.value)
   *
   * - Will always convert value to string when comparing for match.
   *   eg. 0 will equal '0' when matching selected
   *
   * WARNING:
   *  do not set label property when setting up component
   */
  var _default = _exports.default = Ember.Component.extend(_dropdown.default, _translate.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    validationService: Ember.inject.service('validation'),
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    tabsManager: Ember.inject.service(),
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    tagName: 'span',
    modalClassNames: '',
    selected: null,
    // tracks the value that is selected
    // selectedComponents: null,
    // autoSelectFirst: false, //automatically select first list item
    allowEmpty: false,
    allowDeselect: false,
    multiSelect: false,
    hasDropdown: true,
    showEditor: false,
    isBtn: true,
    label: '',
    isPersistentLabel: false,
    value: null,
    readonly: false,
    // input element's readonly
    disabled: false,
    // determine whether clicking on the input will toggle dropdown
    rememberSelected: true,
    onDeselect: null,
    translate: false,
    onSelect: null,
    // action for onSelect
    onBindHelper: null,
    // helper to bind property to model
    allowDynamicLabelChanges: true,
    // allow changes dynamic changes in value to update label automatically
    triggerOnSelectActionOnChange: true,
    // will trigger onSelect when model value changes
    dropdownWasSelected: false,
    // used to determine if user selected dropdown or value was changed from external source
    inputIsFullW: true,
    autoPosition: true,
    isQuickEditable: true,
    // allow quick editing of dropdown value when selected. otherwise need to show dropdown first to show edit btn
    onAfterAddItem: '',
    closeOnOverlayClick: true,
    classNames: ['is-list-dropdown'],
    classNameBindings: ['getClassNames', 'isSelectedEditable:is-editable', 'isHiddenPlaceholder:u-hidden-placeholder'],
    getClassNames: Ember.computed('hasCurrentError', 'isSelectedEditable', function () {
      if (this?.hasCurrentError) {
        return 'has-error';
      }
      return '';
    }),
    init() {
      this._super(...arguments);
      let classNames = this.classNames || [];
      if (RA.isNilOrEmpty(classNames)) {
        classNames = classNames.concat(['list-dropdown', 'u-tappable']);
      } else {
        classNames = classNames.concat(['list-dropdown']);
      }
      const containerClassNames = this.containerClassNames || [];
      classNames = classNames.concat(containerClassNames);
      this.set('classNames', classNames);
      const tab = this.tab;
      const model = this.model;
      this.validationService.setErrorsTracker({
        tab,
        model,
        context: this.context
      });
    },
    _btnClassNames: Ember.computed('btnClassNames', function () {
      let btnClassNames = this.btnClassNames || '';
      return `list-dropdown__btn ${btnClassNames}`;
    }),
    currentErrors: Ember.computed('tab', 'tab.errors.errors.[]', function () {
      this.toggleProperty('has-input-error');
      return this.tab?.errors?.errors || [];
    }),
    hasCurrentError: Ember.computed('currentErrors.[]', 'context', function () {
      const currentErrors = this?.currentErrors || [];
      const context = this?.context;
      return R.pipe(R.find(R.anyPass([R.pathEq(['context', 'key'], context), R.pathEq(['context', 'label'], context), R.pathEq(['context', 'keyWithIndex'], context)])), RA.isNotNilOrEmpty)(currentErrors);
    }),
    imageStyle: Ember.computed('imageHeight', 'imageWidth', function () {
      let style = '';
      if (this.imageHeight) {
        style = `height:${this.imageHeight};`;
      }
      if (this.imageWidth) {
        style = `${style} width:${this.imageWidth};`;
      }
      return style;
    }),
    setSelected(item) {
      if (!item) {
        const value = this.value;
        item = this.getSelectedItem(value);
      }
      if (this.get('rememberSelected')) {
        this.set('selected', item);
      }
      return item;
    },
    // setup () {
    //   let value = this.get('value')
    //   const label = this.get('label')
    //   const list = this.get('list')
    //   console.log('===setup dropdown list')
    //   console.log(label)
    //   console.log(list)
    //   if ((value !== '' && value != null) && isEmpty(label) && !isEmpty(list)) {
    //     this.changeLabel()
    //   } extensions/apps/tw/t-cat/lists/product-type
    // },

    allowEdit: Ember.computed('addItemComponent', function () {
      if (this.addItemComponent) {
        return true;
      }
      return false;
    }),
    modalDialogLabel: Ember.computed('addItemOptions.{modalDialogTitle,modalDialogTitleTranslate}', function () {
      const title = this?.addItemOptions?.modalDialogTitle || '';
      if (!title) {
        return '';
      }
      const translate = this?.addItemOptions?.modalDialogTitleTranslate || false;
      if (translate) {
        return this.intl.t(title);
      }
      return title;
    }),
    isSelectedEditable: Ember.computed('allowEdit', 'isQuickEditable', 'selectedModel.{isOutOfDateOrDeleted,_data.isNotEditable}', function () {
      const allowEdit = this.allowEdit;
      if (!allowEdit) {
        return false;
      }
      if (!this.isQuickEditable) {
        return false;
      }
      const selectedModel = this.selectedModel;
      if (RA.isNilOrEmpty(selectedModel)) {
        return false;
      }
      const isNotEditable = selectedModel?._data?.isNotEditable;
      const isOutOfDateOrDeleted = selectedModel?.isOutOfDateOrDeleted;
      if (isNotEditable) {
        return false;
      }
      if (isOutOfDateOrDeleted) {
        return false;
      }
      return true;
    }),
    //
    // dropdownList: computed('list.[]', function () {
    //
    //   console.log('--dropdown list changed--');
    //   return this.get('list');
    // }),
    /**
     * value was changed on the model elsewhere (not by selecting dropdown item)
     */
    // valueChangedDynamically: Ember.observer('value', function () {
    // // @TODO: test

    // // @TODO: what happens when value is available, but list has not loaded yet?

    //   if (this.get('allowDynamicLabelChanges')) {
    //     const value = this.get('value')
    //     const list = this.get('list')

    //     const dropdownWasSelected = this.get('dropdownWasSelected')

    //     // only trigger label change if value change was from external to component
    //     // (ie. list dropdown was not selected).
    //     if ((value !== '' && value != null) && !isEmpty(list) && dropdownWasSelected === false) {
    //       const item = list.findBy(this.get('itemValueKey'), value)

    //       this.changeLabel(item)

    //       const onSelectAction = this.get('onSelect')
    //       const triggerOnSelectActionOnChange = this.get('triggerOnSelectActionOnChange')

    //       if (onSelectAction && triggerOnSelectActionOnChange) {
    //         onSelectAction(item)
    //       }
    //     }
    //   }

    //   this.set('dropdownWasSelected', false)
    // }),

    /**
     * get selected dropdown value
     * @param {object} item model
     * @return {string} selected item value
     */
    getSelectedValue: function (item) {
      const itemValueKeyArrayPath = R.pipe(R.propOr('', 'itemValueKey'), R.split('.'))(this);
      return R.pathOr('', itemValueKeyArrayPath)(item);
    },
    /**
     * get selected dropdown value
     * @param {object} item model
     * @return {string} selected item value
     */
    getSelectedLabel: function (item) {
      if (this.isPersistentLabel) {
        // @TODO: this should be removed. if you need this property then u should use
        // listBtn
        return this.label;
      }
      if (!item) {
        const value = this.value;
        const list = this.list;
        if (value != null && !Ember.isEmpty(list)) {
          if (!item) {
            item = this.getSelectedItem(value);
          }
        }
      }
      if (item && this.get('rememberSelected')) {
        const itemLabelKey = this.itemLabelKey;
        return item.get?.(itemLabelKey) || item?.[itemLabelKey];
      }
      if (this.placeholder) {
        return this.intl.t(this.placeholder, this.translate);
      }
      return '';
    },
    /**
     * observes when value changes, will update label accordingly
     * will run on init
     */
    // hasValueChanged: Ember.on('init', Ember.observer('value', function () {
    //
    //   //@TODO: also update when list changes?
    //
    //   const value = this.get('value');
    //   if (value) {
    //
    //     //find item that matches value
    //     const list = this.get('list');
    //     const itemValueKey = this.get('itemValueKey');
    //     const item = list.findBy(itemValueKey, value);
    //
    //     this.set('label', this.getSelectedLabel(item));
    //   }
    // })),

    // computedLabel: computed('value', function () {
    //   const item = this.getSelectedItem(this.value)
    //   return this.getSelectedLabel(item)
    //   // if (this.list) {
    //   //   return R.pipe(
    //   //     R.find(R.propEq(this.itemValueKey, this.value)),
    //   //     R.prop(this.itemLabelKey)
    //   //   )(this.list) || this.label
    //   // }
    //   // return this.label
    // }),

    getSelectedItem(value) {
      const list = this.list || [];
      const itemValueKey = this.itemValueKey;
      let i = 0;
      let item;
      if (Ember.isArray(list)) {
        // item = R.find(R.pathEq([itemValueKey], value))(list)
        while (list[i]) {
          // if (String(list[i].get(itemValueKey)) === String(value)) {
          if (R.equals(list.get(`${i}.${itemValueKey}`), value)) {
            item = list[i];
            i = list.length + 1;
          }
          i++;
        }
      }
      return item;
    },
    selectedModel: Ember.computed('list.[]', 'value', 'selected.{_data.translate,synced}', function () {
      const value = this.value;
      const item = this.getSelectedItem(value);
      return item;
    }),
    // HERE
    selectedLabel: Ember.computed('translate', 'defaultLabel', 'value', 'list.[]', 'selected', 'selected.{_data.translate,synced}', 'updateLabel', function () {
      if (!this.get('rememberSelected')) {
        return this.defaultLabel || '';
      }
      const value = this.value;
      const label = this.getSelectedLabel() || this.label;
      const selected = this.getSelectedItem(value);
      if (!selected) {
        const defaultLabel = this.defaultLabel;
        if (defaultLabel) {
          if (this.translate) {
            return this.intl.t(defaultLabel);
          }
          return defaultLabel;
        }
      }
      let itemTranslate;
      if (selected) {
        itemTranslate = R.path(['_data', 'translate'])(selected);
        const shouldTranslateLabel = this.shouldTranslateLabel(value, itemTranslate);
        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }
      if (label && this.translate && this.list && value != null && value !== '') {
        const shouldTranslateLabel = this.shouldTranslateLabel(value, itemTranslate);
        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }

      // if (value != null && value != '' && this.get('list')) {

      // }

      return label;
    }),
    toggleShowEditor(bool) {
      if (typeof bool === 'boolean') {
        Ember.set(this, 'showEditor', bool);
      } else {
        Ember.set(this, 'showEditor', !this.showEditor);
      }
      // causing infinite loop?
      // this.set('updateLabel', Math.random())
    },
    updateItem(item) {
      this.toggleShowEditor(false);
      this.send('selectDropdownItem', item);
    },
    removeItem(item) {
      const self = this;
      const list = self.get('list');
      const itemValueKey = self.get('itemValueKey');
      const needle = item.get(itemValueKey);
      const found = list.findBy(itemValueKey, needle);
      const removedValue = this.getSelectedValue(item);
      const selectedValue = this.value;
      if (removedValue === selectedValue) {
        this.send('deselectDropdownItem');
      }
      list.removeObject(found);
      this.toggleShowEditor(false);
    },
    actions: {
      hideDropdown() {
        this.set('isDropdownVisible', false);
      },
      selectDropdownItem(item) {
        let confirmed = true;
        if (this.confirm && this.confirmMessage) {
          confirmed = window.confirm(this.confirmMessage);
        }
        if (confirmed) {
          this.set('dropdownWasSelected', true);
          const previousValue = this.value;
          const newValue = this.getSelectedValue(item);
          // select dropdown item, set component value
          this.set('isDropdownVisible', false);

          // if (previousValue !== newValue) { //@TODO: test
          // const originalValue = this.get('originalValue')
          // if (newValue !== originalValue) {
          this.set('value', newValue);
          this.set('originalValue', newValue);
          this.set('label', this.getSelectedLabel(item));
          this.setSelected(item);
          this.set('updateLabel', Math.random());

          // @TODO: semi focused, ie. focus but not showing dropdown
          // this.$('.element-inputpvt').focus();

          // trigger outer onSelect action
          if (this.onSelect) {
            this.onSelect(item, previousValue);
          }
        }
      },
      deselectDropdownItem() {
        this.set('value', '');
        this.set('originalValue', '');
        this.set('label', '');
        this.set('selected', '');
        this.set('updateLabel', Math.random());
        const onDeselectAction = this.onDeselect;
        if (onDeselectAction) {
          onDeselectAction();
        }
      },
      addItem(item) {
        if (!this.list) {
          this.set('list', []);
        }
        const list = this.list;
        if (!list.findBy(this.itemValueKey, this.getSelectedValue(item))) {
          this.list.pushObject(item);
        }
        this.send('selectDropdownItem', item);
      },
      editItem(item) {
        this.set('editItem', item);
        this.set('showEditor', true);
      }
    },
    onToggleModalDialog() {
      const isDropdownVisible = this.get('isDropdownVisible');
      this.set('isDropdownVisible', !isDropdownVisible);
    }
  }, _applyDecoratedDescriptor(_obj, "toggleShowEditor", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowEditor"), _obj), _applyDecoratedDescriptor(_obj, "updateItem", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateItem"), _obj), _applyDecoratedDescriptor(_obj, "removeItem", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeItem"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _obj));
});