define("client/pods/components/admin/maintenance/tab-container/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    admin: Ember.inject.service(),
    createIndexesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/indexes', {});
    }).drop(),
    createAllIndexesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/indexes/all', {});
    }).drop(),
    createInventoryCachesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/createInventoryCaches', {});
    }).drop(),
    createDetailsSimplifiedTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/createDetailsSimplified', {});
    }).drop(),
    sendReadyForPickupAtDestinationTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/sendReadyForPickupAtDestination', {});
    }).drop(),
    getContactsMemberLevelTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.server.call('GET', 'api/protected/admin/get-member-levels', {});
      this.set('results', R.propOr([], 'data')(results));
    }).drop(),
    updateContactsMemberLevelTask: (0, _emberConcurrency.task)(function* ({
      podKey = '',
      contactKey = ''
    }) {
      yield this.server.call('PUT', 'api/protected/admin/update-member-levels', {
        podKey,
        contactKey
      });
      yield this.getContactsMemberLevelTask.perform();
    }).drop(),
    getAutomationsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.server.call('GET', 'api/protected/admin/get-automations', {});
      this.set('automationsResults', R.propOr([], 'data')(results));
    }).drop(),
    updateAutomationsTask: (0, _emberConcurrency.task)(function* ({
      podKey = '',
      campaignKey = '',
      automationKey = '',
      itemKey = ''
    }) {
      yield this.server.call('PUT', 'api/protected/admin/update-automations', {
        podKey,
        campaignKey,
        automationKey,
        itemKey
      });
      yield this.getAutomationsTask.perform();
    }).drop(),
    updateMissingEarnRewardDocTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('PATCH', 'api/protected/admin/fix-missing-earn-reward', {});
    }).drop()
  });
});