define("client/pods/components/helpers/new-browser-tab-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1ps3g35N",
    "block": "{\"symbols\":[\"&default\",\"@title\",\"@linkClassNames\"],\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"new-browser-tab\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"new-browser-tab-link__title\"],[8],[0,\"\\n        <\"],[1,[23,2,[]],false],[0,\">\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"link\"]]]],[10,\"target\",\"_blank\"],[11,\"class\",[29,[\"link \",[23,3,[]]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"link\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/new-browser-tab-link/template.hbs"
    }
  });
});