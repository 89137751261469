define("client/pods/flows/steps/model", ["exports", "ramda", "client/pods/base/model", "client/utils/nventor"], function (_exports, R, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _obj = {
    defaults() {
      return {
        stepId: _nventor.default.random.alphaNum(17),
        isPreset: false,
        isPresetLocked: false,
        text: '',
        stepType: 'text',
        dataType: 'text',
        key: '',
        dataKey: '',
        type: '',
        isValidated: false,
        // hasReply: false,
        isReplyRequired: false,
        // hasChoices: false,
        hasAction: false,
        choices: [],
        allowCustomChoice: false,
        actionData: {},
        isTextArea: false
      };
    },
    typeComponent: Ember.computed('_data.stepType', function () {
      const stepType = R.pathOr('', ['_data', 'stepType'])(this);
      const prefix = 'flows/flows-editor/steps-editor';
      switch (stepType) {
        case 'action':
          return `${prefix}/actions-editor`;
        default:
          return `${prefix}/reply-choices`;
      }
    }),
    actionsComponent: Ember.computed('_data.actionType', function () {
      const prefix = 'flows/flows-editor/steps-editor/actions-editor';
      const actionType = R.path(['_data', 'actionType'])(this);
      if (!actionType) {
        return '';
      }
      switch (actionType) {
        case 'generatePromoCode':
          return `${prefix}/generate-promo-code`;
        case 'games':
          return `${prefix}/games`;
      }
      return '';
    }),
    populate(data, attrs) {
      data.actionData = this._transformAction(data);
      data.choices = this._transformChoices(data);
      this._super(data, attrs);
    },
    _transformChoices(data) {
      return R.pipe(R.propOr([], 'choices'), R.map(choice => {
        return this.dataManager.setAsRecord({
          adapterName: 'flows/steps/choices',
          data: choice
        });
      }))(data);
    },
    _transformAction(data) {
      const actionData = R.propOr({}, 'actionData')(data);
      const actionType = R.prop('actionType')(data);
      return this._createActionModel(actionType, actionData);
    },
    _createActionModel(actionType, actionData) {
      if (!actionType) {
        return {};
      }
      return this.dataManager.setAsRecord({
        adapterName: `flows/steps/actions/${actionType}`,
        data: actionData
      });
    },
    allowAction: Ember.computed('_data.isPresetLocked', function () {
      if (this?._data?.isPresetLocked && !this?._data?.hasAction) {
        return false;
      }
      return true;
    }),
    allowReply: Ember.computed('_data.isPresetLocked', function () {
      if (this?._data?.isPresetLocked && !this?.hasReply) {
        return false;
      }
      return true;
    }),
    hasReply: Ember.computed('_data.stepType', function () {
      const stepType = R.path(['_data', 'stepType'])(this);
      if (R.includes(stepType)(['replyChoices', 'replyText'])) {
        return true;
      }
      return false;
    }),
    hasChoices: Ember.computed('_data.stepType', function () {
      const stepType = R.path(['_data', 'stepType'])(this);
      if (R.includes(stepType)(['replyChoices'])) {
        return true;
      }
      return false;
    }),
    isScratchCardGame: Ember.computed('_data.gameType', function () {
      if (this?._data?.gameType === 'gameScratchCard') {
        return true;
      }
      return false;
    }),
    subSteps: Ember.computed('_data.{stepType,actionType,actionData.prizes.[]}', function () {
      const stepType = this?._data?.stepType;
      if (stepType !== 'action') {
        return [];
      }
      const actionType = this?._data?.actionType;
      if (actionType === 'games') {
        const actionData = this.getData('actionData') || {};
        if (actionData?.getData) {
          const prizes = actionData.getData('prizes') || [];
          R.forEach(prize => {
            // set parent step on each prize
            prize.set('parentStep', this);
          })(prizes);
          return prizes;
        }
      }

      // if (actionType === 'generatePromoCode') {
      //   return this.getData('prizes') || []
      // }

      return [];
    }),
    hasCustomDataKey: Ember.computed('_data.dataKey', function () {
      if (this?._data?.dataKey === this.constants?.dataKeys?.custom) {
        return true;
      }
      return false;
    }),
    selectType(stepType) {
      const step = stepType.getData('step') || {};
      R.mapObjIndexed((value, key) => {
        this.setData(key, value);
      })(step);
    },
    selectDataKey(dataKeyData) {
      const dataType = dataKeyData?._data?.dataType || 'text';
      let key = dataKeyData?._data?.value || '';
      if (key === this.constants?.dataKeys?.custom) {
        key = '';
      }
      this.setData('dataType', dataType);
      this.setData('key', key);
    },
    addAction(actionType, actionData = {}) {
      actionData.actionType = actionType;
      const actionModel = this._createActionModel(actionType, actionData);
      this.setData('actionData', actionModel);
    },
    newChoice() {
      return this.addChoice({});
    },
    addChoice(choiceData = {}) {
      const choiceModel = this.dataManager.setAsRecord({
        adapterName: 'flows/steps/choices',
        data: choiceData
      });
      const choices = this.getData('choices') || [];
      choices.pushObject(choiceModel);
      this.setData('choices', choices);
      return choiceModel;
    },
    removeChoice(choiceModel) {
      const choices = this.getData('choices') || [];
      choices.removeObject(choiceModel);
    }
  }, _applyDecoratedDescriptor(_obj, "selectType", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectType"), _obj), _applyDecoratedDescriptor(_obj, "selectDataKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectDataKey"), _obj), _applyDecoratedDescriptor(_obj, "addAction", [_dec3], Object.getOwnPropertyDescriptor(_obj, "addAction"), _obj), _applyDecoratedDescriptor(_obj, "newChoice", [_dec4], Object.getOwnPropertyDescriptor(_obj, "newChoice"), _obj), _applyDecoratedDescriptor(_obj, "addChoice", [_dec5], Object.getOwnPropertyDescriptor(_obj, "addChoice"), _obj), _applyDecoratedDescriptor(_obj, "removeChoice", [_dec6], Object.getOwnPropertyDescriptor(_obj, "removeChoice"), _obj), _obj));
});