define("client/pods/components/grapesjs/form-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsFormModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _class = class GrapesjsFormModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _initializerDefineProperty(this, "selectedForm", _descriptor4, this);
      _initializerDefineProperty(this, "formFlows", _descriptor5, this);
      _initializerDefineProperty(this, "selectedFormValue", _descriptor6, this);
      _defineProperty(this, "adapterName", 'flows');
      _defineProperty(this, "flowsFiltersAdapterName", 'flows/filters');
      _defineProperty(this, "gjsPreviewBodyStyle", {
        body: {
          margin: '30px !important',
          height: '100%',
          'background-color': '#fff'
        }
      });
      this.filters = this.search.setupFilters({
        adapterName: this.flowsFiltersAdapterName,
        defaultFiltersData: {
          type: 'form'
        }
      });
      this.getDefaultsDetails.perform();
    }
    *getDefaultsDetails() {
      const bigordrformComponents = this.args?.editor?.getSelected();
      const selectedFormKey = bigordrformComponents?.getAttributes()['data-gjs-form-key'];
      const hasSelectedForm = R.pipe(R.isEmpty, R.not)(selectedFormKey);
      if (hasSelectedForm) {
        yield this.fetchFormFlowsTask.perform(selectedFormKey);
        return;
      }
      yield this.fetchFormFlowsTask.perform();
    }

    // @restartableTask
    // * searchInputTask (query) {
    //   yield this.crud.searchInputTask.perform(
    //     {
    //       adapterName: this.adapterName,
    //       filters: this.filters,
    //       resultsProperty: 'flows',
    //       component: this
    //     },
    //     query
    //   )
    // }

    *fetchFormFlowsTask(selectedFormKey = '') {
      const getFormFlows = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this.filters
      });
      this.formFlows = getFormFlows;
      const isSelectedFormKey = record => R.pathEq(['_data', '_key'], selectedFormKey, record);
      this.selectedForm = R.filter(isSelectedFormKey, getFormFlows)[0];
      if (RA.isNotNilOrEmpty(this.selectedForm)) {
        this.selectedFormValue = this.selectedForm?._data?.name || '';
      }
    }
    onSelect(formFlow) {
      this.selectedForm = formFlow;
      this.selectedFormValue = this.selectedForm?._data?.name || '';
    }
    setForm(onCloseDialogAction) {
      const editor = this.args?.editor;
      const selectedComponent = editor?.getSelected();
      const formKey = this.selectedForm?._data?._key || '';
      const formName = this.selectedForm?._data?.name || '';
      const updatedAttributes = {
        'data-gjs-form-key': formKey,
        'data-gjs-form-name': formName,
        content: formName
      };
      selectedComponent.setAttributes(updatedAttributes);
      this.selectedForm = onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedForm", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formFlows", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFormValue", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getDefaultsDetails", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultsDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchFormFlowsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchFormFlowsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setForm", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setForm"), _class.prototype), _class);
});