define("client/pods/components/settings/settings-editor/component", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "client/config/environment", "client/utils/nventor", "client/mixins/transporter"], function (_exports, R, RA, R_, _environment, _nventor, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_transporter.default, (_dec = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    transporter: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    hctModel: false,
    headerBottom: null,
    listsIsLoaded: null,
    init() {
      this._super(...arguments);
      let crudAddLists = ['channels/shop/campaigns/statuses', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'channels/shop/campaigns/lists/productsNameFontSize', 'channels/shop/campaigns/lists/productsNameLineCount', 'channels/shop/campaigns/lists/variantSelectorStyle', 'channels/shop/campaigns/lists/cartBtnStyle', 'discounts/types', 'facebook/pages', 'facebook/pixels', 'google/tracking', 'paymentMethods', 'paymentMethods/shop', 'paymentTerms', 'products/lists/tags', 'products/lists/types', 'products/lists/tracking', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'settings/editorSubPanels', 'settings/rounding', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'shipping/feeType', 'taxes', 'taxMethods', 'taxMethods/filters', 'toggles/onOff', 'units/dimensions', 'units/weight', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }];
      if (this.settings.getProp('features.extensions.tw_invoices') || this.settings.getProp('features.extensions.tw_eInvoices')) {
        crudAddLists = crudAddLists.concat(['extensions/apps/tw/invoicing', 'extensions/apps/tw/view']);
      }
      if (this.settings.getProp('features.sales.documents')) {
        crudAddLists = crudAddLists.concat(['documents/sales/terms']);
      }
      if (this.settings.getProp('features.purchases.documents')) {
        crudAddLists = crudAddLists.concat(['documents/purchases/terms']);
      }
      this.crud.addLists(this, crudAddLists);
      this.set('config', _environment.default);
      this.set('editingPanels', []);
    },
    afterLoadLists() {
      this._super(...arguments);
      if (!this.isWizard) {
        const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || _nventor.default.safeHeadOr({}, this.subPanelsList);
        this.tabsManager.displaySubPanel(this, initialPanel);
      }
    },
    currentResourceList: Ember.computed('crud.lists.settings-editorSubPanels.[]', function () {
      if (RA.isNotNilOrEmpty(this.resourceList)) {
        return this.resourceList;
      }
      return this.crud?.lists?.['settings-editorSubPanels'] || [];
    }),
    subPanelsList: Ember.computed('crud.lists.settings-editorSubPanels.[]', function () {
      const editorSubPanels = R_.dotPath('crud.lists.settings-editorSubPanels')(this) || [];

      // only returns the ones with components
      return R.filter(subPanel => !!R.pathOr(null, ['_data', 'component'])(subPanel))(editorSubPanels);
    }),
    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', 'crud.saveTask.isRunning', function () {
      if (this.get('crud.saveTask.isRunning')) {
        return true;
      }
      if (this.isEditing === true) {
        return false;
      }
      return true;
    }),
    isDisabled: Ember.computed('isEditing', function () {
      if (this.isEditing === true) {
        return false;
      }
      return true;
    }),
    editPanel() {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const tabOptions = {
        loadInstructions: {
          component: activeSubTab.get?.('component'),
          value: activeSubTab?.value,
          label: activeSubTab?.label
        }
      };
      this.onEdit(this.model, {
        tabOptions
      });
    }
  }, _applyDecoratedDescriptor(_obj, "editPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "editPanel"), _obj), _obj));
});