define("client/pods/components/home/wizard-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let HomeWizardContainerComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('users'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class HomeWizardContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);
      _initializerDefineProperty(this, "usersService", _descriptor4, this);
      _initializerDefineProperty(this, "wizard", _descriptor5, this);
      _initializerDefineProperty(this, "userMenu", _descriptor6, this);
      _initializerDefineProperty(this, "isToggleOn", _descriptor7, this);
      _initializerDefineProperty(this, "selectedProgress", _descriptor8, this);
      this.crud.addLists(this, ['application/lists/wizards', 'application/lists/progress']);
    }
    afterLoadLists() {
      this.setupTask.perform();
    }
    *setupTask() {
      yield this.getCustomizedUserMenuTask.perform();
    }
    selectProgress(resourceData) {
      this.selectedProgress = resourceData._data.value;
    }
    get wizardsList() {
      const allowed = this.crud.lists['application-lists-wizards'] || [];
      return allowed;
    }
    get filteredList() {
      // const selectedProgress = this.selectedProgress

      // if (selectedProgress === 'isFinished') {
      //   return this.finishedItems
      // }

      // if (selectedProgress === 'unfinished') {
      //   return this.unfinishedItems
      // }

      return this.userColletion;
    }
    get userColletion() {
      return R.pathOr([], ['userMenu', '_data', 'collection'])(this);
    }
    get isOn() {
      if (this.isToggleOn) {
        return true;
      }
      if (this.unFinishedItemsCount > 0) {
        return true;
      }
      return false;
    }
    get isOnClassName() {
      if (this.isOn) {
        return 'on';
      }
      return 'off';
    }
    get unfinishedItemsCount() {
      return this.unfinishedItems.length;
    }
    get finishedItems() {
      const userColletion = this.userColletion;
      return userColletion.filter(uc => R.pathEq(['_data', 'isFinished'], true)(uc));
    }
    get unfinishedItems() {
      const userColletion = this.userColletion;
      return userColletion.filter(uc => R.pathEq(['_data', 'isFinished'], false)(uc));
    }
    toggle() {
      this.isToggleOn = !this.isToggleOn;
    }
    setShowType(showType) {
      this.showType = showType;
      this.isToggleOn = true;
    }
    *getCustomizedUserMenuTask() {
      const userKey = this.usersService.getUserKey();
      const menuId = 'application/lists/wizards';
      const userMenuFilters = this.search.setupFilters({
        adapterName: 'members-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'index',
            direction: 'ASC'
          }],
          userKey,
          menuId
        }
      });
      const userMenu = yield this.crud.findTask.perform({
        adapterName: 'wizards',
        filters: userMenuFilters
      });
      this.userMenu = userMenu;
      const allowedMenu = this.wizardsList || [];
      const userCollection = R.pathOr([], ['_data', 'collection'])(userMenu);
      yield R.forEach(menu => {
        const foundCollection = R.find(R.pathEq(['_data', 'target'], menu._data.target))(userCollection);
        if (foundCollection) {
          return;
        }
        menu.setData('userKey', userKey);
        menu.setData('menuId', menuId);
        const hideForOldUsers = menu.getData('hideForOldUsers');
        menu.setData('isFinished', !!hideForOldUsers);
        return this.crud.createRecordTask.perform({
          adapterName: 'members-menu',
          model: menu
        });
      })(allowedMenu);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "usersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userMenu", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isToggleOn", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedProgress", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProgress", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShowType", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setShowType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCustomizedUserMenuTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getCustomizedUserMenuTask"), _class.prototype), _class);
});