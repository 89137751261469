define("client/pods/contacts/persons/model", ["exports", "client/pods/base/model", "client/mixins/contacts-is-parent", "client/mixins/contacts-details", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _model, _contactsIsParent, _contactsDetails, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend(_contactsIsParent.default, _contactsDetails.default, {
    defaults() {
      return {
        personId: _nventor.default.random.alphaNum(8)
      };
    },
    populate(data, attrs) {
      data = this.transformPersonDetails(data, attrs);
      this._super(data, attrs);
    },
    transformPersonDetails(data, {
      transporterExtension
    } = {}) {
      if (!transporterExtension) {
        return data;
      }
      const personDefaultDetailsByTransporterAccounts = this.getPersonDetailsByTransporterExtension(transporterExtension);
      const existingPersonDetails = data?.details || [];
      if (RA.isNilOrEmpty(existingPersonDetails)) {
        return data;
      }
      if (existingPersonDetails.length === personDefaultDetailsByTransporterAccounts.length) {
        return data;
      }
      const updatedPersonDetails = R.map(personDefaultDetail => {
        const isExistingDetail = existingPersonDetails.find(model => model._data.subType === personDefaultDetail.subType);
        if (isExistingDetail) {
          return isExistingDetail;
        }
        this.addDetail(personDefaultDetail);
        return this._data.details.lastObject;
      })(personDefaultDetailsByTransporterAccounts);
      data.details = updatedPersonDetails;
      return data;
    },
    setTransporterKeyToDetailsModel() {
      const details = this?._data?.details || [];
      const isPickupLocation = this?._data?.type === 'pickupLocation';
      if (!isPickupLocation) {
        return details;
      }
      const updatedDetails = details.map(detailModel => {
        const hasTransporterKey = R.pipe(R.prop('transporterKey'), RA.isNotNilOrEmpty)(detailModel);
        if (hasTransporterKey) {
          return detailModel;
        }
        const transporterKeyHash = {
          address: 'transporterSenderAddress',
          telephone: 'transporterSenderTelephone',
          transporterPackageTemperature: 'transporterAccount'
        };
        const subType = detailModel?._data?.subType;
        const transporterKey = transporterKeyHash[subType];
        if (transporterKey) {
          detailModel.setData('transporterKey', transporterKey);
        }
        return detailModel;
      });
      this.setData('details', updatedDetails);
    },
    componentPath: 'contacts/persons/editor-person',
    component: Ember.computed('componentPath', function () {
      return this.componentPath;
    }),
    label: Ember.computed('_data.{name,label}', function () {
      const label = this?._data?.label;
      if (label) {
        return `${label} (${this?._data?.name})`;
      }
      return this?._data?.name;
    }),
    defaultForDocs: Ember.computed('_data.{isDefault,isDefaultFor,isDefaultForDocs.[]}', function () {
      if (this.get('_data.isDefault')) {
        if (this.get('_data.isDefaultFor') === 'all') {
          return ['all'];
        }
        return this.get('_data.isDefaultForDocs') || [];
      }
      return [];
    }),
    /**
     * Computes the convenience store data based on the details data.
     * READ ONLY
     *
     * @returns {Object} The computed convenience store data.
     */
    convenienceStoreData: Ember.computed('_data.details.[]', function () {
      const data = R.pipe(R.pathOr([], ['_data', 'details']), R.map(detailModel => {
        const sevenEleven = ['storeId', 'storeName', 'storeAddress'];
        const subType = detailModel?._data?.subType;
        const isSevenElevenDetail = sevenEleven.includes(subType);
        if (isSevenElevenDetail) {
          return {
            [subType]: detailModel._data.value
          };
        }
      }), R.reject(RA.isNilOrEmpty))(this);
      return Object.assign({}, ...data);
    }),
    tCatPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'sender address',
        subType: 'address',
        value: '',
        transporterKey: 'transporterSenderAddress',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'preferred delivery time',
        icon: 'fas fa-clock',
        subType: 'deliveryTime',
        value: 'anytime',
        translate: true,
        transporterKey: 'transporterDeliveryTime',
        type: 'pickupLocation',
        isDefault: true
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount',
        type: 'pickupLocation',
        isDefault: true
      }, {
        customLabel: 'package size',
        icon: 'fas fa-ruler-combined',
        subType: 'packageSize',
        value: '60cm',
        transporterKey: 'transporterPackageSize',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'product type',
        icon: 'fas fa-cube',
        subType: 'packageType',
        value: 'specialty and local good',
        translate: true,
        transporterKey: 'transporterPackageType',
        type: 'pickupLocation',
        isDefault: true
      }];
    },
    hctPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'sender address',
        subType: 'address',
        value: '',
        transporterKey: 'transporterSenderAddress',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-user-circle',
        subType: 'transporterAccount',
        value: '',
        translate: false,
        transporterKey: 'transporterAccount',
        type: 'pickupLocation',
        isDefault: true
      }];
    },
    sevenElevenPersonDetails() {
      return [{
        customLabel: 'sender company',
        subType: 'transporterSenderCompany',
        value: '',
        transporterKey: 'transporterSenderCompany',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        // customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount',
        type: 'pickupLocation',
        isDefault: true
      }, {
        customLabel: 'return storeId',
        icon: 'fas fa-user-circle',
        subType: 'storeId',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'return storeName',
        icon: 'fas fa-user-circle',
        subType: 'storeName',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'return storeAddress',
        icon: 'fas fa-user-circle',
        subType: 'storeAddress',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }];
    },
    payuniSevenElevenPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'transporter type',
        icon: 'fa-solid fa-people-carry-box',
        subType: 'transporterType',
        value: 'B2C',
        translate: false,
        transporterKey: 'transporterType',
        type: 'pickupLocation',
        isDefault: true
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount',
        type: 'pickupLocation',
        isDefault: true
      }, {
        customLabel: 'return storeId',
        icon: 'fas fa-user-circle',
        subType: 'storeId',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'return storeName',
        icon: 'fas fa-user-circle',
        subType: 'storeName',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }, {
        customLabel: 'return storeAddress',
        icon: 'fas fa-user-circle',
        subType: 'storeAddress',
        value: '',
        type: 'pickupLocation',
        isDefault: true,
        translate: false
      }];
    },
    /**
     *
     * @param {Object} options
     * @param {string} options.transporterExtension
     * @param {string} options.transporterSenderTelephone
     */
    setupPerson({
      transporterExtension,
      transporterSenderTelephone = ''
    }) {
      const personDefaultDetailsByTransporterAccounts = this.getPersonDetailsByTransporterExtension(transporterExtension);
      R.forEach(detailData => {
        if (transporterSenderTelephone && detailData.transporterKey === 'transporterSenderTelephone') {
          detailData.value = transporterSenderTelephone;
        }
        this.addDetail(detailData);
      })(personDefaultDetailsByTransporterAccounts);
    },
    clearConvenienceStoreData() {
      const details = R.pipe(R.pathOr([], ['_data', 'details']), R.map(detailModel => {
        const sevenEleven = ['storeId', 'storeName', 'storeAddress'];
        const subType = detailModel?._data?.subType;
        const isSevenElevenDetail = sevenEleven.includes(subType);
        if (isSevenElevenDetail) {
          return R.set(R.lensPath(['_data', 'value']), '')(detailModel);
        }
        return detailModel;
      }), R.reject(RA.isNilOrEmpty))(this);
      this.set('_data.details', details);
    },
    getTransporterData() {
      const transporterSenderName = this._data.name;
      const transporterData = R.pipe(R.pathOr([], ['_data', 'details']), R.filter(R.hasPath(['_data', 'transporterKey'])), R.reduce((obj, model) => {
        const transporterKey = R.pathOr('', ['_data', 'transporterKey'])(model);
        obj[transporterKey] = R.pathOr('', ['_data', 'value'])(model);
        return obj;
      }, {}))(this);
      return R.mergeRight({
        transporterSenderName
      }, transporterData);
    },
    getPersonDetailsByTransporterExtension(transporterExtension) {
      const personDefaultDetailsByTransporterAccounts = {
        [this.constants.transporterExtensions.hct]: this.hctPersonDetails(),
        [this.constants.transporterExtensions.tCat]: this.tCatPersonDetails(),
        [this.constants.transporterExtensions.sevenEleven]: this.sevenElevenPersonDetails(),
        [this.constants.transporterExtensions.payuniSevenEleven]: this.payuniSevenElevenPersonDetails()
      };
      return personDefaultDetailsByTransporterAccounts[transporterExtension] || [];
    }
  });
});