define("client/pods/components/menus/nav-tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5HChha0Z",
    "block": "{\"symbols\":[\"item\",\"@onSelect\",\"@isPinnedMenu\",\"@pinType\",\"@onUpdateMenuIndexTask\",\"@onPinTask\"],\"statements\":[[4,\"each\",[[23,0,[\"menu\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,1,[\"_data\",\"groups\"]],[23,1,[\"_data\",\"sub\"]],[23,1,[\"_data\",\"isFavorites\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"menus/nav-tree/nav-nested\",null,[[\"item\",\"wizardItem\",\"labelKey\",\"isExpanded\",\"onExpand\",\"onPinTask\",\"isPinnedMenu\",\"onUpdateMenuIndexTask\",\"onSelect\",\"pinType\"],[[23,1,[]],[23,1,[]],\"label\",[23,1,[\"_data\",\"isExpanded\"]],[28,\"action\",[[23,0,[]],\"toggleSubMenu\"],null],[23,6,[]],[23,3,[]],[23,5,[]],[23,2,[]],[23,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\"],[[23,1,[\"_data\",\"beta\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"menus/nav-tree/nav-item\",null,[[\"item\",\"wizardItem\",\"classNames\",\"labelKey\",\"onPinTask\",\"onUpdateMenuIndexTask\",\"pinType\",\"isPinnedMenu\",\"onSelect\"],[[23,1,[]],[23,1,[]],\"nav-tree__item-link\",\"label\",[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-tree/template.hbs"
    }
  });
});