define("client/pods/components/transporters/seven-eleven/pickup-location/return-store-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nxN8tAvo",
    "block": "{\"symbols\":[\"@transporterModel\",\"@model\",\"@onUpsertPersonTask\",\"@onCloseDialogAction\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"convenienceStoreData\",\"storeId\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,2,[\"convenienceStoreData\",\"storeName\"]],false],[0,\" - \"],[1,[23,2,[\"convenienceStoreData\",\"storeAddress\"]],false],[0,\" (\"],[1,[28,\"tt\",[\"storeId\"],null],false],[0,\"\\n      : \"],[1,[23,2,[\"convenienceStoreData\",\"storeId\"]],false],[0,\")\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[\"seven-eleven return store description\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,2,[\"isDirty\"]],[23,2,[\"convenienceStoreData\",\"storeId\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"elements/btn-delete\",[],[[\"@typeToDelete\",\"@label\",\"@icon\",\"@onSubmit\"],[false,\"\",\"fas fa-trash\",[28,\"perform\",[[23,0,[\"clearReturnConvenienceStoreTask\"]],[23,4,[]],[23,2,[]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@disabled\",\"@translate\",\"@label\",\"@isRunning\",\"@onSubmit\"],[[28,\"if\",[[23,1,[\"_data\",\"_key\"]],false,true],null],true,\"change return convenience store\",[28,\"or\",[[23,0,[\"updateReturnConvenienceStoreTask\",\"isRunning\"]],[23,0,[\"clearReturnConvenienceStoreTask\",\"isRunning\"]]],null],[28,\"perform\",[[23,0,[\"updateReturnConvenienceStoreTask\"]],[23,3,[]],[23,2,[]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/seven-eleven/pickup-location/return-store-editor/template.hbs"
    }
  });
});