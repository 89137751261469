define("client/pods/components/websites/panels-edit/component", ["exports", "client/mixins/crud", "ember-concurrency-decorators", "ramda-extension", "ramda-adjunct"], function (_exports, _crud, _emberConcurrencyDecorators, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    adapterName: 'websites',
    init() {
      this.setup();
      this._super(...arguments);
    },
    setup() {
      this.crud.addLists(this, ['websites/lists/subPanels']);
      const dirty = this.setupDirty({
        model: this.get('tab.model')
      });
      this.set('dirty', dirty);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    },
    _display(model) {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = R_.dotPath('crud.lists.websites-lists-subPanels')(this) || [];
      let subTabOptions = subPanels.findBy('_data.value', activeSubTab.get?.('value'));
      if (RA.isNilOrEmpty(subTabOptions)) {
        subTabOptions = subPanels.findBy('_data.editingComponent', activeSubTab.get?.('component'));
      }

      // @TODO: there is a bug here findBy may return undefined

      const tabOptions = {
        loadInstructions: {
          component: subTabOptions.get?.('_data.component'),
          value: subTabOptions.get?.('_data.value')
        }
      };
      return this.onDisplay(model, {
        tabOptions
      });
    },
    *updateRecordTask() {
      yield this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        model: this.dirty,
        onAfter: this.afterUpdateRecord,
        tab: this.tab
      });
    },
    afterUpdateRecord(saved) {
      this._display(saved);
    },
    afterCancelEditing(original) {
      this._display(original);
    },
    actions: {
      cancel(dirty) {
        this.cancelEditing({
          adapterName: this.adapterName,
          dirty: dirty
        });
      }
    }
  }, _applyDecoratedDescriptor(_obj, "updateRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "updateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterUpdateRecord", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterUpdateRecord"), _obj), _obj));
});