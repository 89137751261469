define("client/pods/components/dispatch/processes-item-waybills-seven-eleven/last-milestone/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DispatchProcessesItemWaybillsSevenElevenLastMilestone extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "daysDangerObject", {
        shipped: {
          waybillCreated: 1,
          readyForPickupAtDestination: 2,
          addressChangeRequired: 0
        },
        returns: {
          waybillCreated: 1,
          readyForPickupAtReturnDestination: 0,
          addressChangeRequired: 0
        }
      });
    }
    get badgeClassNames() {
      const daysDangerObject = this.daysDangerObject;
      const waybillProcessType = this?.args?.waybillProcessType || '';
      const milestoneData = this?.args?.milestoneData || {};
      const lastMilestone = milestoneData?.lastMilestone || '';
      let daysDanger = 0;
      if (waybillProcessType && lastMilestone) {
        daysDanger = R_.dotPathOr(0, 'waybillProcessType.lastMilestone')(daysDangerObject);
      }
      const docCountMoreThanDays = R.pipe(R.propOr([], 'groupedByDaysOld'), R.filter(data => R.gt(data.daysOld, daysDanger)), R.pluck('total'), R.reject(RA.isNilOrEmpty), R.sum)(milestoneData);
      if (docCountMoreThanDays > 0) {
        return '';
      }
      return 'element-btn__badge-light';
    }
  }
  _exports.default = DispatchProcessesItemWaybillsSevenElevenLastMilestone;
});