define("client/pods/components/flows/flows-editor/data-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U1UJAN6Q",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container flex-container-row flex-container--wrap\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@lists\",\"@onReloadSearchRecordsTask\",\"@onSearchTask\",\"@results\",\"@onPaginationTask\",\"@paginationIsFooter\",\"@isRunning\",\"@tableViewOptions\"],[\"flows/table-view/data-detail-row\",[23,0,[\"filters\"]],[23,0,[\"crud\",\"lists\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[23,0,[\"crud\",\"searchAdvancedTask\"]],[23,0,[\"filters\",\"results\"]],[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],false,[23,0,[\"getFlowCapturedData\",\"isRunning\"]],[28,\"hash\",null,[[\"flow\"],[[23,1,[]]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/flows-editor/data-display/template.hbs"
    }
  });
});