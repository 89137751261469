define("client/pods/components/products/products-editor/details-editor/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    contactsService: Ember.inject.service('contacts'),
    init() {
      this._super(...arguments);
      this.set('contactsFilters', this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          status: [this.constants.status.active]
        }
      }));
    },
    hasUploads: Ember.computed('showUploads', function () {
      if (RA.isNilOrEmpty(this.showUploads)) {
        return true;
      }
      return this.showUploads;
    }),
    hasInventoryOpening: Ember.computed('isNew', 'model._data.tracking', 'isInventory', function () {
      const isNew = this.isNew;
      const tracking = this.get('model._data.tracking');
      const isInventory = this.isInventory;
      if (isNew && tracking === this.get('constants.productTracking.on') && isInventory) {
        return true;
      }
      return false;
    }),
    isInventory: Ember.computed('model._data.type', function () {
      if (this.get('model._data.type') === this.get('constants.productTypes.inventory')) {
        return true;
      }
      return false;
    }),
    isProductSetsReadonly: Ember.computed('isNew', 'isEditing', 'isAllowedToDelete', function () {
      if (this.isNew) {
        return false;
      }
      let isProductSetsReadonly = false;
      const model = this.model;
      const isAllowedToDelete = R.propOr(false, 'isAllowedToDelete')(this);
      // option cannot be edited after saving
      // only allow for product general for now
      if (!this.isEditing) {
        return true;
      }
      const hasVariants = R.pathOr(false, ['_data', 'hasVariants'])(model);
      if (hasVariants) {
        return true;
      }
      if (isAllowedToDelete) {
        isProductSetsReadonly = false;
      } else {
        isProductSetsReadonly = true;
      }
      return isProductSetsReadonly;
    }),
    availableBarcodesList: Ember.computed('crud.lists.barcodes', 'model.{_data.barcodes.@each.barcodeType,synced}', function () {
      const alreadyAddedBarcodes = this.model._data.barcodes || [];
      const barcodes = this.crud.lists.barcodes || [];
      const alreadyAddedBarcodesTypes = R.pipe(R.map(R.path(['_data', 'barcodeType'])), R.reject(RA.isNilOrEmpty))(alreadyAddedBarcodes);
      return R.reject(barcodeData => {
        return R.includes(barcodeData?._data?.value)(alreadyAddedBarcodesTypes);
      })(barcodes);
    }),
    isNormalItem: Ember.computed('model._data.{isSet,isSetNew,isSetWithChoices,hasVariants}', function () {
      const model = this.model;
      const isSet = model.getData('isSet') || false;
      const isSetNew = model.getData('isSetNew') || false;
      const isSetWithChoices = model.getData('isSetWithChoices') || false;
      const hasVariants = model.getData('hasVariants') || false;
      if (isSet || isSetNew || isSetWithChoices || hasVariants) {
        return false;
      }
      return true;
    }),
    actions: {
      setSetType(setType) {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
        const selectedSetType = setType.getData('value');
        if (selectedSetType) {
          model.setData(selectedSetType, true);
        }
        // model.setData(selectedSetType, true)
        // this.setEditorSubPanels()
      },
      deselectSetType() {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
      },
      onSubmitAddProductDisplayTags() {
        this.model.addProductDisplayTags();
      },
      onSubmitDeleteProductDisplayTags(productDisplayTag) {
        const productDisplayTags = this.model.getData('productDisplayTags');
        productDisplayTags.removeObject(productDisplayTag);
      },
      loadSupplierKey(contact) {
        const model = this?.model || {};
        const contactKey = contact?._data?._key || '';
        model.setData('preferredSupplierKey', contactKey);
      },
      unloadSupplierKey() {
        const model = this?.model || {};
        model.setData('preferredSupplierKey', '');
      }
    }
  });
});