define("client/pods/transporter/waybill-record/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data = {}, attrs) {
      const self = this;
      data = data || {};
      data = self._transformShippingWaybillStatuses(data);
      this._super(data, attrs);
    },
    _transformShippingWaybillStatuses(data) {
      const shippingWaybillStatuses = R.propOr([], 'shippingWaybillStatuses')(data);
      if (RA.isNotNilOrEmpty(shippingWaybillStatuses)) {
        data.shippingWaybillStatuses = R.map(shippingWaybillStatus => {
          return this.dataManager.setAsRecord({
            adapterName: 'transporter/waybill-record/shipping-waybill-status',
            data: shippingWaybillStatus
          });
        })(shippingWaybillStatuses);
      }
      return data;
    }
  });
});