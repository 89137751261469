define("client/pods/components/transporters/waybill-display/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NvqkavnH",
    "block": "{\"symbols\":[\"transporterWaybillRecord\",\"@model\",\"@onFetchOverviewTask\"],\"statements\":[[4,\"each\",[[23,0,[\"transporterWaybillRecords\"]]],null,{\"statements\":[[0,\"  \"],[5,\"transporters/waybill-display/list/item\",[],[[\"@model\",\"@transporterWaybillRecord\",\"@onFetchOverviewTask\"],[[23,2,[]],[23,1,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/waybill-display/list/template.hbs"
    }
  });
});