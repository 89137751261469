define("client/pods/components/menus/nav-tree/nav-item/component", ["exports", "ramda", "ramda-adjunct", "ember-concurrency"], function (_exports, R, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    dragAndDrop: Ember.inject.service(),
    // tagName: 'div',
    // classNames: ['nav-tree__item'],
    translate: true,
    item: null,
    labelKey: 'label',
    label: Ember.computed('item', 'labelKey', 'isPinnedMenu', function () {
      const isPinnedMenu = this.isPinnedMenu || '';
      if (isPinnedMenu) {
        return this.item?._data?.pinLabel;
      }
      return this.item?._data[this.labelKey];
    }),
    draggable: Ember.computed('item', function () {
      const _key = this.item.getData('_key') || '';
      if (RA.isNilOrEmpty(_key)) {
        return false;
      }
      return true;
    }),
    _translate: Ember.computed('item._data.translate', function () {
      if (this?.item?._data?.translate === false) {
        return false;
      }
      return this.translate;
    }),
    onClick() {
      const item = this.item;
      const pinLabel = item.getData('pinLabel');
      if (!R.equals(pinLabel, 'favorites')) {
        this.onSelect(item);
      }
    },
    onDrop(droppedAt, results, indexDotPath = '_data.index', outerDrop, draggedFrom) {
      this.dragAndDrop.onDropWithIndexOld(droppedAt, results, indexDotPath = '_data.index', outerDrop, draggedFrom);
      this.onUpdateMenuIndexTask.perform({
        resetIndex: false
      });
    },
    pinTask: (0, _emberConcurrency.task)(function* (wizardItem) {
      const onPinTask = this.onPinTask;
      if (onPinTask?.perform) {
        yield onPinTask.perform(wizardItem);
      } else {
        yield onPinTask?.(wizardItem);
      }
    }),
    pinIcon: Ember.computed('pinType', 'item._data.isPinned', function () {
      const pinType = this.pinType || 'favorite';
      let isPinned = R.pathOr(false, ['_data', 'isPinned'])(this.item);
      switch (pinType) {
        case 'wizard':
          return isPinned ? 'far fa-check-square' : 'far fa-square';
        case 'favorite':
        default:
          return isPinned ? 'fas fa-star' : 'far fa-star';
      }
    }),
    showPinIcon: Ember.computed('pinTask', 'item._data.pinLabel', function () {
      if (!this.pinTask) {
        return false;
      }
      const pinLabel = R.pathOr('', ['item', '_data', 'pinLabel'])(this);
      if (!pinLabel || pinLabel === 'favorites' || pinLabel === 'wizards') {
        return false;
      }
      return true;
    })
  }, _applyDecoratedDescriptor(_obj, "onClick", [_dec], Object.getOwnPropertyDescriptor(_obj, "onClick"), _obj), _applyDecoratedDescriptor(_obj, "onDrop", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onDrop"), _obj), _obj));
});