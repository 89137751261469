define("client/pods/components/extensions/apps/tw/seven-eleven/report-status-days/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwSevenElevenReportStatusDaysComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _class = class ExtensionsAppsTwSevenElevenReportStatusDaysComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "server", _descriptor3, this);
      _initializerDefineProperty(this, "transporter", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      // @tracked report
      _initializerDefineProperty(this, "transporterModel", _descriptor6, this);
      _initializerDefineProperty(this, "transportersList", _descriptor7, this);
      _initializerDefineProperty(this, "fetchedReportDetails", _descriptor8, this);
      _initializerDefineProperty(this, "isRowVisible", _descriptor9, this);
      _initializerDefineProperty(this, "sevenElevenTransporterList", _descriptor10, this);
      _defineProperty(this, "daysOldArray", [0, 1, 2, 3, 4, 5, 6, 7]);
      this.crud.addLists(this, ['contacts/transporters', 'dispatch/lists/processes']);
    }
    afterLoadLists() {
      const transportersList = R.pathOr([], ['crud', 'lists', 'contacts-transporters'])(this);
      this.transportersList = transportersList;
      const transporterModel = transportersList.findBy('_data.transporterExtension', this.provider);
      if (transporterModel) {
        this.transporterModel = transporterModel;
      }
    }
    *fetchReportDetailsTask({
      type,
      segment
    }) {
      const fetchedReportDetails = this.fetchedReportDetails || {};
      if (RA.isNilOrEmpty(fetchedReportDetails[type])) {
        const segmentReport = yield this.args.onFetchReportTask.perform({
          type,
          segment
        });
        if (RA.isNotNilOrEmpty(segmentReport[type])) {
          const report = this?.args?.report || {};
          const segmentReportValue = segmentReport[type];
          Ember.set(report, type, segmentReportValue);
          // this.report = report
        }
      }
      fetchedReportDetails[type] = true;
      // @NOTE: this is required to refresh object in template
      this.fetchedReportDetails = fetchedReportDetails;
      const isRowVisible = this.isRowVisible;
      const isVisible = !isRowVisible[`${type}-${segment}`];
      isRowVisible[`${type}-${segment}`] = isVisible;

      // @NOTE: this is required to refresh object in template
      this.isRowVisible = isRowVisible;
    }
    resetTracked() {
      this.fetchedReportDetails = {};
      this.isRowVisible = {};
    }

    // get badgeCount () {
    //   const report = this?.args?.report || {}

    //   const noWaybillsLength = R.pluck('docCount')(report?.noWaybills || []) || 0
    //   const awaitingDispatchLength = R.pluck('docCount')(report?.awaitingDispatch || []) || 0
    //   const shippedLength = R.pluck('docCount')(report?.shipped || []) || 0
    //   const returnsLength = R.pluck('docCount')(report?.returns || []) || 0

    //   return R.sum([
    //     noWaybillsLength,
    //     awaitingDispatchLength,
    //     shippedLength,
    //     returnsLength
    //   ])
    // }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transporter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "transportersList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetchedReportDetails", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isRowVisible", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sevenElevenTransporterList", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchReportDetailsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReportDetailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetTracked", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "resetTracked"), _class.prototype), _class);
});