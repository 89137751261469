define("client/pods/components/dispatch/panels-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+dOXVeS5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-sub u-no-print\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-header-1\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tt\",[\"inventory status report\"],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"dispatch\",\"fetchDispatchSummaryTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"dispatch/panels-display/list-summary\",null,[[\"autoFetchProduct\",\"allowProcesses\",\"isDispatchedProcessModel\",\"isDispatchedBeforeToday\",\"productsList\",\"summaryData\"],[true,[24,[\"allowProcesses\"]],[24,[\"isDispatchedProcessModel\"]],[24,[\"isDispatchedBeforeToday\"]],[24,[\"productsList\"]],[24,[\"summaryData\",\"summaryData\"]]]]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/panels-summary/template.hbs"
    }
  });
});