define("client/pods/transporter/waybill-record/shipping-waybill-status/model", ["exports", "client/pods/base/model", "client/constants/index"], function (_exports, _model, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        status: _index.default.shippingStatus.completed,
        translateStatus: true,
        dateZ: this.getNowZ()
      };
    }
  });
});