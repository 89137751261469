define("client/pods/components/elements/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UmNXv8RR",
    "block": "{\"symbols\":[\"@defaultSwatchesList\",\"@placeholder\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-container-row flex-container--no-wrap\"],[8],[0,\"\\n  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"inputPvtClassNames\",\"placeholder\",\"value\"],[[24,[\"readonly\"]],\"color-picker__input\",[23,2,[]],[24,[\"value\"]]]]],false],[0,\"\\n\\n  \"],[1,[28,\"elements/element-btn\",null,[[\"icon\",\"onSubmit\",\"classNames\",\"value\"],[\"fas fa-eye-dropper\",[28,\"action\",[[23,0,[]],\"toggleColorPicker\"],null],[23,0,[\"colorPickerClassNames\"]],\"\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showColorPicker\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-container-new\",[],[[\"@closeOnOverlayClick\"],[true]],{\"statements\":[[0,\"\\n    \"],[5,\"color-picker\",[],[[\"@color\",\"@colorKey\",\"@model\",\"@defaultSwatchesList\",\"@toggleColorPicker\"],[[22,\"value\"],[22,\"colorKey\"],[22,\"model\"],[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleColorPicker\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/color-picker/template.hbs"
    }
  });
});