define("client/pods/components/transporters/waybill-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct", "ramda", "client/constants/index"], function (_exports, _component, _emberConcurrencyDecorators, RA, R, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersWaybillEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class TransportersWaybillEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "date", _descriptor2, this);
      _initializerDefineProperty(this, "dirty", _descriptor3, this);
      _defineProperty(this, "adapterName", 'transporter/waybill-record/shipping-waybill-status');
      this.crud.addLists(this, ['transporter/waybill-record/lists/shipping-waybill-status-type']);
    }
    setupShippingWaybillStatusDirty() {
      let dirty;
      if (this.args.isEditing) {
        dirty = this.crud.setupDirty({
          adapterName: this.adapterName,
          model: this.args.model
        });
      } else {
        dirty = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data: {}
        });
      }
      const transporterWaybillRecordModel = this.args.transporterWaybillRecordModel;
      dirty.setData('resource', transporterWaybillRecordModel._data.resource);
      dirty.setData('resourceKey', transporterWaybillRecordModel._data.resourceKey);
      dirty.setData('resourceId', this.args.resourceId);
      this.dirty = dirty;
      const selectedShippingWaybillStatusType = R.pipe(R.pathOr([], ['crud', 'lists', 'transporter-waybill-record-lists-shipping-waybill-status-type']), R.find(R.pathEq(['_data', 'value'], dirty.getData('status'))))(this);
      if (RA.isNotNilOrEmpty(selectedShippingWaybillStatusType)) {
        this.setShippingWaybillStatusParams(selectedShippingWaybillStatusType);
      }
    }
    setShippingWaybillStatusParams(selectedShippingWaybillStatusType) {
      const dirty = this.dirty;
      const transporterWaybillRecordModel = this.args.transporterWaybillRecordModel;
      dirty.setData('isCustomStatus', true);
      dirty.setData('provider', transporterWaybillRecordModel._data.provider || '');
      dirty.setData('paymentNumber', transporterWaybillRecordModel._data.paymentNumber || '');
      dirty.setData('milestoneCode', selectedShippingWaybillStatusType.getData('milestoneCode'));
      if (!this.args.isEditing) {
        dirty.setData('statusMessage', selectedShippingWaybillStatusType.getData('statusMessage'));
        dirty.setData('message', selectedShippingWaybillStatusType.getData('statusMessage'));
      }
    }
    cancelShippingWaybillStatusDirty(onCloseDialogAction) {
      if (this.args.isEditing) {
        this.crud.cancelEditing({
          adapterName: this.adapterName,
          dirty: this.dirty
        });
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
    *saveTask(onCloseDialogAction) {
      const dirty = this.dirty;
      if (this.args.isEditing) {
        yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterName,
          model: dirty
        });
      } else {
        yield this.crud.createRecordTask.perform({
          adapterName: this.adapterName,
          model: dirty
        });
      }
      if (this?.args?.onFetchOverviewTask?.()) {
        yield this.args.onFetchOverviewTask();
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
    *removeTask(onCloseDialogAction) {
      const dirty = this.dirty;
      yield this.crud.removeRecordTask.perform({
        adapterName: this.adapterName,
        model: dirty,
        noConfirm: true // already has confirm in the btnDelete
      });
      if (this?.args?.onFetchOverviewTask?.()) {
        yield this.args.onFetchOverviewTask();
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupShippingWaybillStatusDirty", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setupShippingWaybillStatusDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShippingWaybillStatusParams", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setShippingWaybillStatusParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelShippingWaybillStatusDirty", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "cancelShippingWaybillStatusDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _class);
});