define("client/pods/application/lists/progress/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ApplicationListsProgressAdapter extends _adapter.default.extend(_adaptersNew.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "resourcePath", 'api/protected/app/lists/wizards');
      _defineProperty(this, "modelObj", _model.default);
      _defineProperty(this, "idParam", false);
    }
    findAll() {
      const data = [{
        value: 'unfinished',
        label: 'is finished'
      }, {
        value: 'isFinished',
        label: 'is finished'
      }, {
        value: 'all',
        label: 'all'
      }];
      return _nventor.default.resolve(data);
    }
  }
  _exports.default = ApplicationListsProgressAdapter;
});