define("client/pods/components/flows/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hO0IyyUW",
    "block": "{\"symbols\":[\"@tab\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onDisplay\",\"@onEdit\",\"@results\",\"@onPaginationTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container flex-container-row flex-container--wrap\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@tableClassNames\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@tableViewOptions\"],[[23,1,[]],\"new-table-view--data\",\"flows/table-view/detail-row\",[23,2,[\"results\"]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,5,[]],[28,\"hash\",null,[[\"allowUploads\"],[true]]]]]],[0,\"\\n\\n    \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@showPageNumbers\",\"@onPaginationTask\"],[[23,2,[]],[23,7,[]],true,[23,8,[]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/panels-dash/template.hbs"
    }
  });
});