define("client/translations/zh-tw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "": "",
    "#": "#",
    "%": "%",
    "...": "...",
    "1 day": "1天",
    "1 hour": "1小時",
    "1 point equal to": "一點 = $",
    "1 week": "一週",
    "120+ days": "120天以上",
    "120cm": "120cm",
    "12Months": "1年",
    "15 minutes": "15分鐘",
    "150cm": "150cm",
    "2 days": "2天",
    "2 hours": "2小時",
    "24Months": "2年",
    "3 days": "3天",
    "3 hours": "3小時",
    "30 days": "30天",
    "30 minutes": "30分鐘",
    "31 characters max": "最多31字元",
    "3rd party": "第三方平台",
    "3rd-party": "第三方平台",
    "4 days": "4天",
    "4 digit code": "四碼",
    "4 hours": "4小時",
    "5 hours": "5小時",
    "6 hours": "6小時",
    "60 days": "60天",
    "60cm": "60cm",
    "6Months": "6個月",
    "90 days": "90天",
    "90cm": "90cm",
    "ATM_BOT": "台灣銀行 ATM",
    "ATM_CATHAY": "國泰世華銀行 ATM (暫不提供)",
    "ATM_CHINATRUST": "中國信託 ATM",
    "ATM_ESUN": "玉山銀行 ATM (暫不提供)",
    "ATM_FIRST": "第一銀行 ATM",
    "ATM_FUBON": "台北富邦 ATM (暫不提供)",
    "ATM_LAND": "土地銀行 ATM",
    "ATM_TACHONG": "大眾銀行 ATM (2018 年已併到元大銀行",
    "ATM_TAISHIN": "台新銀行 ATM",
    "Api Key": "串接金鑰",
    "B2C": "B2C",
    "C2C": "C2C",
    "CODE128": "CODE128",
    "CODE39": "CODE39",
    "EAN13": "EAN13",
    "EAN2": "EAN2",
    "EAN5": "EAN5",
    "EAN8": "EAN8",
    "HCT": "新竹物流",
    "Mailgun": "Mailgun",
    "Maximum Range 3 Month": "最大範圍為3個月",
    "Taiwan": "台灣",
    "Will not export campaigns without start and end date": "不會匯出沒有開始和結束日期的活動",
    "Wrong format of param CustomerPhone or length exceeded.": "參數客戶電話格式錯誤或長度超出",
    "_blank_": " ",
    "abandoned": "放棄",
    "access token": "存取權杖",
    "account": "帳戶",
    "account code": "帳戶代碼",
    "account description": "帳戶描述",
    "account last 5 digits": "後五碼",
    "account number": "帳戶號碼",
    "account type": "帳戶類別",
    "accountant (view only)": "帳務人員(僅有閱讀權限)",
    "accounts": "帳戶",
    "active": "有效的",
    "activity": "交易歷史",
    "add": "增加",
    "add activity": "新增事件",
    "add all": "添加全部",
    "add attachment": "新增附件",
    "add automation": "新增滿額禮或加購",
    "add bulk": "批次上架",
    "add cell": "並排區塊",
    "add criteria": "新增條件",
    "add custom worksheet": "增加自訂工作表",
    "add department": "新增部門",
    "add detail": "增加詳細資料",
    "add display tags": "新增圖片文字標籤",
    "add form step": "新增表單步驟",
    "add help": "Add Help",
    "add link": "新增連結",
    "add new adjustment": "增加新佣金調整",
    "add new choices": "增加分區任選",
    "add new customization": "新增客製化規格",
    "add new record": "Add New Record",
    "add new rule": "新增規則",
    "add new schedule": "新增排程",
    "add new variant type": "增加產品規格",
    "add person": "新增聯絡人",
    "add prize": "新增獎品",
    "add product": "增加產品",
    "add product to shop": "上架產品",
    "add row": "增加橫列",
    "add schedule": "增加時間表",
    "add spec": "新增規格",
    "add status": "新增狀態",
    "add step": "新增步驟",
    "add surcharge": "新增附加費用",
    "add tab": "新增標頭標籤",
    "add to cart": "加入購物車",
    "add to shop": "加入商店",
    "addAll": "添加全部",
    "addToCartAndChooseOptions": "顯示加入購物車及選取內容文字",
    "addToCartForAll": "統一顯示加入購物車文字",
    "added": "已加入",
    "added member level points will be added to current member level points": "新增的會員點數將會被加入現有會員點數中",
    "additional description": "更多描述",
    "additional free shipping rule": "額外免運費條件",
    "additional information": "其他資料",
    "additional message": "更多電子信箱內文",
    "address": "地址",
    "addresses": "地址",
    "addtional checkout information": "顯示在結帳頁面的額外資訊",
    "addtional item checkout information": "關於產品的額外資訊，例如：過敏原標示",
    "adjust all commission base costs": "調整所有抽成底價",
    "adjust currently available preOrder qty": "調整目前可預購數量",
    "adjust currently available qty": "調整目前可用數量",
    "adjust indexes": "調整排序",
    "adjust inventory": "調整庫存",
    "adjust limit preorder": "調整預購限制數量",
    "adjust limit qty": "調整限制數量",
    "adjust limited preorder qty": "調整預購限制數量",
    "adjust limited qty": "調整限制數量",
    "adjust limited qty by": "預設限制數量方式",
    "adjust member level": "調整會員等級",
    "adjust prices": "調整價格",
    "adjust total qty": "設定數量",
    "adjusted commission base cost": "調整後佣金成本基數",
    "adjusted commission total amount": "調整後佣金總數",
    "adjusted shipping fee": "調整後運費",
    "adjustment": "調整",
    "adjustment amount": "依使用情況調整的會員點數",
    "adjustment this month": "本月調整",
    "adjustments": "調整",
    "admin": "管理員",
    "admin->credit card": "管理員 > 信用卡",
    "admin->maintenance": "管理員 > 系統維護",
    "admin->members": "管理員 > 會員",
    "admin->tw_eInvoicing": "管理員 > 電子發票",
    "advanced": "進階",
    "advanced search": "進階篩選",
    "advanced-settings": "進階設定",
    "advancedTemplate": "進階模板",
    "advertisement": "廣告",
    "advertiser id": "Advertiser_ID",
    "after checkout": "結帳後",
    "after noon": "中午以後",
    "afterNoon": "中午以後",
    "age categories": "年齡分類",
    "ages": "年齡",
    "alcoholic beverage / oil / vinegar / sauce": "酒/油/醋/醬",
    "align_center": "置中",
    "align_left": "靠左",
    "align_right": "靠右",
    "all": "全部",
    "all activity": "所有紀錄",
    "all banks": "所有銀行",
    "all qty": "所有數量",
    "all undispatched": "全部取消出貨",
    "allTags": "Show All Tags (Unique and Combined)",
    "allow access date end": "允許登入結束日期",
    "allow access date start": "允許登入開始日期",
    "allow all campaigns": "所有商店/團購頁套用此官網主網域",
    "allow all campaigns per website": "所有商店/團購頁套用此官網主網域",
    "allow credit card installments": "允許分期付款",
    "allow custom pick up location": "允許自訂取貨點取貨",
    "allow customers to see enter promo code on checkout": "結帳時顯示\"我有優惠代碼\"",
    "allow discount for on sale items": "允許特價商品可以再折扣",
    "allow e-invoices to be issued": "允許開立電子發票",
    "allow linked campaigns": "允許連結活動",
    "allow on sale items": "允許特價商品",
    "allow order messages": "允許訂單留言功能",
    "allow pick up": "允許自取",
    "allow pos terminals": "允許 POS 終端",
    "allow preOrder": "允許預購",
    "allow print all waybills": "允許列印全部托運單",
    "allow printing from": "Allow printing from",
    "allow public template editor": "可建網頁公用模板",
    "allow recurring schedules": "允許定期購",
    "allow rss feeds on campaigns": "商店允許 RSS訂閱",
    "allow seo": "允許 SEO (搜尋引擎最佳化)",
    "allow shipping": "可運輸",
    "allow shopping": "允許購物車",
    "allow tax numbers per shop": "Allow tax numbers per shop",
    "allow to earn reward points": "允許累積會員點數",
    "allow to re-index all products": "允許重新排序所有產品",
    "allow to spend reward points": "允許使用會員點數折抵消費金額",
    "allow today pickup": "允許當日為起算日",
    "allow track reward for non member": "允許累積非會員買家的點數（非會員買家不能看到以及使用會員點數，成為會員後先前累積的點數就能看到和使用）",
    "allow transporter arrive time period": "允許指定黑貓物流到貨時段（僅適用於黑貓宅急便）",
    "allowApiAccess": "Allow api access",
    "allowShipSeparately": "允許贈品拆單運送",
    "allowed emails": "限可使用電子信箱",
    "allowed ip addresses": "允許的IP位址",
    "allowed product tags": "允許商品標籤",
    "allowed shipping zones": "目前可使用的運費規則",
    "allowed shop": "可使用商店",
    "allowed telephones": "限可使用手機帳號",
    "already imported": "先前已匯入",
    "already sent": "已送出",
    "always charge shipping fee": "一定要收運費",
    "always display wizard": "永遠顯示快速建立模示",
    "always hide": "永遠隱藏",
    "always hide menu": "永遠隱藏主選單",
    "amount": "金額",
    "amount due": "總撥款",
    "answer": "答案",
    "anti-fraud info": "防詐騙資訊",
    "anti-fraud text": "參考：我們不會要求並指示您至 ATM 操作。ATM 只有匯款及轉帳功能，無法解除分期付款或訂單錯誤問題。隨時可撥打 165 反詐騙諮詢專線。",
    "anyNextAvailableNumbers": "任何下一個可用號碼",
    "anytime": "任何時段",
    "api access": "Api Access",
    "api_settings wizard description": "設定快速建立簡介",
    "apply adjustment amount": "退款金額扣除已使用點數",
    "apply schedules to all images": "套用排程至所有圖片",
    "apply schedules to all pages": "將排套用至所有頁面",
    "apply to all orders": "應用至所有訂單",
    "apply to all product": "適用於全部商品",
    "apply to first order only": "僅應用至第一筆訂單",
    "apply to last order only": "僅應用至最後一筆訂單",
    "approve": "批准",
    "archive": "封存",
    "archived": "已封存",
    "are you sure you want to archive": "您確定要封存?",
    "are you sure you want to cancel invoice number": "您確定要取消發票",
    "are you sure you want to cancel this order?": "您確定要取消訂單嗎?",
    "are you sure you want to cancel?": "您確定要取消?",
    "are you sure you want to clear page": "您確定要刪除嗎?",
    "are you sure you want to close?": "您確定要關閉嗎?",
    "are you sure you want to comment and notify customer": "您確定要留言和通知顧客嗎?",
    "are you sure you want to copy from master to children": "您確定要複製主活動到所有子活動嗎？",
    "are you sure you want to delete": "您確定要刪除嗎?",
    "are you sure you want to delete comment": "您確定要刪除留言",
    "are you sure you want to delete image?": "您確定要刪除圖片?",
    "are you sure you want to delete this campaign": "您是否確定要刪除此商店活動頁面？您將無法再復原這個頁面。",
    "are you sure you want to delete this step? all linked steps will be deleted too": "您確定要刪除此步驟？所有與此連結的步驟也會連帶刪除",
    "are you sure you want to delete variant option": "您確定要刪除產品變體選項嗎？相關子產品也會連帶刪除。",
    "are you sure you want to dispatch all?": "您確定要全部發送嗎?",
    "are you sure you want to hold all?": "您確定要全部暫停嗎?",
    "are you sure you want to logout": "您確定要登出嗎?",
    "are you sure you want to mark as completed?": "你確認要標記為已完成？",
    "are you sure you want to merge contacts": "你確認要合併聯絡人？",
    "are you sure you want to re-index all products? this may take some time.": "您確認要重新排序所有商品嗎？這可能會花上一些時間。",
    "are you sure you want to re-sync product": "確定要重新整理嗎？",
    "are you sure you want to remove": "您確定要刪除嗎?",
    "are you sure you want to remove entire row?": "你確認要刪除這個橫列嗎？",
    "are you sure you want to remove item": "您確定要刪除嗎: {item}?",
    "are you sure you want to remove this cell?": "你確認要刪除這個儲存格嗎？",
    "are you sure you want to remove this custom sheet?": "你確認要刪除這個自訂工作表嗎？",
    "are you sure you want to reprint": "您確定要重新列印?",
    "are you sure you want to reset all rows?": "您確定要重置所有的橫列嗎？",
    "are you sure you want to revert to draft": "您確定要還原成草稿?",
    "are you sure you want to save as template": "您確定要存為模板?",
    "are you sure you want to unarchive": "您確定要取消封存?",
    "are you sure you want to undispatch all?": "您確定要全部取消發送嗎?",
    "are you sure you want to undo cancel?": "您確定要回復已取消?",
    "are you sure you want to undo void for document": "您確定要回復作廢的文件",
    "are you sure you want to undo?": "您確定要回復?",
    "are you sure you want to unhold all?": "您確定要全部取消暫停嗎?",
    "are you sure you want to void document": "您確定要作廢文件",
    "area": "地區",
    "array": {
      "min": "必填 {limit}"
    },
    "arrival date": "到貨日期",
    "arrow settings": "箭頭設定",
    "assets": "資產",
    "assign to": "指派給",
    "associates": "合作夥伴",
    "at least 6 characters": "至少6個字符",
    "atm_approved": "付款成功",
    "atm_attentionRequired": "需要手動處理",
    "atm_bsp": "永豐銀行",
    "atm_eSun": "玉山銀行",
    "atm_expired": "已超過付款期限",
    "atm_pending": "未付款",
    "atm_pending_short": "未付款",
    "atm_ubot": "聯邦銀行",
    "attentionRequired": "需要手動處理",
    "authorize refund": "授權退款",
    "auto": "自動",
    "auto cancel expired atm orders": "自動取消過期的ATM訂單",
    "auto expand": "自動展開",
    "auto fill column": "自動填入以下內容",
    "auto fill commissions": "自動填入歷史抽成",
    "auto fill shipping fee adjustment": "自動填入調整運費",
    "auto fill variant children's code": "自動填產品編碼",
    "auto fill variant children's name": "自動填產品名稱",
    "auto generate detail row": "自動產生商品細項橫列",
    "auto generate sales history (optional)": "自動產生銷售紀錄（非必填）",
    "auto hide": "自動隱藏",
    "auto index": "自動編號",
    "auto link member": "會員資料是否和聯絡人資料自動連結？",
    "auto mark as printed": "自動標記為已列印",
    "auto print invoices": "自動列印統一發票",
    "auto redirect to url when campaign has ended": "結團後自動到到不同的網址",
    "auto send dispatched email at 10pm every day": "auto send dispatched email at 10pm every day",
    "automatically end campaign": "自動結束活動",
    "automatically send payment reminders": "自動發送付款提醒信",
    "automatically update all following invoices": "自動更新其他發票號碼",
    "automation": "滿額禮或加購",
    "automation index": "加價購序數",
    "automation key": "加價購識別碼",
    "automation priority": "條件排序",
    "automation_action_mayAdd": "加價購",
    "automation_action_mustAdd": "滿額禮",
    "automation_qty": "金額/數量",
    "automation_selection_type": "添加方式",
    "automation_then": "則",
    "automation_title": "顯示標題",
    "automation_when": "當",
    "automations": "滿額禮或加購",
    "automations shipping behavior": "設定贈品（拆單）方式",
    "automations-settings": "自動化設定",
    "available": "可得到",
    "available preOrder qty": "可預購數量",
    "available promo code": "搜尋現有優惠代碼",
    "available qty": "可用庫存",
    "available to sell qty": "可賣數量",
    "available_sales_qty": "可銷售量",
    "average": "平均",
    "average item price": "平均價格",
    "average order value": "平均單價",
    "average price": "平均價格",
    "awaiting payment": "未付款",
    "awaitingPayment": "未付款",
    "awaitingRecurringPayment": "等待定期購付款",
    "awaitingReplyFromCustomer": "等待顧客回覆",
    "awaitingReplyFromMerchant": "等待商家回覆",
    "b2b": "B2B,",
    "b2c": "B2C",
    "back to previous": "回上一頁",
    "backInDown": "後方進入後向下",
    "backInUp": "後方進入後向上",
    "back_page": "回到上一頁",
    "background color": "背景色",
    "background color options": "背景顏色選項",
    "background image": "背景圖片",
    "balance sheet": "資產負債表",
    "bank": "銀行",
    "bank accounts": "銀行帳戶",
    "bank of china": "Bank of China",
    "bank transfer": "銀行轉帳",
    "bankTransfer": "銀行轉帳",
    "banking details": "銀行帳號資料",
    "banner": "橫幅圖",
    "banner bottom": "下方橫幅圖",
    "banner top": "上方橫幅圖",
    "barcode": "國際條碼",
    "barcode type": "條碼類型",
    "barcode types": "條碼類型",
    "barcode_ean13": "國際條碼(EAN13)",
    "barcode_generator_error": "無法產生條碼",
    "barcodes": "國際條碼",
    "base cost": "抽成底價",
    "basic": "基本",
    "basic information": "基本資料",
    "basic settings": "基本設定",
    "basic-settings": "基本設定",
    "batch number": "批號",
    "before checkout": "結帳前",
    "before noon": "中午以前",
    "beforeNoon": "中午以前",
    "beginning of month": "月初",
    "beta": "新功能(測試中/可試用)",
    "beta settings": "測試設定",
    "beta-settings": "測試設定",
    "better images": "better images",
    "bigger": "放大",
    "bigordr": "BigOrdr",
    "bigordr customer": "BigOrdr顧客",
    "bigordr-background-fixed": "滑動效果",
    "bigordr-background-fixed-background": "滑動效果底層",
    "bigordr-background-fixed-cover": "滑動效果上層",
    "bigordr-banner": "滿版圖文",
    "bigordr-cell": "並排區塊",
    "bigordr-flex-cell": "置中對齊區塊",
    "bigordr-form": "表單",
    "bigordr-grid": "圖文群組",
    "bigordr-grid-images": "圖片群組",
    "bigordr-inner-row": "一般區塊",
    "bigordr-picture-block": "圖片",
    "bigordr-picture-layer-container-block": "自定義橫幅",
    "bigordr-scratch-card": "刮刮卡",
    "bigordr-stories-block": "動態",
    "bigordr-strip": "留白圖文",
    "bigordr-strip-image": "留白圖文圖片",
    "bigordr-tabs-with-header-block": "頁籤傳送門",
    "billing address": "帳單地址",
    "billingAddress": "帳單地址",
    "birthday": "生日",
    "birthday email": "生日祝賀信件",
    "birthday months": "生日月份",
    "birthday reward recipients": "生日獎勵收受人",
    "birthday rewards": "生日獎勵",
    "birthday years": "生日年份",
    "black": "黑色",
    "blog": "文章",
    "blogs": "文章",
    "blogs images ratio": "文章圖片長寬比例",
    "blogs preview settings": "文章總覽設定",
    "blogs settings": "文章設定",
    "blogs show breadcrumb": "顯示麵包屑導覽",
    "blogs style": "文章排版風格",
    "blogs tags": "文章標籤",
    "blogs_preview_author": "總覽顯示文章作者",
    "blogs_preview_date": "總覽顯示文章日期",
    "blogs_preview_description": "總覽顯示文章描述",
    "blogs_preview_tags": "總覽顯示文章標籤",
    "blogs_preview_title": "總覽顯示文章標題",
    "blogs_style1": "風格 1（多篇文章一列）",
    "blogs_style2": "風格 2（單篇文章一列）",
    "blue": "藍色",
    "book qty": "帳面庫存量",
    "bot": "機器人",
    "bot builder": "建立聊天機器人",
    "bottom": "下方",
    "bounce": "彈跳",
    "brand": "品牌",
    "brands": "品牌",
    "brown": "棕色",
    "browse": "瀏覽",
    "browse mode": "瀏覽",
    "bsp": "永豐銀行Bank SinoPac",
    "bsp_hash": "Hash",
    "bsp_merchant_id": "商家編號",
    "bsp_payments": "永豐銀行Bank SinoPac",
    "bsp_virtualAccount": "授權啟用虛擬帳戶",
    "bulk discount": "量販折扣",
    "bulk discount conflicts": "量販折扣衝突",
    "bulk discount schedules": "量販折扣時間表",
    "bulk discounts": "量販折扣",
    "bulk edit": "大量編輯",
    "bulk insert products into document": "同步填入多個產品。請輸入產品代碼或產品名稱，且每個產品請分行。",
    "bulk split": "大量拆單",
    "bulk update document status and print": "批次更新狀態並列印",
    "bulk update status and print": "批次更新狀態並列印",
    "button": "按鈕",
    "button click": "按下按鈕",
    "button image options": "圖片選項",
    "button image radius": "圖片圓角值",
    "button image type": "按鈕圖片類型",
    "button label": "按鈕標籤",
    "button styles": "按鈕風格",
    "button1": "按鈕1",
    "button2": "按鈕2",
    "button3": "按鈕3",
    "buttons": "按鈕",
    "by documents": "依出貨單檢視",
    "by invoices": "依電子發票檢視",
    "by orders": "依訂單",
    "by products": "依產品",
    "byAllOrder": "顯示所有商品",
    "byAllOrdersWithOneColumnRow": "商品品項獨立顯示",
    "byAllOrdersWithOneColumnRowWithSummary": "商品品項獨立顯示 ＋明細",
    "byDetails": "顯示訂購商品數量",
    "byDetailsWithGrandTotals": "顯示訂購商品數量加總計",
    "byDispatchProcesses": "依出貨狀態",
    "byDocumentsStatuses": "依文件狀態",
    "c2c": "C2C",
    "calculate dispatch days starting from today": "允許當日為起算日",
    "calendar settings": "日曆設定",
    "calendar settings type": "日曆設定方式",
    "callback url": "重導向頁面網址",
    "campaign": "活動",
    "campaign detail product tags": "限本商店產品標籤",
    "campaign ended settings": "活動結束設定",
    "campaign expired settings": "結團設定",
    "campaign images": "活動圖片",
    "campaign key": "活動識別碼",
    "campaign notification": "活動提醒",
    "campaign period": "活動期間",
    "campaign products": "活動產品",
    "campaign qty": "活動數量",
    "campaign reports": "活動報表",
    "campaign review is not set!": "尚未設定活動審查流程！",
    "campaign settings": "商店設定",
    "campaign status": "商店狀態",
    "campaign-settings": "活動設定",
    "campaign_description": "商店簡介",
    "campaign_expired_template": "已結團內文",
    "campaigns": "活動",
    "campaigns finished syncing": "活動結束同步",
    "campaigns review": "活動審查流程",
    "campaigns review settings": "活動審查流程設定",
    "campaigns_are_syncing": "活動同步中，請無編輯主活動與子活動",
    "can preOrder": "可預購",
    "can split by transporter": "可依照物流業者拆單",
    "cancel": "取消",
    "cancel invoice": "取消統一發票",
    "cancel invoice reason": "取消統一發票原因 (最多20個字符)",
    "cancel order": "取消訂單",
    "cancel_save": "取消儲存",
    "cancelled": "已取消",
    "cancelled invoices": "已取消發票",
    "cannot have empty commission": "需要填入抽成",
    "cannot import due to incorrect data": "訂單資料有錯誤無法匯入",
    "cannot issue e-invoices": "無法開立",
    "cannot merge. contacts are linked to different members": "無法合併，聯絡人連結到不同的會員",
    "cannot refund credit card": "無法信用卡退刷總計",
    "cannot send": "無法送出",
    "cannot upload file, files must be under 10mb": "無法上傳文件，檔案必須小於10 MB。",
    "cannot void because document has used reward points": "因為當次消費賺取的會員點數在其他次消費中被使用，退款總額中將會依使用情形調整。",
    "cart": "購物車",
    "cart button style": "購物車按鈕樣式",
    "cart items": "購物車品項",
    "cash": "貨到付款",
    "cash before delivery": "銀行轉帳",
    "cash book": "銀行帳戶",
    "cash on delivery": "貨到付款",
    "cash payment fee": "貨到付款手續費",
    "cashBeforeDelivery": "ATM轉帳",
    "category": "性質",
    "cathay united bank": "Cathay United Bank",
    "cc_approved": "刷卡成功",
    "cc_awaitingPayment": "刷卡未付款",
    "cc_awaiting_3ds_verification": "3D驗證未完成",
    "cc_declined": "刷卡失敗",
    "cc_paidOut": "已撥款",
    "cc_partial_card_number": "卡號",
    "cc_ref": "付款編碼",
    "cc_refund": "刷卡退款",
    "cc_refundRequested": "刷卡申請退款中",
    "cc_refundRequested date": "申請退款日期",
    "cc_refund_total": "退款總計",
    "cc_refunded": "刷卡已退款",
    "cc_refunded date": "退款日期",
    "cc_total": "刷卡金額",
    "cc_unsuccessful": "不成功",
    "center": "中側",
    "chang hwa bank": "Chang Hwa Bank",
    "change convenience store": "更換便利商店",
    "change language? will reload page": "您確定要更改語言？此動作將會更新頁面",
    "change logs": "更新紀錄",
    "change password": "變更密碼",
    "change pickup method": "改變取貨方式(不建議)",
    "change return convenience store": "更換",
    "changes summary": "產品變動列表",
    "changes_deployed": "工程師已更新伺服器",
    "channel": "通路",
    "channel id": "頻道ID",
    "channel secret": "頻道密鑰",
    "channels": "通路",
    "charge shipping fee": "收運費",
    "charge to different account": "選擇其他帳號",
    "charge to different customer account": "選擇其他的顧客帳號",
    "charge to different supplier account": "選擇其他的供應商帳號",
    "chat messages": "聊天訊息",
    "check history": "查詢歷史",
    "check order status": "訂單查詢",
    "checkout": "結帳",
    "checkout convenience store description": "客製便利商店訊息",
    "checkout description": "產品結帳階段資訊",
    "checkout details description": "結帳步驟:顧客資料 客製化訊息",
    "checkout logs": "結帳紀錄(Checkout Logs)",
    "checkout page on select dispatch or arrival date": "結帳頁面選擇預計出貨日或是預計到貨日",
    "checkout payment description": "結帳步驟:付款 客製化訊息",
    "checkout settings": "結帳設定",
    "checkout summary description": "結帳步驟:購物車內容 客製化訊息",
    "checkout/details": "結帳顧客資料 (步驟 2/4)",
    "checkout/payment": "結帳付款 (步驟 3/4)",
    "checkout/success": "結帳成功 (步驟 4/4)",
    "checkout/summary": "結帳購物車內容 (步驟 1/4)",
    "checkoutId logs": "結帳ID紀錄(CheckoutId Logs)",
    "children campaign": "子活動",
    "choice": "選項",
    "choice name": "任選名稱",
    "choice price": "任選價格",
    "choiceIsEmpty": "任選商品內容空白",
    "choices": "選項",
    "choose between": "選擇",
    "choose form": "選擇問卷",
    "choose how to adjust qty": "選擇調整數量方式",
    "choose link": "選擇連結",
    "choose qty": "任選數量",
    "choose template": "套用模板",
    "chooseBetween": "選擇",
    "choose_all": "全部",
    "choose_between": "選擇",
    "citizenDigitalCertificate": "自然人憑證",
    "city": "城市",
    "ckeditor-language": "zh",
    "clear": "清空",
    "clear all": "清空",
    "clear column": "清除以下內容",
    "clear page": "刪除內文",
    "click id": "Click_ID",
    "close": "關閉",
    "close preview": "顏色預覽",
    "closing balance": "期終餘額",
    "clothing and accessories": "服飾配件",
    "cm": "公分",
    "code": "編碼",
    "collapse under product name": "顯示原始產品名稱",
    "colon not allowed": "不能使用冒號 「 : 」",
    "color template": "顏色模板",
    "color theme": "顏色主題",
    "colorOptions_solid": "底色",
    "colorOptions_transparent": "透明",
    "combine labels": "結合標籤",
    "combinedTagsOnly": "Group Tags Together (Combined Tags)",
    "comment": "留言",
    "comments": "留言",
    "comments settings": "客服留言設定",
    "commission": "抽成",
    "commission %": "抽成比例(％)",
    "commission % per item": "每件抽成比例(％)",
    "commission amount": "抽成金額",
    "commission amount per item": "每件抽成金額",
    "commission base cost": "抽成底價",
    "commission base cost per item": "每件商品抽成底價基數",
    "commission base costs": "抽成底價",
    "commission calculation": "抽成計算",
    "commission due date": "抽成付款日期",
    "commission due from last month": "上個月到期抽成",
    "commission due this month": "本月到期抽成",
    "commission payable to": "支付抽成者",
    "commission payout": "抽成支出",
    "commission per unit": "每單位抽成",
    "commission qty": "實際抽成數量",
    "commission ratio": "佣金比例 (%)",
    "commission refunded this month": "本月取消退回抽成",
    "commission total": "總抽成",
    "commission total amount": "抽成總金額",
    "commission_adjustment_error_msg": "發生錯誤！最大折扣金額為10",
    "commission_byAmount": "總額",
    "commission_byBaseCost": "低消",
    "commission_normalPercent": "百分比折扣(%)",
    "commission_twPercent": "折",
    "commissions": "抽成",
    "commissions adjustment": "佣金調整",
    "commissions calculated": "已算抽成",
    "commissions calculation method": "抽成計算方法",
    "commissions due": "應付抽成",
    "commissions due returned": "應付退貨抽成",
    "commissions generated": "產生的抽成",
    "commissions generated returned": "退貨抽成",
    "commissions payouts": "抽成支出",
    "commissions refunded": "抽成已取消退回",
    "commissions settings": "抽成設定",
    "commissions summary for this month": "本月抽成總結",
    "commissionsSettings": "抽成設定",
    "company": "公司",
    "company address": "公司地址",
    "company info": "公司資料",
    "company name": "公司名",
    "company opening hours": "公司營業時間",
    "company telephone": "公司電話",
    "complete": "已完成",
    "complex": "進階",
    "compound": "複合",
    "computer, communication and consumer electronics": "3C",
    "confirm": "確認",
    "confirm password": "再次確認密碼",
    "confirm_ignore": "已處理不要理",
    "connect domain": "連接網域",
    "connect shop": "連接商店",
    "connection error": "連線錯誤",
    "consignment": "寄賣",
    "consignment delivery": "寄賣出貨單",
    "consignment qty": "寄賣數量",
    "consignment receipt": "寄賣收據",
    "consignments": "寄賣",
    "contact": "聯絡人",
    "contact email": "收件人電子信箱",
    "contact info": "聯絡資料",
    "contact is not a member, send reward": "聯絡人並非會員，您確定要寄送獎勵嗎？",
    "contact key": "聯絡人識別碼",
    "contact name": "收件人姓名",
    "contact person": "聯絡人",
    "contact phone number": "聯絡電話",
    "contact tags report summary option": "Contact Tags Report Summary Option",
    "contact us": "聯絡我們",
    "contacts": "聯絡資料",
    "contacts rewards": "聯絡人點數",
    "contacts without member level": "沒有會員等級的聯絡人",
    "contacts_groups": "群組",
    "contains an invalid value": "輸入錯誤：內含無效數值",
    "contains duplicate value": "輸入錯誤，有重複值",
    "contra type": "類型",
    "contract end date": "合約終止日期",
    "contract fee": "合約費",
    "contract start date": "合約起始日期",
    "contract type": "合約業額",
    "convenience store": "超商取貨",
    "convenience store brand": "超商品牌",
    "convenience store id": "店號",
    "convenience store name": "店名",
    "conversion rate": "轉換率",
    "convert set items to only show set child items": "組合商品轉為只顯示組合產品子項",
    "convert to date": "轉換成日期",
    "convert to index": "轉換成指數",
    "convert to number": "轉換成數字",
    "copy": "複製",
    "copy & paste": "複製貼上",
    "copy existing bulk discounts": "複製現有的量販折扣",
    "copy from above": "複製上面",
    "copy images": "複製圖片",
    "copy link": "複製連結",
    "copy payment link": "複製付款連結",
    "copy preview url": "複製預覽網址",
    "cost of sales": "銷貨成本",
    "could not sync": "無法同步",
    "countries": "國家",
    "country": "國家",
    "countryCode": "國家地區代碼",
    "create from": "建立自",
    "create hct contact": "新增新竹物流聯絡資料",
    "create new adjustment": "抽成調整",
    "create new commissions": "新增抽成計算",
    "create product and add to shop": "新增產品並加入商店",
    "create seven-eleven contact": "創建 7-11 聯絡資料",
    "create t-cat contact": "建立黑貓聯絡人",
    "create workflow": "建立工作流程",
    "created by": "建立人",
    "created date": "建立日期",
    "credentials": "憑證",
    "credit": "貸記",
    "credit card": "信用卡",
    "credit card history": "刷卡歷史",
    "credit card installments: use defaults from settings": "分期付款: 使用設定預設",
    "credit card provider installments: tap pay": "信用卡提供者 (分期付款): Tap Pay",
    "credit card provider recurring: tap pay": "信用卡提供者 (定期購): Tap Pay",
    "credit card provider: tap pay": "信用卡提供者: Tap Pay",
    "credit card refund authorization request": "信用卡退刷請求",
    "credit card refunded email": "已退刷通知信",
    "credit card status": "信用卡狀態",
    "credit card transactions": "信用卡明細",
    "creditCard": "線上刷卡",
    "creditors": "應付 (債權人)",
    "criteria": "符合此條件",
    "criterias": "條件",
    "ctbc": "CTBC",
    "cumulative": "累計",
    "cumulative total": "累計",
    "currency": "貨幣",
    "currency decimal rounding": "貨幣小數點位數",
    "current": "本月",
    "current active": "正在進行的活動",
    "current available qty": "Current Available Qty",
    "current bulk discount": "正在套用的量販折扣",
    "current document": "目前文件",
    "current inventory book qty": "Current Inventory Book Qty",
    "current month": "本月",
    "current on hand qty": "Current On Hand Qty",
    "current points": "目前點數",
    "current sale": "銷售項目",
    "current schedule": "當前排程",
    "current year": "本年",
    "current_lang": "繁體中文",
    "currently active schedule": "現在有效的排程",
    "custom": "自訂",
    "custom author display": "自訂作者顯示方式",
    "custom date display": "自訂日期顯示方式",
    "custom id": "自訂商品ID (Facebook/Google 廣告目錄)",
    "custom limited qty per person text": "自訂限制銷售量文字",
    "custom message for additional free shipping": "未達免運門檻訊息內容",
    "custom message for quoted rate shipping": "報價運費的自訂訊息",
    "custom pick up locations": "自訂實體通路",
    "custom products display settings": "產品顯示設定",
    "custom width": "自訂寬度",
    "custom worksheet": "自訂工作表",
    "customer": "顧客",
    "customer account": "顧客帳號",
    "customer activity": "客戶歷史",
    "customer comments": "顧客留言",
    "customer expected arrival date": "顧客預期送達（取貨）日",
    "customer expected dispatch date": "顧客預期出貨（取貨）日",
    "customer payment method": "銷貨付款方式",
    "customer payment terms": "銷貨付款條件",
    "customer service": "客服",
    "customer statements": "顧客對帳表",
    "customer tags": "顧客標籤",
    "customerService": "客服",
    "customerSettings": "顧客設定",
    "customer_export": "訂購人",
    "customization options": "規格名稱",
    "customization prefix": "規格開頭 (將添加到產品名)",
    "customization type": "客製化規格",
    "customizations": "客製化",
    "customizations price behavior": "客製價格顯示",
    "customizations settings": "客製化設定",
    "customize worksheet": "自訂工作表",
    "customized export": "自訂匯出",
    "daily": "每日",
    "daily necessities": "生活用品",
    "daily revenue": "每日營業額",
    "danger editing message": "編輯可能導致原記錄無法復原，確定要繼續進行？",
    "dark color": "暗色",
    "darkColor": "暗色",
    "dashboard": "分析",
    "data": "資料",
    "data exceeds its size limit. cannot save": "資料超過大小限制，無法儲存。",
    "data feed": "Data feed",
    "data out of date": "您的資料已過期",
    "data was updated": "資料已變動",
    "dataType_date": "日期",
    "dataType_email": "電子郵件",
    "dataType_mobile": "手機",
    "dataType_number": "數字",
    "dataType_telephone": "電話",
    "dataType_text": "文字",
    "date": "日期",
    "date conflict": "日期衝突",
    "date end": "結束日期",
    "date error": "Date Error",
    "date is required": "結帳頁到(出)貨日必填",
    "date period for overview": "總覽顯示期間",
    "date range": "日期範圍",
    "date settings": "日期設定",
    "date start": "開始日期",
    "date_ascending": "日期由舊到新",
    "date_descending": "日期由新到舊",
    "date_period": "日期",
    "day": "天",
    "day of month": "日期",
    "days": "天",
    "days after checkout": "下單完成幾日內",
    "daysOld_0": "0",
    "daysOld_1": "1",
    "daysOld_2": "2",
    "daysOld_3": "3",
    "daysOld_4": "4",
    "daysOld_5": "5",
    "daysOld_6": "6",
    "daysOld_7": "7+",
    "debit": "借記",
    "debtors": "應收 (債務人)",
    "decrease": "減少",
    "deduct points earned": "扣除該筆訂單獲得的點數",
    "default": "預設",
    "default campaigns review": "預設活動審查流程",
    "default email content": "預設電子郵件內容",
    "default facebook page": "預設的Facebook頁面",
    "default for documents": "設為文件預設",
    "default image": "預設圖片",
    "default item tax": "預設稅額",
    "default name": "預設名稱",
    "default payment method": "預設付款方式",
    "default settings": "預設值",
    "default shop price filter": "預設價格篩選",
    "default status for when over sales limited qty": "超過限制銷售數量預設狀態",
    "default tax": "預設稅額",
    "default tax on purchases for products": "產品進貨預設稅額",
    "default tax on purchases for services": "服務進貨預設稅額",
    "default tax on sales for products": "產品銷貨預設稅額",
    "default tax on sales for services": "服務銷貨預設稅額",
    "default tax rate": "預設稅率",
    "default transporter settings": "預設物流設定",
    "default value for allow seo for new shops": "允許 SEO (搜尋引擎最佳化): 新增一頁式商店時，是否開啟",
    "default weight unit": "預設重量單位",
    "defaultCanceledEmailMessage": "已取消您的訂單，以下為取消此訂單的相關資訊",
    "defaultDispatchedEmailMessage": "您的訂單今日已幫您出貨",
    "defaultOrderedEmailMessage": "感謝您的支持與喜愛，您已完成訂單，如下為您的訂單明細",
    "defaultRefundedEmailMessage": "已退刷，以下列商品已完成退刷",
    "defaultShopRecurringLastOrderEmailMessage": "這是您最後一期定期購，如果您還想再次購買，請點擊以下連結。",
    "defaults": "預設",
    "defaults faqs": "預設:常見問題",
    "delete": "刪除",
    "delete campaign": "刪除商店活動",
    "delete_keyword": "刪除",
    "deleted": "已刪除",
    "delivered": "出貨",
    "delivered qty": "出貨數量",
    "deliveries": "出貨",
    "delivery": "出貨",
    "delivery address": "收件地址",
    "delivery date": "出貨日期",
    "delivery date is required": "結帳到貨日必填",
    "delivery date options": "交貨日期選項",
    "delivery date settings": "指定到(出)貨日期設定",
    "delivery max day description": "選擇結帳頁顯示預計到貨日，顧客最晚可選日期為 : {descriptionValue} ",
    "delivery max days": "備貨期後可指定天數",
    "delivery min day description": "選擇結帳頁顯示預計到貨日，需 {inputValue} 個工作天，顧客可選最早日期為 : {descriptionValue}",
    "delivery min days": "預估備貨天數",
    "delivery note": "出貨單",
    "delivery note print format": "出貨單列印格式",
    "delivery notes": "出貨單",
    "delivery time": "配送時間",
    "delivery-settings": "出貨設定",
    "deliveryAddress": "收件地址",
    "deliveryTime": "配送時間",
    "denied product tags": "禁止商品標籤",
    "department": "部門",
    "department name": "部門名稱",
    "departments": "部門",
    "description": "敘述",
    "description attachments": "商品附件",
    "deselect all": "取消全選",
    "designated zone": "指定運送區域",
    "desktop": "電腦",
    "detail": "詳細資料",
    "detailIsEmpty": "組合商品內容空白",
    "detailLimitTypes_campaign": "活動數量",
    "detailLimitTypes_inventory": "庫存量",
    "detailType_address": "地址 / 公司地址 / 收件地址",
    "detailType_email": "電子信箱",
    "detailType_note": "備註 / 連結",
    "detailType_phone": "電話 / 傳真 / 手機",
    "detailed statement": "詳細說明",
    "details": "詳細資料",
    "did not receive an activation email? resend activation email": "沒有收到認證信嗎? 重新寄送驗證信",
    "dietary supplement": "保健食品",
    "differentChildLength": "資料庫中未找到子項",
    "differentChoiceLength": "資料庫中未找到子項",
    "dimensions": "尺寸",
    "dimensions_height": "高",
    "dimensions_length": "長",
    "dimensions_width": "寬",
    "disabled": "未啟用的",
    "disallow shopping": "禁止購物",
    "disallowed arrival days": "設定結帳頁面不可到貨日(物流不可配送日，請依照所選物流進行勾選，日曆上此日期會反灰，即顧客不可選取日期)",
    "disallowed dispatch days": "設定不可出貨日（店家休息日，若選擇為結帳頁顯示預計出貨日，日曆上此日期會反灰，即顧客不可選取日期）",
    "disallowed shipping zones": "目前不提供的運費規則",
    "disc": "折扣",
    "discInclTotal": "含稅折扣",
    "discount": "折扣",
    "discount %": "折扣 %",
    "discount amount": "折扣額",
    "discount for on sale items": "特價商品折扣",
    "discount rate": "折扣%",
    "discount rate for on sale items": "特價商品折扣比例",
    "discount total": "總折扣",
    "discount when buy items": "折扣當購買商品",
    "discount when items value": "當購物滿 <金額> 折扣",
    "discounts": "折扣",
    "dispatch": "出貨管理",
    "dispatch (can create and update)": "出貨人員 - (可出貨，新增及編輯訂單，但無法瀏覽其他內容)",
    "dispatch (view only)": "出貨人員 - (只能查看出貨訂單，且無法編輯任何內容)",
    "dispatch all": "全部出貨",
    "dispatch date": "出貨日期",
    "dispatch dates settings": "出貨日期設定",
    "dispatch list number of records": "出貨管理: 出貨單筆數",
    "dispatch list sort by": "出貨管理: 排序",
    "dispatch max day description": "選擇結帳頁顯示預計出貨日，顧客最晚可選日期為 : {moreDescriptionValue} ",
    "dispatch max days": "最大可選預計出貨日 (訂單日期後)",
    "dispatch min day description": "選擇結帳頁顯示預計出貨日，需 {inputValue} 個工作天，顧客可選最早日期為 : {moreDescriptionValue}",
    "dispatch min days": "最小可選預計出貨日 (訂單日期後)",
    "dispatch notification": "出貨提醒",
    "dispatch process for new orders": "新訂單的出貨流程",
    "dispatch process shortcut": "配送流程快速鍵",
    "dispatch qty": "出貨數量",
    "dispatch status": "出貨狀態",
    "dispatch today": "今天出貨",
    "dispatch-settings": "出貨設定",
    "dispatched": "已出貨",
    "dispatched by date": "依出貨日期",
    "display": "顯示",
    "display blogs per row": "每橫列顯示的文章數量",
    "display blogs tags as filters": "顯示側邊文章分類",
    "display in shop": "使用進階模板",
    "display items per row": "每橫列顯示的產品數量",
    "display news per row": "每橫列顯示的最新消息數量",
    "display news tags": "顯示最新消息標籤",
    "display order": "顯示順序",
    "display products in shop": "同時使用進階模板及預設商品模板",
    "display style": "顯示風格",
    "display tag": "商品圖片標籤",
    "display tags": "商品圖片標籤",
    "display wizard": "快速建立模示",
    "display_faq": "顯示",
    "district": "區域",
    "districts": "區域",
    "dividends": "紅利",
    "do not use inventory qty": "不共用商品庫存量",
    "doc": "文件",
    "doc-pending": "未交總表",
    "docNo": "文件號碼",
    "document": "文件",
    "document delivery dates": "出貨日期",
    "document number": "文件號碼",
    "document settings": "文件設定",
    "document_row_action_buttons": "文件欄按鈕列表",
    "documentationId": "說明編號",
    "documents": "文件",
    "documents purchases": "進貨",
    "documents sales": "銷貨",
    "documents summary behavior": "出貨管理總計明細顯示",
    "documents-public": "客服訊息",
    "does not contain required value": "輸入錯誤",
    "domain": "網址",
    "domain connected": "網域已連接",
    "domain not connected": "網域未連接",
    "domain_validation_step1": "<ol class='spaced-list'> <li>開啟一個新的視窗或分頁, 登入您的網域主機帳號， 詳情請見&nbsp;<a href='/a/answer/48323' rel='noopener'>辨認您的網域&nbsp;host</a>.</li> <li>前往您的網域 DNS 紀錄， 可能也叫做 <strong>DNS管理 (DNS Management)</strong>, <strong>網域名稱</strong> <strong>伺服器管理</strong>, <strong>控制台</strong>, 或 <strong>進階設定</strong>. </li> <li>選擇新增紀錄的選項</li> </ol>",
    "domain_validation_step1_label": "步驟 1: 前往您的網域 DNS 紀錄",
    "domain_validation_step2": "<ol class='spaced-list'> <li>紀錄類型選擇 <strong>TXT</strong>。</li> <li>在 <strong>名稱/主機/別名</strong> 處，輸入 <strong>@</strong> 或直接空白。<br> 您的主機可能會需要您&nbsp;輸入您的網域，看起來會像 <em>example.com</em>,&nbsp;。您的其他 DNS 紀錄也可能包含您可以輸入的資訊。 </li> <li>在 <strong>存續時間 (TTL)</strong> 處，輸入 <strong>86400</strong> 或留空白。</li> <li>在 <strong>值/答案/目的</strong> 處，貼上您在步驟 1. 複製的紀錄</li> <li>儲存紀錄</li> </ol>",
    "domain_validation_step2_label": "步驟 2: 新增確認紀錄到您的網域主機",
    "domain_validation_step3_label": "步驟 3: 回到原本的地方並確認",
    "done": "完成",
    "dont have an account? try control pod for free": "還沒有帳戶嗎？現在可以免費試用!",
    "dot settings": "切換點設定",
    "double-row": "雙橫列",
    "download": "下載",
    "draft": "草稿",
    "drafts": "草稿",
    "dropdown": "下拉式選單",
    "due": "付款截止日",
    "due date": "預定日期",
    "duplicate": "資料重複",
    "duplicate addresses": "地址重複",
    "duplicate document": "複製",
    "duplicate product": "產品重複",
    "duplicate value": "資料重複",
    "duplicate waybills": "托運單重複",
    "duplicated": "Duplicated",
    "duplicated contact data": "聯絡人資料已重複（自動連結時找到信箱有重複）",
    "duration": "持續時間",
    "eInvoice": "電子發票",
    "eInvoicePrinterTerminal": "電子發票印表機主機",
    "each": "每個",
    "earliest": "最舊的",
    "earliest delivery date": "選擇結帳頁最早可送達（取貨）日期",
    "ecPay": "綠界科技",
    "ecPay_creditCard": "授權啟用綠界信用卡支付",
    "ecPay_eInvoice": "綠界科技: 電子發票",
    "ecPay_hash_iv": "Hash IV",
    "ecPay_hash_key": "Hash Key",
    "ecPay_installments": "綠界科技 (分期付款)",
    "ecPay_merchant": "商家",
    "ecPay_merchant_id": "商家編號",
    "ecPay_payments": "綠界科技 EcPay Payments",
    "ecPay_virtualAccount": "授權啟用虛擬帳戶",
    "edit": "編輯",
    "edit convenience store address": "編輯超商店址(不建議)",
    "edit functions": "編輯功能",
    "edit sales invoice": "編輯出貨單",
    "edit sales order": "編輯訂單",
    "edit status": "編輯狀態",
    "edit store location address": "編輯實體通路店址(不建議)",
    "edit template": "Edit Template",
    "einvoice printer terminal": "電子發票印表機主機",
    "email": "電子信箱",
    "email campaign": "活動信件",
    "email campaigns": "活動信件",
    "email delivery note": "寄送出貨信件",
    "email document": "寄送電子文件",
    "email message": "電子信箱內文",
    "email message for recipient": "給被推薦人的電子郵件訊息",
    "email message for referrer": "給推薦人的電子郵件訊息",
    "email message to buy more": "最後一期定期購的信件內容",
    "email not sent": "無法發送電子郵件",
    "email payment reminder": "發送付款提醒",
    "email placeholder": "語法",
    "email sent to": "電子郵件已發送",
    "email settings": "電子信箱設定",
    "email signature": "電子信箱簽名檔",
    "email subject": "電子信箱主旨",
    "email_canceled_order_default_subject": "已取消您的訂單",
    "email_dispatched_default_subject": "出貨通知",
    "email_event_delivered": "已發送",
    "email_event_failed": "發送錯誤",
    "email_event_opened": "已打開",
    "email_payment_reminder_subject": "付款提醒",
    "email_placeholder_desc": "您可以直接複製語法放到您想顯示的位置",
    "email_queued": "排程中",
    "email_refunded_order_default_subject": "已退刷",
    "email_test_to_address": "測試用電子信箱",
    "email_test_to_name": "測試用聯絡姓名",
    "emails": "電子信箱",
    "emails to send": "電子郵件發送數量",
    "emails->all activity": "電子信箱 > 所有紀錄",
    "emails->email campaign": "電子信箱 > 電子郵件活動",
    "employee": "員工",
    "empty": "空",
    "enable": "啟用",
    "enable allow print all waybills on any dispatch process": "允許任意配送流程啟用打印所有出貨單功能",
    "enable customizations": "啟用客製化",
    "enable facebook chat": "啟用Facebook聊天",
    "enable facebook chat by default": "預設開啟Facebook聊天功能",
    "enable facebook pixel conversion api": "啟用 Facebook 像素(Pixel) 轉換 API",
    "enable installments by default": "允許分期付款",
    "enable line chat": "啟用Line聊天",
    "enable line chat by default": "預設開啟Line聊天功能",
    "enable recurring schedules": "允許定期購",
    "enable shop price filter": "啟用價格篩選",
    "enable shop.com service": "啟用 Shop.com(美安) 服務",
    "enabled": "已啟用",
    "encountered unknown error": "系統正在嘗試修復問題，建議您先重新整理頁面。",
    "end": "止",
    "end date": "終止日期",
    "end of invoice month": "從月底開始算",
    "end of month": "月底",
    "end on": "止",
    "end time": "終止時間",
    "enquiry": "詢價單",
    "enter duration (sec)": "輸入時長(秒)",
    "enter fullscreen": "進入全螢幕",
    "enter pos mode": "進入POS模式",
    "entered amount exceeds the outstanding amount": "輸入金額超過未付金額",
    "equals": "等於",
    "equity": "權益",
    "error fetching barcode": "下載條碼錯誤",
    "error message": "錯誤信息",
    "error occurred": "發生錯誤",
    "error occurred please try again later": "發生錯誤",
    "error syncing. could not sync": "同步錯誤，無法同步",
    "error while generating waybill numbers": "產生托運單編碼時出現錯誤",
    "errors": "錯誤信息",
    "estimate delivery date": "預計送達日期",
    "estimated commissions": "預估抽成",
    "estimated commissions percentage": "預估抽成百分比",
    "estimated delivery date": "預計送達日期",
    "estimated earnings": "預計收益",
    "estimated total commissions": "預估分潤（抽成{percentage}%）",
    "esun": "E Sun",
    "event": "事件",
    "everytime you print it will generate a new Waybill number. are you sure you want to print?": "當您每次列印時都會產生一個新的托運編碼，您確定要列印嗎？",
    "exact date": "指定日期",
    "example": "範例",
    "exclude all stores": "未指定商店訂單",
    "exclude shipping from sales history": "銷售紀錄排除運費",
    "exclude specific dates": "排除特定日期（點選日期加入排除特定日期列表，已選取的日期呈現反灰 ; 若想取消，再次點擊反灰日期以取消該日）",
    "excludeFreeShipping": "排除免運規則",
    "excluded dates": "已排除特定日期",
    "excluding tax discount": "未稅折扣",
    "exclusive amount": "未稅額",
    "exclusive base cost": "抽成未稅底價",
    "exclusive discount": "未稅折扣",
    "exclusive price": "未稅單價",
    "exclusive selling price": "未稅售價",
    "exclusive total": "未稅總計",
    "exclusive total after discount": "折扣後未稅總計",
    "existing": "現有的",
    "exit fullscreen": "離開全螢幕",
    "expand": "展開",
    "expand sub menus": "永遠顯示子選單",
    "expand to show other aliases": "展開，顯示原始產品名稱和別名",
    "expected arrival date": "預計到貨日期",
    "expected arrival date subtitle": "結帳頁預期送達(出貨)日副標題 : 顯示於結帳頁日曆上方",
    "expected close date": "預計結束日期",
    "expected dispatch date": "預計出貨日期",
    "expected prepare date": "預計備貨日期",
    "expenses": "開銷",
    "expire after days": "幾天後過期?",
    "expired": "已過期",
    "expiring reward points notification": "點數到期通知",
    "expiring reward points notification email": "點數到期通知信",
    "expiry end at": "期限結束",
    "expiry start from": "期限開始",
    "export": "匯出",
    "export HCT data": "匯出新竹物流資料",
    "export all data": "匯出全部已出貨",
    "export by": "Export By",
    "export campaign reports": "匯出活動報表",
    "export count": "匯出數量",
    "export data": "匯出資料",
    "export data and update status": "匯出及更新狀態",
    "export dispatched data": "匯出出貨資料",
    "export file format": "匯出檔案格式",
    "export invoices data": "匯出電子發票",
    "export items by payment status": "匯出交易歷史和付款狀態",
    "export limited qtys": "匯出限制數量",
    "export method": "輸出方法",
    "export order": "匯出順序",
    "export order details using specified order": "匯出時，按指定產品順序",
    "export products": "匯出產品",
    "export type": "匯出種類",
    "export_all_transporters": "全部物流資料",
    "export_eInvoices_all": "電子發票(成功&作廢)",
    "export_eInvoices_cancelled": "電子發票(作廢)",
    "export_eInvoices_details": "電子發票&產品明細",
    "export_eInvoices_successful": "電子發票(成功)",
    "export_orders_details": "訂單產品明細",
    "export_orders_info": "訂單明細",
    "export_orders_per_row": "訂購商品每項一列排序",
    "export_orders_total": "訂單總表（一）",
    "export_orders_total_two": "訂單總表（二）",
    "export_paid": "已收款",
    "export_paid-available": "已付款(現)",
    "export_paid-preOrder": "已付款(預)",
    "export_paid-total": "已付款(現+預)",
    "export_paid_export_orders_info": "已收款-訂單明細",
    "export_paid_export_transporter": "已收款-物流資料",
    "export_pay_on_delivery": "貨到付款",
    "export_pay_on_delivery_export_orders_info": "貨到付款-訂單明細",
    "export_pay_on_delivery_export_transporter": "貨到付款-物流資料",
    "export_preOrderLimitedQty": "原始預購庫存",
    "export_preOrderLimitedQty_remaining": "剩餘預購庫存",
    "export_salesLimitedQty": "原始現有庫存",
    "export_salesLimitedQty_remaining": "剩餘現有庫存",
    "export_total-available": "加總(現)",
    "export_total-preOrder": "加總(預)",
    "export_total-total": "加總(現+預)",
    "export_transporter": "物流資料",
    "export_unpaid-available": "未付款(現)",
    "export_unpaid-preOrder": "未付款(預)",
    "export_unpaid-total": "未付款(現+預)",
    "extensions": "擴充表單",
    "external": "外部",
    "facebook": "Facebook",
    "facebook chat": "Facebook聊天",
    "facebook login": "Facebook登入",
    "facebook login short intro": "自動在您的網站上啟用 Facebook Messenger 聊天外掛程式。在您登入後，選擇您打算啟用網站客服聊天室的頁面。",
    "facebook page": "Facebook專頁",
    "facebook page id": "Facebook 專頁代碼",
    "facebook page inbox": "Facebook頁面訊息",
    "facebook page name": "Facebook 專頁名稱",
    "facebook page url": "Facebook網址",
    "facebook pixel": "Facebook 像素(Pixel)",
    "facebook plugin": "Facebook外掛程式",
    "facebook store": "Facebook Store",
    "fadeIn": "淡入",
    "fadeInDown": "向下淡入",
    "family mart only provides room temperature delivery": "* 全家僅提供常溫配送",
    "familyMart": "全家",
    "faqs": "常見問題",
    "faqs display": "常見問題顯示設定",
    "faqs for product": "商品:常見問題",
    "faqs for shop": "商店:常見問題",
    "faqs-settings": "常見問答設定",
    "favicon": "頁面圖示",
    "favorite": "最愛",
    "favorites": "最愛",
    "fax": "傳真",
    "features": "功能",
    "fees": "手續費",
    "female": "女",
    "fetch waybills": "fetch waybills",
    "fetching data": "正在捉取資料",
    "field": "數據欄位",
    "file is too large": "檔案過大",
    "file is too small": "檔案過小",
    "file name": "檔案名稱",
    "files manager": "檔案總管",
    "files_manager_tags": "標籤",
    "fill to the bottom": "以下填入相同",
    "fill to the top": "以上填入相同",
    "fill to the top and bottom": "上下皆填入相同",
    "filters": "篩選",
    "final": "正式",
    "first bank": "First Bank",
    "first name": "姓名",
    "first waybill number": "托運單第一號",
    "fiscal": "財務的",
    "flash": "閃爍",
    "flat fee": "固定運費",
    "flat shipping fee": "固定運費",
    "flip": "翻轉",
    "flipInX": "上下翻轉",
    "flipInY": "水平翻轉",
    "floor": "下限",
    "flow_allow_custom_choice": "允許自訂選項",
    "flow_chat_message": "聊天訊息",
    "flow_collect_user_information_and_play_a_game": "蒐集使用者資訊並玩遊戲",
    "flow_collect_user_information_generate_and_email_promo_code": "蒐集使用者資訊，產生並以電子郵件發送優惠代碼",
    "flow_event": "觸發事件",
    "flow_event_allow_backdating": "允許回溯",
    "flow_event_display": "展示方式",
    "flow_event_greeting": "歡迎訊息",
    "flow_event_has_criterias": "有評判基準",
    "flow_form": "表單",
    "flow_form_steps": "表單步驟",
    "flow_game_unsuccessful_message": "遊戲挑戰失敗訊息",
    "flow_generate_promo_code": "產生優惠代碼",
    "flow_limits": "限制",
    "flow_message": "訊息",
    "flow_payload_value": "數值",
    "flow_popup": "彈出視窗",
    "flow_question": "問題",
    "flow_reply": "問答",
    "flow_reply_choices": "複選回覆",
    "flow_scratch_card": "刮刮樂",
    "flow_scratch_card_background_color": "刮刮樂背景顏色",
    "flow_scratch_card_background_image": "刮刮樂背景圖片 (比例4x3)",
    "flow_scratch_card_inner_background_image": "刮刮樂內容背景圖片 (比例4x3)",
    "flow_scratch_card_inner_text_color": "刮刮樂內容文字",
    "flow_scratch_card_text": "刮刮樂文字",
    "flow_scratch_card_text_color": "刮刮樂文字顏色",
    "flow_scrolling_message": "滾動訊息",
    "flow_scrolling_message_link": "滾動訊息連結",
    "flow_scrolling_message_timer": "滾動訊息持續時間(單位:秒，請輸入1至20內的數字)",
    "flow_steps": "步驟",
    "flow_wheel_of_fortune": "輪盤",
    "flows": "聊天機器人",
    "font alignment": "字體對齊",
    "font alignment for blogs": "文章字體對齊方式",
    "font color": "字體顏色",
    "font size": "字體大小",
    "font size for product name": "品名字體大小",
    "font weight for blogs title": "文章標題字體",
    "font_weight_bold": "粗體",
    "font_weight_normal": "正常",
    "footer": "頁尾",
    "for documents": "文件",
    "force update": "強制使用者更新",
    "force user to update to current version": "強制使用者更新",
    "forgot your password": "忘記密碼了嗎?",
    "form title": "表單名稱",
    "fourByThree": "4x3",
    "free": "免費",
    "free shipping condition": "免運金額門檻",
    "free shipping for orders greater and equal to": "訂單免運金額(含)以上",
    "free_shipping_any": "符合任何條件",
    "free_shipping_none": "無",
    "free_shipping_only": "符合所有條件",
    "frequently asked questions": "常見問題",
    "friday": "星期五",
    "friday opening hours": "星期五營業時間",
    "from": "從",
    "from day of order": "從下單日期開始",
    "from email address": "寄件人電子信箱",
    "from email name": "寄件人",
    "from end of invoice month": "從發票月底",
    "from invoice date": "從發票日",
    "from name": "寄件人",
    "from now": "從現在",
    "from now on only persons with the latest code will be linked": "重新產生報表連結",
    "from person": "發行人",
    "frozen": "冷凍",
    "ftp password": "FTP 密碼",
    "ftp user": "FTP 使用者名稱",
    "fubon": "Fubon",
    "full width": "全寬度",
    "full width video": "滿版影片",
    "full-width-image": "滿版圖片",
    "g": "公克",
    "gender": "性別",
    "general": "一般",
    "general food": "一般食品",
    "generate": "產生",
    "generate new waybills": "產生新托運單",
    "generate new waybills and print": "Generate new waybills and print",
    "generate password": "產生密碼",
    "generate sales invoice data": "產生銷售資料",
    "generate settings": "產生設定",
    "generate total qty per row": "每行加總總數量",
    "generate waybills": "產生托運單",
    "gjs-Attachment": "圖檔位置",
    "gjs-Background": "背景",
    "gjs-Background Color": "底色",
    "gjs-Blur": "模糊",
    "gjs-Border": "框線",
    "gjs-Border Color": "框線顏色",
    "gjs-Border Radius": "外框線弧度",
    "gjs-Border Style": "框線樣式",
    "gjs-Border Width": "框線寬度",
    "gjs-Bottom": "下",
    "gjs-Box Shadow": "外框陰影",
    "gjs-Center": "中",
    "gjs-Color": "文字顏色",
    "gjs-Dimension": "進階（調整尺寸）",
    "gjs-Font Family": "字型",
    "gjs-Font Size": "字體大小",
    "gjs-Height": "高",
    "gjs-Image": "圖片",
    "gjs-Images": "圖片",
    "gjs-Insert your text here": "請在這裡輸入文字",
    "gjs-Inside": "內側",
    "gjs-Justify": "調整",
    "gjs-Layer 1": "第一層",
    "gjs-Left": "左",
    "gjs-Line-height": "行距",
    "gjs-Margin": "外距（元素與元素之距離）",
    "gjs-Max-width": "最大寬度",
    "gjs-Min-height": "最小高度",
    "gjs-Opacity": "透明度",
    "gjs-Outside": "外側",
    "gjs-Padding": "內距（元素內容與元素邊界之距離）",
    "gjs-Position": "位置",
    "gjs-Repeat": "重複圖片",
    "gjs-Repeat-x": "水平重複填滿",
    "gjs-Repeat-y": "垂直重複填滿",
    "gjs-Right": "右",
    "gjs-Shadow type": "陰影樣式",
    "gjs-Size": "尺寸",
    "gjs-Spread": "粗度",
    "gjs-Style": "風格",
    "gjs-Text align": "對齊方式",
    "gjs-Top": "上",
    "gjs-Width": "寬",
    "gjs-X position": "橫座標",
    "gjs-Y position": "直座標",
    "gjs-arrows-background-hidden": "隱藏箭頭背景",
    "gjs-arrows-color": "箭頭顏色",
    "gjs-arrows-display": "顯示箭頭",
    "gjs-arrows-position": "箭頭位置",
    "gjs-arrows-size": "箭頭大小",
    "gjs-auto": "自動",
    "gjs-autoplay": "自動播放",
    "gjs-autoplay-delay": "輪播秒數",
    "gjs-box-shadow-color": "顏色",
    "gjs-center bottom": "下方置中",
    "gjs-center center": "置中",
    "gjs-center top": "上方置中",
    "gjs-contain": "原始大小",
    "gjs-cover": "填滿",
    "gjs-dashed": "虛線",
    "gjs-default-sliders-status": "輪播圖預設狀態",
    "gjs-dots-active-color": "圓點活躍顏色",
    "gjs-dots-background-hidden": "圓點隱藏背景",
    "gjs-dots-color": "圓點顏色",
    "gjs-dots-display": "圓點顯示",
    "gjs-dots-position": "圓點位置",
    "gjs-dots-size": "圓點尺寸",
    "gjs-dots-style": "輪播分頁樣式",
    "gjs-dots-style-dot": "點狀",
    "gjs-dots-style-line": "線狀",
    "gjs-dotted": "點線",
    "gjs-double": "雙框線",
    "gjs-fix-visible-items": "固定顯示項目數量",
    "gjs-fixed": "固定",
    "gjs-hidden": "隱藏",
    "gjs-infinite": "無限銜接",
    "gjs-inside": "內側",
    "gjs-left bottom": "坐下方",
    "gjs-left center": "左方置中",
    "gjs-left top": "左上方",
    "gjs-local": "原始位置",
    "gjs-no-repeat": "不重複",
    "gjs-non-Repeat": "不重複",
    "gjs-none": "無框線",
    "gjs-outside": "外側",
    "gjs-re-index": "重新排序",
    "gjs-repeat": "重複圖片",
    "gjs-repeat-x": "水平重複填滿",
    "gjs-repeat-y": "垂直重複填滿",
    "gjs-rewind": "回到開頭",
    "gjs-rewind-speed": "倒轉速度",
    "gjs-right bottom": "右下方",
    "gjs-right center": "右方置中",
    "gjs-right top": "右上方",
    "gjs-schedules": "排程",
    "gjs-scroll": "滾動",
    "gjs-shadow-type": "陰影樣式",
    "gjs-show-bigordr-story": "顯示story",
    "gjs-slide-speed": "輪播速度",
    "gjs-slides-per-page": "每頁輪播圖數量",
    "gjs-slides-to-scroll": "每次滑動的輪播圖數量",
    "gjs-smooth-scroll": "平滑滾動",
    "gjs-solid": "實線",
    "gjs-status-active": "有效",
    "gjs-status-inactive": "無效",
    "gjs-timing": "滑動速度",
    "gjs-title": "標題",
    "gjs-total-items": "項目總數",
    "gjs-visible": "可見",
    "gjs_landscape": "橫式",
    "gjs_portrait": "直式",
    "gjs_video ratio": "影片比例",
    "global settings": "全域設定",
    "go back to old version": "返回舊版",
    "go to": "前往",
    "google add to cart conversion label": "加入購物車轉換標籤",
    "google merchant center": "Google Merchant Center",
    "google purchase conversion label": "購買轉換標籤",
    "google tags manager": "Google Tags Manager",
    "google tracking id": "Google Tracking ID",
    "grains, vegetables and fruits": "穀物蔬果",
    "gray": "灰色",
    "greater than": "大於",
    "greater than or equal to": "大於或等於",
    "greaterThan": "大於",
    "greaterThanOrEqualTo": "大於或等於",
    "green": "綠色",
    "greeting": "歡迎訊息",
    "grid": "格線",
    "gross profit": "毛利",
    "group by product set with choices": "顯示任選商品",
    "group by tags": "使用標籤分類",
    "group by transporter": "所有列印格式依物流公司排序",
    "group picking list by transporter": "所有列印格式依物流公司排序",
    "group product by transporter": "依物流公司排序",
    "grouped to": "已合併至",
    "grouped with": "已合併",
    "has active campaigns": "活動中",
    "has break time": "有午休時間",
    "has cash payment fee": "自訂貨到付款手續費",
    "has choices": "有限定選項",
    "has custom limited qty per person text": "自訂限制銷售量文字",
    "has disallowed product tags": "有禁止商品標籤",
    "has image": "有圖片",
    "has images": "是否有圖片?",
    "has limit": "數量有限",
    "has limits": "有次數上限",
    "has limits per person": "有每人次數上限",
    "has no image": "無圖片",
    "has reply": "需要回覆",
    "has surcharge on free shipping": "免運費但有附加費用",
    "has times per order": "有每筆訂單次數上限",
    "has unset weight": "Has Unset weight",
    "has usage limit per person": "每人有使用次數上限",
    "has variants": "多規格商品",
    "hasPreOrder": "預購",
    "hasPreOrderCount": "預購: {count}",
    "hasSameValuesWithDatabase": "無變更",
    "hasStock": "現貨",
    "hasVariants": "多規格商品",
    "have not reissued e-invoices": "尚未重新開立電子發票",
    "having both linked consignments and orders in one document is not allowed": "同一份文件內無法同時有訂單與連結寄賣",
    "hct": "新竹物流",
    "hct contact": "新竹物流聯絡資料",
    "hct credentials": "新竹物流憑證",
    "hct extensions": "新竹物流進階設定",
    "hct summary": "新竹物流總表",
    "hct summary successfully submitted": "成功上傳新竹物流總表",
    "hct waybills": "新竹物托運單",
    "hct_region_error": "查無此區號，請檢查配送地址",
    "header": "標頭",
    "header-settings": "標頭與頁尾",
    "heartbeat": "心跳",
    "height": "高度",
    "help": "說明",
    "help editor": "Help Editor",
    "hidden": "隱藏",
    "hide": "隱藏",
    "hide add to cart button with a password": "開啟鎖定按鈕並隱藏加入購物車按鈕",
    "hide all": "全隱藏",
    "hide all customization prices": "隱藏所有客製價格",
    "hide all printed": "隱藏全已列印",
    "hide customization prices if 0": "隱藏0元客製化價格",
    "hide payouts details": "隱藏支出細節",
    "hide price when has password": "鎖定時隱藏商品價格",
    "hide printed": "隱藏已列印",
    "hide printed today": "隱藏今日已列印",
    "hide product code": "隱藏編碼",
    "hide products with missing data": "Hide products with missing data (negative product qty)",
    "hide products with no activity": "隱藏沒有活動的產品",
    "hide shipping fee": "隱藏運費",
    "hide shop description for all shops": "隱藏所有商店簡介",
    "hide tax number for all shops": "隱藏所有商店統編",
    "hide unit": "隱藏單位",
    "highest": "收取最高運費",
    "hold all": "全部暫停",
    "home": "首頁",
    "home fax": "住家傳真",
    "home page": "首頁",
    "home phone": "住家電話",
    "horizontal": "水平",
    "hours": "小時",
    "household appliances": "家電",
    "href": "連結",
    "html-custom-code": "Html代碼",
    "i have a referral code": "我有推薦代碼",
    "icon": "圖示",
    "if there are multiple payment types, waybills will be grouped by payment type": "系統會將7-11取貨付款、取貨不付款訂單分類好再列印托運單，列印順序與揀貨單、出貨單不同屬於正常情況。",
    "image": "圖片",
    "image alignment": "Image Alignment",
    "image alt": "圖片替代文字",
    "image caption": "圖片標題",
    "image link": "圖片連結",
    "image name": "圖片名稱",
    "image not found, maybe deleted": "圖片未找到，可能已刪除",
    "image settings": "圖片設定",
    "images": "圖片",
    "images hover brightness effect": "滑鼠移到圖片亮度調整",
    "images hover zoom effect": "滑鼠移到圖片縮放效果",
    "import": "匯入",
    "import data": "匯入資料",
    "import dispatched": "匯入已出貨",
    "import limited qtys": "匯入限制數量",
    "import paid": "匯入已付款",
    "import products": "匯入產品",
    "import to bulk update status and print": "匯入批次更新狀態並列印",
    "import_transporter_template": "下載匯入物流資料表格",
    "in pixel": " (單位: px)",
    "in sync": "已同步",
    "in use by": "使用中",
    "inactive": "無效的",
    "inactive variant children": "無效的產品變體子項",
    "inclTotal": "含稅總計",
    "include in seo shop locations": "加到 SEO 商店位置",
    "include pos": "Include Pos",
    "includeFreeShipping": "包含免運規則",
    "including tax discount": "含稅折扣",
    "inclusive amount": "含稅額",
    "inclusive discount": "含稅折扣",
    "inclusive discounted price": "含稅折扣價",
    "inclusive price": "含稅單價",
    "inclusive selling price": "含稅售價",
    "inclusive total": "含稅總計",
    "inclusive total after discount": "折扣後含稅總計",
    "inclusive total after returns": "折讓後含稅總計",
    "inclusive_total_short": "含稅總計",
    "income statement": "收入狀態",
    "incoming transactions summary": "傳入交易摘要",
    "incomplete pdf. please re-download": "PDF下載不完整，請重新下載",
    "incomplete telephone/address": "電話/地址不完整",
    "incorrect": "輸入錯誤",
    "incorrect array order": "輸入錯誤",
    "incorrect array order and/or incorrect number of items": "輸入錯誤",
    "incorrect byte": "輸入錯誤",
    "incorrect bytes": "輸入錯誤",
    "incorrect date": "輸入日期錯誤",
    "incorrect date format": "輸入日期格式錯誤",
    "incorrect date range": "輸入日期範圍不正確",
    "incorrect decimal places": "輸入錯誤，須為數值，小數位不正確",
    "incorrect length": "輸入行長度錯誤",
    "incorrect number": "輸入錯誤，須為數值",
    "incorrect number of items": "輸入錯誤",
    "incorrect uri scheme": "輸入錯誤，網址格式有誤",
    "increase": "增加",
    "increase/decrease limited qty": "+/- 限制數量",
    "increase/decrease preOrder qty": "+/- 預購數量",
    "index": "編號",
    "individual": "個人",
    "infinite": "無限",
    "influencers": "Influencers",
    "information": "資訊",
    "init": "啟動",
    "input value": "原始值",
    "insert": "插入",
    "insert documents ref here": "輸入參照編號",
    "insert or replace templates": "插入或更換內文",
    "insert text or drag content here, remove this text block if not needed": "插入文字或拖曳內容至此，若不需要文字方塊請移除",
    "inside image": "在圖片中",
    "insideImage": "在圖片中",
    "instagram url": "Instagram網址",
    "installment periods": "分期付款",
    "installments": "分期付款",
    "internal comments": "內部備註",
    "invalid": "錯誤",
    "invalid image type": "圖片格式錯誤",
    "invalid number of days": "無效的日數",
    "invalid number of months": "無效的月數",
    "invalid password": "密碼錯誤",
    "invalid rate": "無效費率",
    "invalid returns": "折讓單不正確",
    "invalid returns. returned qty greater than invoiced qty": "退貨數量大於出貨數量",
    "invalid status": "狀態錯誤",
    "invalid tax types. Provider does not allow mixed types": "不允許混合稅別",
    "invalid totals. totals for document - returns does not equal to issue total": "發票總額與退貨總額不符",
    "invalid two factor authentication code": "OTP認證失敗",
    "invalid username or password": "使用者名稱或密碼錯誤",
    "inventory": "庫存",
    "inventory activity": "庫存歷史",
    "inventory adjustment": "庫存調整",
    "inventory available": "可用庫存",
    "inventory book qty": "帳面庫存量",
    "inventory filters": "庫存篩選",
    "inventory in": "入庫",
    "inventory management": "庫存管理",
    "inventory notification": "存貨通知",
    "inventory on hand": "現有庫存",
    "inventory on hand qty": "現有庫存量",
    "inventory out": "出庫",
    "inventory qty": "庫存量",
    "inventory report": "列印庫存表",
    "inventory reports": "存貨報告",
    "inventory status report": "庫存狀態",
    "invoice": "發票",
    "invoice date": "從發票開立日期",
    "invoice description": "發票產品名稱",
    "invoice number": "發票號碼",
    "invoice numbers": "發票號碼",
    "invoiced": "已開立",
    "invoices": "統一發票",
    "ip address": "IP位址",
    "is associates": "合作夥伴",
    "is between": "之間",
    "is child campaign": "是子活動",
    "is custom response": "客戶自訂回應",
    "is finished": "已完成",
    "is master campaign": "是主活動",
    "is member": "已是會員",
    "is not allowed": "輸入錯誤",
    "is partner": "由大筆協助申請的合作夥伴",
    "is required": "必填",
    "is transporter": "物流公司",
    "is unlimited": "不限次數",
    "isBetween": "之間",
    "isComplete": "完成",
    "isIncomplete": "未完成",
    "isPinned": "已置頂",
    "isRecurringChild": "定期購",
    "isRecurringMaster": "定期購",
    "isSet": "組合商品",
    "isSetChild": "組合商品子項",
    "isSetWithChoices": "任選商品",
    "isVariantChild": "產品變體子項",
    "issue invoice": "開立統一發票",
    "issue invoices now": "立即開立電子發票",
    "item": "品項",
    "item key": "產品識別碼",
    "item name": "品名",
    "item quantity": "產品數量",
    "item value": "產品單價",
    "item was not invoiced": "未開發票商品",
    "itemName": "品項",
    "itemQty": "產品數量",
    "itemQuantity": "產品數量",
    "itemValue": "產品單價",
    "items": "品項",
    "items total price is not equal to the set price": "商品總價格不等於組合價格！",
    "jello": "Q彈",
    "job_position": "職稱",
    "join with": "連接",
    "kg": "公斤",
    "kgi Bank": "KGI Bank",
    "knowledge base": "知識庫",
    "label": "標籤",
    "label for transporter": "給物流公司的備註",
    "language is now set to": "現在設定的語言是",
    "large": "大",
    "last 1 month": "過去一個月",
    "last 1 year": "過去一年",
    "last 3 months": "過去三個月",
    "last 4 digits": "後四碼",
    "last 6 months": "過去六個月",
    "last button label": "送出按鈕標籤",
    "last month": "上個月",
    "last name": "姓氏",
    "last waybill number": "托運最後一號",
    "lastest delivery date": "選擇結帳頁最晚可送達（取貨）日期",
    "latest": "最新的",
    "latest customers": "最新的顧客",
    "latest dispatch date": "最晚需出貨日",
    "latest dispatch time": "當日最晚可出貨時間",
    "latest dispatch time description": "若下單時間超過當日最晚可出貨時間，結帳頁日期則自動往後加一天",
    "latest pickup time": "當日最晚可店取取貨時間",
    "latest pickup time description": "若下單時間超過當日最晚可店取取貨時間，結帳頁日期則自動往後加一天",
    "latest suppliers": "最新的供應商",
    "launch": "啟動",
    "lead time": "交貨時間",
    "ledger": "分類帳戶",
    "ledger account": "分類帳戶",
    "ledgerAccount": "分類帳戶",
    "ledgers": "分類帳戶",
    "ledgers->cash book": "分類帳戶 > 銀行帳戶",
    "ledgers->creditors": "分類帳戶 > 應付 (債權人)",
    "ledgers->debtors": "分類帳戶 > 應收 (債務人)",
    "left": "左側",
    "less than": "小於",
    "less than or equal to": "小於或等於",
    "lessThan": "小於",
    "lessThanOrEqualTo": "小於或等於",
    "level change": "會員等級更新",
    "liabilities": "負債",
    "light color": "亮色",
    "lightColor": "亮色",
    "limit by": "限制方式",
    "limit preOrder qty": "限制預購數量",
    "limit sales qty": "限制銷售量",
    "limited preOrder qty": "預購量限制",
    "limited qty": "限制數量",
    "limited qty per person": "每人限制數量",
    "limited sales qty": "限制銷售量",
    "line": "Line",
    "line chat": "Line聊天",
    "line count for blogs description": "文章描述顯示行數",
    "line count for blogs title": "文章標題顯示行數",
    "line id": "Line帳號",
    "line pay": "Line Pay",
    "line pay history": "Line Pay歷史",
    "line pay provider: tap pay": "Line Pay 提供者: Tap Pay",
    "line pay transactions": "Line Pay明細",
    "linePay": "Line Pay",
    "linePay_awaitingPayment": "等待 Line Pay 付款中",
    "link": "連結",
    "link description": "連結敘述",
    "link thumbnail image": "連結縮圖",
    "link thumbnail image ratio": "連結縮圖比例",
    "link title": "連結標題",
    "link to flow": "Link To Flow",
    "link_target": "目標視窗",
    "linked campaigns": "已連結活動",
    "linked documents have conflicts": "連結的文件有衝突!",
    "linked to": "連結到",
    "links": "連結",
    "list all": "全部列出",
    "list all abandoned": "列出所有被廢棄的",
    "loading": "載入中",
    "lock": "鎖定",
    "locked": "鎖住",
    "log into new company?": "您確定要登入 [ {company} ]? 會更新頁面",
    "logged out": "已登出",
    "login": "登入",
    "logo": "商標",
    "logo icon position": "Logo圖標位置",
    "logo menu position": "Logo與主選單位置",
    "logo mobile": "商標 (行動裝置)",
    "logo on top": "Logo在上",
    "logo position": "Logo 位置",
    "logo size": "Logo大小",
    "logo size desktop only": "Logo大小(電腦版限定)",
    "logoCenteredScrollMethod_hide": "完全隱藏",
    "logoCenteredScrollMethod_shrink": "縮小(只剩Logo圖標)",
    "logout": "登出",
    "lowest": "收取最低運費",
    "mailing list": "電子報",
    "mailing_groups": "名單",
    "main": "主要的",
    "main account number": "主要帳戶",
    "main island": "本島地區",
    "maintenance": "系統維護",
    "make sure that this is the intended qty": "請確保此為可提供的數量",
    "makeup and cosmetics": "美容彩妝",
    "male": "男",
    "manage duplicates": "合併",
    "manage rules": "管理規則",
    "margin": "毛利率",
    "mark as completed": "已完成",
    "mark as inactive": "設為無效",
    "mark as paid": "標記為已付款",
    "mark as resolved": "標記為已解決",
    "mark as unresolved": "標記為未解決",
    "master campaign": "主活動",
    "match_contacts_groups": "當",
    "match_contacts_groups_criteria": "條件",
    "match_free_shipping_any": "任何產品",
    "match_free_shipping_for": "當",
    "match_free_shipping_only": "只當購物車內有商品時",
    "max length": "最大長度",
    "max order price": "最高訂單金額",
    "max order qty": "最高重量",
    "max order weight": "最高重量",
    "max price": "最高價格",
    "max qty": "最大數量",
    "may contain duplicate data": "May Contain Duplicate Data",
    "mayAdd": "加價購",
    "medical supplies": "醫療相關用品",
    "medium": "中",
    "member": "會員",
    "member email": "會員電子信箱",
    "member id": "會員ID",
    "member level": "會員等級",
    "member level adjustment email": "會員等級調整信",
    "member level description": "會員等級敘述",
    "member level expiry date": "會員等級到期日",
    "member level expiry time": "會員等級到期時間",
    "member level name": "會員等級名稱",
    "member level point required": "所需點數",
    "member level points": "會員等級累積點數",
    "member level points will be set to member level minimum points": "會員等級點數將會設為該等級最低點數",
    "member level status": "狀態",
    "member levels": "會員等級",
    "members": "會員",
    "menu": "目錄",
    "menu expand alignment": "選單展開對齊",
    "menu expand behavior": "選單展開行為",
    "menu font size": "主選單字體大小",
    "menu position": "主選單位置",
    "menu style": "主選單格式",
    "merchant center settings": "廣告目錄",
    "merchantId": "Merchant ID",
    "merchantIv": "Merchant IV",
    "merchantKey": "Merchant Key",
    "merge": "合併",
    "merge_document": "併單",
    "merged": "已合併",
    "merged to": "已合併至",
    "merged with": "已合併",
    "merged_document": "已併單",
    "message": "訊息",
    "message to display when campaign has ended": "活動結束顯示訊息",
    "messages": "訊息",
    "method": "方法",
    "min amount required per period": "分期付款，每期最低總金額",
    "min length": "最小長度",
    "min order price": "最低訂單金額",
    "min order qty": "最低數量",
    "min order to allow shipping": "Min order to allow shipping: {amount}",
    "min order weight": "最低重量",
    "min price": "最低價格",
    "min qty": "最小數量",
    "minimize logo on scroll": "向下滾動時縮小Logo",
    "minimum cart amount": "折扣碼最低購買額度",
    "minimum spend requirement": "使用會員點數最低消費金額",
    "minutes": "分",
    "miscellaneous": "其他 (運費，手續費，等)",
    "missing data": "資料錯誤或缺失",
    "mm": "公釐",
    "mm1": "一",
    "mm10": "十",
    "mm11": "十一",
    "mm12": "十二",
    "mm2": "二",
    "mm3": "三",
    "mm4": "四",
    "mm5": "五",
    "mm6": "六",
    "mm7": "七",
    "mm8": "八",
    "mm9": "九",
    "mobile": "手機",
    "mobile logo size": "行動裝置Logo大小",
    "monday": "星期一",
    "monday opening hours": "星期一營業時間",
    "month": "月",
    "month end": "月底",
    "month1": "一月",
    "month10": "十月",
    "month11": "十一月",
    "month12": "十二月",
    "month2": "二月",
    "month3": "三月",
    "month4": "四月",
    "month5": "五月",
    "month6": "六月",
    "month7": "七月",
    "month8": "八月",
    "month9": "九月",
    "monthly": "每月",
    "monthly revenue": "每月營業額",
    "months": "月",
    "more": "更多",
    "moreThanFiveDays": "5+天",
    "multiple": "多次",
    "multiple delivery dates": "多次出貨日期",
    "multiple discount rate": "多重折扣率",
    "multiple discounts": "多次折扣",
    "multiple documents found. please capture manually": "找到多筆文件，請人工選取",
    "must be a bigger number": "輸入錯誤，必須是一個更大的數字",
    "must be a boolean": "輸入錯誤",
    "must be a buffer or a string": "輸入錯誤",
    "must be a credit card": "必須是信用卡號",
    "must be a float or double": "輸入錯誤，須為數值，可有小數點",
    "must be a negative number": "輸入錯誤，須為數值，必須是負數",
    "must be a number": "輸入錯誤，須為數值",
    "must be a positive number": "輸入錯誤，須為數值，必須是正數",
    "must be a smaller number": "輸入錯誤，必須是一個更小的數字",
    "must be a string": "輸入錯誤，須為字串",
    "must be a valid GUID": "必須是有效的GUID",
    "must be a valid ISO 8601 date": "必須是有效的ISO 8601日期",
    "must be a valid base64 string": "必須是有效的base64字串",
    "must be a valid date": "輸入日期格式錯誤",
    "must be a valid email": "輸入錯誤，電子信箱格式不正確",
    "must be a valid hostname": "必須是有效的主機名",
    "must be a valid ip address": "必須是有效的IP地址",
    "must be a valid port": "輸入錯誤，必須是有效的連接埠",
    "must be a valid relative uri": "輸入錯誤，必須是網址",
    "must be a valid taiwanese address": "必須是有效的台灣地址",
    "must be a valid timestamp or number of milliseconds": "輸入錯誤，時間格式須為毫秒數",
    "must be a valid timestamp or number of second": "輸入錯誤，時間格式須為秒數",
    "must be a valid uri": "輸入錯誤，必須是有效的網址",
    "must be an array": "輸入錯誤",
    "must be an integer": "輸入錯誤，須為數值，不可有小數點",
    "must be smaller than inclusive price": "必須小於含稅單價",
    "must be unicode normalized": "必須是unicode",
    "must dispatch today": "今日須出貨",
    "must exclude value": "輸入錯誤",
    "must not have leading or trailing whitespace": "前後不可有空格",
    "must only contain alpha-numeric and underscore characters": "輸入錯誤，只可包含字母、數字與底線",
    "must only contain alpha-numeric characters": "輸入錯誤，只可包含字母與數字",
    "must only contain hexadecimal characters": "必須只包含十六進制字串",
    "must only contain uppercase characters": "只能大寫字母",
    "mustAdd": "滿額禮",
    "my profile": "我的資料",
    "name": "名稱",
    "national credit card center of ROC": "National Credit Card Center of ROC",
    "nav container position": "輪播分頁位置",
    "nav dot": "輪播分頁指示點",
    "nav dots": "輪播分頁指示器",
    "nav item dot": "點狀",
    "nav item line": "線狀",
    "nav item style": "輪播分頁樣式",
    "nav next": "下一頁按鈕",
    "nav previous": "上一頁按鈕",
    "navItemDot": "點狀",
    "navItemLine": "線狀",
    "never expire": "無限期",
    "new": "新增",
    "new account": "新帳戶",
    "new bulk discount": "新增量販折扣",
    "new category page": "產品頁面",
    "new customer": "新增客戶",
    "new customer and supplier": "新增客戶及供應商",
    "new feature": "新功能",
    "new features": "新功能",
    "new link": "新連結",
    "new member level": "新會員等級",
    "new member level points": "新會員點數",
    "new orders": "新訂單",
    "new page": "新頁面",
    "new product": "新產品",
    "new products editor": "新產品編輯模式",
    "new sales invoice": "新增出貨單",
    "new sales order": "新增訂單",
    "new shipping zone": "新增運費規則",
    "new supplier": "新增供應商",
    "new template": "新增頁籤",
    "new total must be equal to the original document total": "新總價不等於原始總價",
    "new value": "調整後數量",
    "new window": "新視窗",
    "newCustomer": "新顧客",
    "new_contacts_groups": "新增群組",
    "neweb Pay": "Neweb Pay",
    "newestFirst": "上架日期由新到舊",
    "news": "最新消息",
    "news images ratio": "最新消息圖片長寬比例",
    "news settings": "最新消息設定",
    "news show breadcrumb": "顯示麵包屑導覽",
    "next": "下一頁",
    "next active": "下一個進行的活動",
    "next button label": "下一頁按鈕標籤",
    "next schedule": "下期排程",
    "next step": "下一步",
    "next step conversion rate": "下一步轉換率",
    "next workflow": "下個工作流程",
    "night": "晚上",
    "no": "否",
    "no active schedule": "時間表上沒有正在進行的活動",
    "no applicable shipping zones": "未選擇運費規則，顧客目前無法購買此商品",
    "no background colour": "無背景色",
    "no comments": "無留言",
    "no commission this month": "本月無抽成！",
    "no data": "無資料",
    "no data to export": "無匯出資料",
    "no details": "無詳細資料",
    "no dispatched data": "無已出貨資料",
    "no emails to send": "無可發送的電子郵件",
    "no master campaign found": "並未找到對應的主活動",
    "no member level changes": "會員等級尚未有任何變更!",
    "no next available number": "目前無可用的電子發票號碼",
    "no price history found": "未找到歷史",
    "no products added yet": "未選擇產品",
    "no products found, double click to refresh or add new products": "找不到產品，若已加入產品請點擊兩次更新畫面或加入新產品",
    "no rates": "免運費",
    "no reward expiry set": "未設到期時間",
    "no reward points set": "未設要發送的點數",
    "no shipping zones set in campaign": "未設置運費規則於商店，顧客目前無法購買此商品",
    "no tax allowed": "不允許有稅",
    "no transporter": "No transporter",
    "no transporter selected": "未選擇物流公司",
    "noItemInDatabase": "資料庫中未找到項目",
    "no_of_documents_unit": "筆",
    "no_of_times": "次",
    "nonMember": "非會員",
    "none": "無",
    "noon": "下午",
    "normal": "一般商品",
    "normal percentage": "一般折數百分比",
    "normal product": "一般商品",
    "not a day of the month (1-31)": "不正確的日數 (1-31)",
    "not a url": "網址格式錯誤",
    "not an email": "電子郵件格式錯誤",
    "not date": "須填日期",
    "not dispatched": "未出貨",
    "not editable": "不可編輯",
    "not email": "電子郵件格式錯誤",
    "not equals": "不等於",
    "not float": "請輸入數字",
    "not found": "查無此項",
    "not matching any of the allowed alternatives": "輸入錯誤",
    "not member": "非會員",
    "not number": "須為數字",
    "not unique": "資料重複",
    "not yet addded to any shops": "未上架至商店",
    "not yet dispatched": "未出貨",
    "not yet generated": "未產生",
    "not yet invoiced": "未開立",
    "not yet saved": "未儲存",
    "not yet set": "未設定",
    "notEquals": "不等於",
    "note": "備註",
    "note is required on checkout": "顧客注意事項為必填",
    "notes": "備註",
    "notification": "通知",
    "notifications": "通知",
    "notifications_read": "已讀",
    "notifications_unread": "未讀（新增）",
    "number": "號碼",
    "number of days": "日數",
    "number of days after dispatch date": "發貨後的天數",
    "number of games per person": "每人遊戲數量",
    "number of lines for product name": "品名顯示行數",
    "number of months": "月數",
    "number of orders": "訂單數量",
    "number of orders (after split)": "訂單數量(拆單後)",
    "number of orders (before split)": "訂單數量(拆單前)",
    "number of results": "搜尋數量",
    "number of selections": "選擇的數量",
    "number of transactions": "交易數量",
    "off": "關",
    "offer id": "Offer_ID",
    "office fax": "辦公室傳真",
    "office phone": "公司電話",
    "offline": "離線中",
    "old exclusive price": "舊的未稅價格",
    "old inclusive price": "舊的含稅價格",
    "old member level points": "舊會員點數",
    "old store id": "商店ID",
    "old store name": "商店名",
    "on": "開",
    "on checkout delivery date selection": "結帳頁顯示到(出)貨日期選擇",
    "on checkout dispatch date selection": "結帳頁出貨日期選擇",
    "on checkout pickup date selection": "結帳頁顯示店取日期選擇",
    "on consignment qty": "寄賣數量",
    "on hand qty": "現有庫存量",
    "on hold": "暫停",
    "on hold qty (checkout in progress)": "保留數量 (還在結帳流程)",
    "on sale": "特價中",
    "on sale schedules": "特價時間表",
    "on sale tag display position": "特價中標籤顯示位置",
    "on sale tag display style": "特價中標籤顯示樣式",
    "on set date": "在設定日期",
    "on total": "總額計算",
    "onHold": "暫停",
    "onSaleScheduleWarning_notOnSale": "若您尚未選取排程，此商品將會繼續顯示【原始價格】",
    "onSaleScheduleWarning_onSale": "若您尚未選取排程，此商品將會繼續顯示【原折扣價格】",
    "on_consignment_exclusive_total": "寄賣未稅總額",
    "on_consignment_inclusive_total": "寄賣含稅總額",
    "once": "一次",
    "once off": "一次性",
    "one item limit per checkout": "每次結帳限制一件商品",
    "oneByOne": "1x1 (正方形)",
    "oneToThreeDays": "1-3天",
    "online": "上線中",
    "only allow recurring schedules in shop": "只顯示定期購選項（不可單買）",
    "only categories": "只顯示產品分類",
    "only for B2C": "僅支援B2C使用",
    "only for mobile preview and slider display": "僅影響輪播和手機瀏覽的文章總覽",
    "only include items with sales activity": "隱藏無交易產品",
    "only one bulk discount allowed with all product tags": "僅能有一個包含全部產品標籤的量販折扣",
    "only send when customer won prizes": "僅在顧客贏得獎勵時寄送",
    "only show set details (hide parents)": "只顯示組合子項 (隱藏組合母項)",
    "only show set parents (hide details)": "只顯示組合母項 (隱藏組合子項)",
    "only show shipping description, rate is quoted after order": "只顯示配送資訊，運費會在下單後報價",
    "only show variant parents": "只顯示主附加選項",
    "only show variant parents in products search": "在產品分頁搜尋只顯示主附加選項",
    "only tags": "只顯示產品標籤",
    "open graph": "Open Graph",
    "open help": "開啟說明",
    "open hours": "營業時間",
    "open link": "開啟連結",
    "open shop settings": "開商店設定分頁",
    "open_tab": "開新分頁",
    "open_window": "開啟新視窗",
    "opening balance": "期初盈餘",
    "opening inventory book qty": "現有庫存量",
    "opening inventory book value": "現有庫存價值",
    "opening inventory on hand qty": "帳面庫存量",
    "opening inventory on hand value": "帳面庫存價值",
    "opening points": "初始點數",
    "operator": "操作人員",
    "operator->change logs": "操作人員 > 更新紀錄",
    "optimized-image": "圖片",
    "optional": "非必填",
    "optional_will_use_default": "非必填，將使用系統預設值",
    "options": "選項",
    "orange": "橙色",
    "order": "訂單",
    "order #": "訂單 #",
    "order activity showing options": "訂單交易歷史出貨日期顯示設定",
    "order canceled email": "已取消通知信",
    "order date": "訂購日期",
    "order dispatched email": "訂單出貨通知信",
    "order number": "訂單號碼",
    "order percentage": "訂單占比",
    "order requirements": "訂單要求設定",
    "order success email": "訂單成功信",
    "order summary": "訂單明細",
    "order value": "訂單總計",
    "order-activity-settings": "訂單交易歷史設定",
    "orderValue": "訂單總計",
    "ordered": "訂購",
    "ordered date": "訂購日期",
    "ordered qty": "訂購數量",
    "ordered_exclusive_total": "訂購未稅額",
    "ordered_inclusive_total": "訂購含稅額",
    "orders": "訂單",
    "orders by date": "依訂購日期",
    "original commission base cost": "原始佣金成本基數",
    "original commission total amount": "原始佣金總數",
    "original document total": "原始總價",
    "original inclusive price": "含稅原價",
    "original total commission": "原始總佣金",
    "original value": "原始數量",
    "other": "其他",
    "other income": "其他收入",
    "out of stock": "缺貨中",
    "outOfStock": "缺貨中",
    "outer region": "偏遠地區",
    "outgoing transactions summary": "送出交易摘要",
    "outlying islands": "外島地區",
    "output value": "自訂值",
    "outside image": "在圖片外",
    "outsideImage": "在圖片外",
    "outstanding": "未出貨",
    "outstanding qty": "未出貨數量",
    "outstanding total": "未付總金額",
    "outstanding_exclusive_total": "未交未稅額",
    "outstanding_inclusive_total": "未交含稅額",
    "over limit": "已超過限制",
    "overdue commission": "逾期抽成",
    "overlap date description": "排程重疊日期",
    "overpaid": "超額付款",
    "overview": "總覽",
    "package size": "黑貓包裹尺寸",
    "package temperature": "包裹溫度",
    "package type": "黑貓預設產品類型",
    "packageSize": "黑貓包裹尺寸",
    "packageType": "黑貓預設產品類型",
    "packages": "件數",
    "packages_short": "件數",
    "packaging_dimensions": "包裝尺寸",
    "packaging_dimensions_unit_default": "預設包裝尺寸單位",
    "page": "頁",
    "page is hidden on menu": "在主選單隱藏",
    "page schedules": "頁面排程",
    "page status": "頁面狀態",
    "page url": "頁面網址",
    "page view": "瀏覽頁面",
    "page views": "瀏覽量",
    "page views per person": "每人平均瀏覽量",
    "pageBlocks_bio": "簡介",
    "pageBlocks_buttons": "按鈕",
    "pageBlocks_facebookPosts": "Facebook 貼文",
    "pageBlocks_flow": "聊天機器人",
    "pageBlocks_group_audience": "受眾",
    "pageBlocks_group_basic": "基本",
    "pageBlocks_group_money": "金錢",
    "pageBlocks_group_social": "社群",
    "pageBlocks_images": "圖片",
    "pageBlocks_instagramPosts": "Instagram 貼文",
    "pageBlocks_mailingList": "郵件名單",
    "pageBlocks_products": "商品",
    "pageBlocks_socialLinks": "社交連結",
    "pageBlocks_text": "文字",
    "pageBlocks_threadsPosts": "Threads 貼文",
    "pageBlocks_tikTokPosts": "TikTok 貼文",
    "pageBlocks_tips": "小費/贊助",
    "pageBlocks_youtubeVideo": "YouTube 影片",
    "paid": "已付款",
    "paid total": "已付款總額",
    "parent page": "顯示在分類頁面下",
    "passed allowed refund period": "已超過可退刷日期",
    "password": "密碼",
    "password (at least 4 characters)": "密碼（至少4個字符）",
    "password (at least 8 characters)": "密碼（至少8個字符）",
    "password button label": "鎖定按鈕標籤",
    "password description": "解除鎖定說明",
    "paste as plain text": "貼上純文字",
    "pay": "付款",
    "pay to account auto expiry": "銀行轉帳時效自動設定",
    "payers phone number": "付款人電話",
    "payment": "付款",
    "payment amount": "付款金額",
    "payment date": "付款日期",
    "payment due": "付款截止日",
    "payment due date": "付款截止日",
    "payment last 5 digits": "後五碼",
    "payment link": "付款連結",
    "payment method": "付款方式",
    "payment methods": "付款方式",
    "payment number": "payment number",
    "payment overdue date": "付款逾期日",
    "payment reminder email": "付款提醒信",
    "payment reminder email message": "付款提醒電子郵件內容",
    "payment reminder email subject": "付款提醒電子郵件主旨",
    "payment reminder email templates": "付款提醒郵件模板",
    "payment reminders": "付款提醒",
    "payment settings": "付款設定",
    "payment status": "付款狀態",
    "payment terms": "付款條件",
    "payment-flow": "金流",
    "payment-settings": "付款設定",
    "paymentAndCheckout": "付款與結帳",
    "payments": "付款",
    "payments and due date": "付款與期限",
    "payments->credit card": "付款 > 信用卡",
    "payments->line pay": "付款 > Line Pay",
    "payments->virtual account": "付款 > 虛擬帳號",
    "payout": "撥款",
    "payouts this month": "本月支出",
    "payuni": "PayUni",
    "payuni-seven-eleven": "PayUni 7-11",
    "payuni-seven-eleven credentials": "PayUni 7-11 Credentials",
    "payuni-seven-eleven waybill": "Payuni 7-11 托運單",
    "payuni_hash_iv": "Hash IV",
    "payuni_hash_key": "Hash Key",
    "payuni_import from contact": "Import from contact",
    "payuni_merchant_id": "Merchant ID",
    "payuni_payments": "PayUni Payments",
    "pending": "未完成",
    "pending points": "待定點數",
    "pending products": "掛單",
    "pending qty": "未出庫存",
    "pendingCancellation": "取消處理中",
    "per line": "個別計算",
    "percentage of price": "價格的百分比",
    "permanently unavailable variant children": "已刪除的多規格子商品",
    "person": "聯絡人",
    "person + contact": "收件人 + 訂購人",
    "person name": "聯絡人姓名",
    "person_export": "收件人",
    "persons": "聯絡人",
    "persons_count": "不重複訪客數",
    "pet supplies": "寵物用品飼料",
    "phone": "電話",
    "pick a color theme": "選擇一個顏色主題",
    "pick up": "自取",
    "pick up locations": "取貨點地址",
    "picking list": "檢貨單",
    "pickup address": "取貨地址",
    "pickup date options": "取貨日期選項",
    "pickup date settings": "指定店取日期設定",
    "pickup date subtitle": "選擇取貨日副標題(顯示於結帳頁)",
    "pickup locations": "取貨點地址",
    "pickup methods": "取貨方式",
    "pickup settings": "自取設定",
    "pickup settings instruction": "自取設定預設會套用至所有活動頁面，請前往 商店 > 活動 檢查個別商店設定",
    "pickup-settings": "自取設定",
    "pickupAddress": "取貨地址",
    "picture-desktop": "圖片（桌電）",
    "picture-tablet": "圖片（平板）",
    "pin": "置頂",
    "pinned": "已置頂",
    "pixel id": "像素(Pixel) ID",
    "plan": "方案",
    "please allow popups to open": "請允許彈出視窗",
    "please check email for two factor authentication code": "請至登入信箱查收BigOrdr發送之信件，並取得驗證碼。",
    "please press print again to see changes": "請重按列印鍵變更",
    "please select": "請選擇",
    "podKey": "識別碼",
    "point": "點數",
    "points": "{points} 點數",
    "points adjustment email": "會員點數調整通知信",
    "points become valid after number of days": "點數生效日",
    "points earned": "獲得的會員點數",
    "points expiry date": "會員點數到期日",
    "points expiry time": "會員點數有效期限",
    "points spent": "會員點數",
    "points to adjust": "調整的會員點數",
    "points to adjust expiry date": "調整到期日的點數",
    "points to send": "要發送的點數",
    "pos": "POS",
    "pos profile": "POS設定檔",
    "pos profile not found": "找不到POS設定檔",
    "pos terminal": "POS終端",
    "position": "位置",
    "position_center-bottom": "下方置中",
    "position_center-center": "正中",
    "position_center-top": "上方置中",
    "position_left-bottom": "左下角",
    "position_left-top": "左上角",
    "position_right-bottom": "右下角",
    "position_right-top": "右上角",
    "possibly missing data": "可能有資料遺失",
    "post office": "郵局",
    "postCode": "郵遞區號",
    "postal codes": "郵遞區號",
    "power": "權力用戶",
    "pre order": "預購中",
    "preOrder": "預購中",
    "preOrder stats": "預購統計",
    "preOrder-notes": "預購產品名備註",
    "preferred delivery time": "偏好的送達時段",
    "preferred supplier": "首選供應商",
    "preferred supplier fulfillment method": "供應商配送方式",
    "prepare for dispatch": "準備出貨",
    "preparing": "準備中",
    "preset commissions": "預設抽成",
    "presets": "預設",
    "press": "按",
    "press enter or search button to search": "按下 Enter 或搜尋鍵來搜尋",
    "press enter to select, and search button to search": "按下 Enter 選擇，按搜尋鍵來搜尋",
    "preview": "預覽",
    "preview merge": "預覽合併",
    "previous": "上一頁",
    "previous button label": "上一頁按鈕標籤",
    "previous step": "上一步",
    "price": "單價",
    "price (for accounting purpose)": "價格（作帳用）",
    "price history": "價格歷史",
    "price range": "價格範圍",
    "priceAsc": "價格由低至高",
    "priceDesc": "價格由高至低",
    "priceLessThanPriceDiscounted": "含稅折扣價: 必須小於含稅單價",
    "pricing": "定價",
    "print": "列印",
    "print all": "列印全部",
    "print all delivery notes": "列印全部出貨單",
    "print all eInvoices": "開立全部電子發票",
    "print all invoices": "列印全部統一發票",
    "print all waybills": "列印全部托運單",
    "print delivery note": "列印出貨單",
    "print hct summary": "列印新竹物流總表",
    "print images": "列印產品圖片",
    "print images on documents": "列印產品圖片",
    "print invoice": "列印統一發票",
    "print invoices": "列印統一發票",
    "print picking list": "列印檢貨單",
    "print statement": "列印對帳表",
    "print waybill": "列印托運單",
    "print waybills starting position": "開始列印位置",
    "print_delivery_note_prices": "列印請款單",
    "printed": "已列印",
    "printed products": "印刷品",
    "printer": "Printer",
    "printer error, please check printer connection": "列印錯誤",
    "printer id": "打印機 ID",
    "printerAllocatedNumbers": "印表機指派的號碼",
    "private": "非公開",
    "private notes": "非公開訊息",
    "private templates": "我的模板",
    "prize": "獎品",
    "prize description": "獎品描述",
    "prize_successfulMessage": "中獎成功訊息",
    "prize_successfulTitle": "中獎成功標題",
    "probably missing data": "Probably missing data!",
    "process_order": "排序",
    "processing": "處理中",
    "processing please wait": "處理中請稍候",
    "product": "商品",
    "product code": "編碼",
    "product decimal places": "產品小數點位數",
    "product display": "產品顯示",
    "product grouping": "產品分類",
    "product image ratio": "產品長寬比例",
    "product images": "產品圖片",
    "product images size": "產品圖片大小",
    "product info": "產品資料",
    "product information": "產品介紹",
    "product limits": "產品限制數量",
    "product name": "產品名稱",
    "product price rounding": "產品價格小數點位數",
    "product schedules": "產品上架時間表",
    "product set": "組合商品",
    "product set with choices": "任選商品",
    "product sets": "組合商品",
    "product settings": "產品設定",
    "product size": "產品尺寸",
    "product stats": "產品數據統計",
    "product status": "商品狀態",
    "product tags": "產品標籤",
    "product type": "商品類型",
    "product url": "產品 Url 顯示",
    "product_description": "產品規格",
    "product_description_short": "產品規格",
    "product_general": "一般商品",
    "product_set": "組合商品",
    "product_set_price": "組合價格",
    "product_sets": "組合商品",
    "product_sets_contents": "組合內容",
    "product_sets_with_choices": "任選商品",
    "product_sets_with_choices_contents": "任選商品內容",
    "product_short description": "商品摘要",
    "product_sync": "單次重整產品",
    "product_sync_warning": "更新此頁面內容不會自動同步至商店，儲存後請至【商店】點擊編輯產品，按下【單次重整產品】",
    "product_tags_any": "含",
    "product_tags_only": "僅含",
    "product_type_inventory": "實體商品",
    "product_type_service": "服務",
    "product_type_virtual": "虛擬",
    "products": "商品",
    "products images hover brightness effect": "滑鼠移到產品圖片亮度調整",
    "products images hover zoom effect": "滑鼠移到產品圖片縮放效果",
    "products images ratio": "產品圖片長寬比例",
    "products images size": "產品圖片尺寸",
    "products in this shop": "本店的產品",
    "products overview name": "商店預覽名稱",
    "products overview style": "商店預覽風格",
    "products sort by": "產品排序",
    "products to be converted to normal product": "轉換為一般商品的品項",
    "products to be converted to variant child product": "轉換為多規格子商品的品項",
    "profile": "簡介",
    "promo code": "優惠代碼",
    "promo code (at least 6 characters)": "優惠代碼(至少6個字符)",
    "promo codes": "優惠代碼",
    "promotion": "促銷",
    "promotion email": "促銷信件",
    "provider": "平台",
    "public": "公開",
    "public description": "自訂優惠代碼訊息",
    "public release notes": "公開宣布訊息",
    "public status": "資料公開狀態（客戶可看見）",
    "public templates": "預設模板",
    "pulse": "脈動",
    "purchases": "進貨",
    "purchases consignment": "進項寄賣",
    "purchases consignment overview": "進項寄賣槪要",
    "purchases consignment receipt": "寄賣進貨單",
    "purchases consignment return": "進項寄賣退貨單",
    "purchases enquiry": "詢價單",
    "purchases invoice": "進貨單",
    "purchases invoices this month": "本月進貨單",
    "purchases order": "採購單",
    "purchases price history": "進價歷史",
    "purchases return": "進貨折讓單",
    "purchases returns this month": "本月進貨折讓單",
    "purchases terms": "進貨條件",
    "purchases->consignments": "進貨 > 寄賣",
    "purchases->consignments->purchases-consignments": "進貨 > 寄賣 > 寄賣進貨單",
    "purchases->consignments->purchases-consignments-returns": "進貨 > 寄賣 > 進項寄賣退貨單",
    "purchases->doc-pending": "進貨 > 未交總表",
    "purchases->purchases-invoices": "進貨 > 進貨單",
    "purchases->purchases-orders": "進貨 > 採購單",
    "purchases->purchases-quotes": "進貨 > 詢價單",
    "purchases->purchases-returns": "進貨 > 進貨折讓單",
    "purchases-consignments": "寄賣進貨單",
    "purchases-consignments-returns": "進項寄賣退貨單",
    "purchases-invoices": "進貨單",
    "purchases-orders": "採購單",
    "purchases-pending": "未交總表",
    "purchases-pending: display tax method": "進貨未交總表: 顯示未稅或含稅",
    "purchases-purchases-consignments": "進貨: 寄賣進貨單",
    "purchases-purchases-consignments-returns": "進貨: 進項寄賣退貨單",
    "purchases-purchases-invoices": "進貨: 進貨單",
    "purchases-purchases-orders": "進貨: 採購單",
    "purchases-purchases-quotes": "進貨: 詢價單",
    "purchases-purchases-returns": "進貨: 進貨折讓單",
    "purchases-quotes": "詢價單",
    "purchases-returns": "進貨折讓單",
    "purchases: on consignment": "購買：寄賣",
    "purchases: on consignment qty": "購買：寄賣量",
    "purchases_delivered_exclusive_total": "已進貨未稅額",
    "purchases_delivered_inclusive_total": "已進貨含稅額",
    "purchasing exclusive price": "進貨未稅價",
    "purchasing inclusive price": "進貨含稅價",
    "purple": "紫色",
    "qr code": "QR Code",
    "qty": "數量",
    "qty cannot be empty or have value below one": "數量不可為空或是小於一!",
    "qty cannot be greater than the allowed qty": "數量無法大於允許數量!",
    "qty sold": "售出數量",
    "qty sold (to invoice)": "已售數量(未紀錄)",
    "quantity": "數量",
    "quantity based rates": "以數量設定運費",
    "query": "查詢",
    "question": "問題",
    "questions": "問題",
    "queued": "排程中",
    "quick add": "快速增加",
    "quick add products to shop": "快速新增產品到商店",
    "quick login": "已經是會員，請快速登錄",
    "quickTip": "小提示",
    "quote": "報價單",
    "rate": "費率",
    "rate table": "運費區間",
    "ratio_original": "原始",
    "re-download already generated waybills": "重新下載已經產生的運單",
    "re-download waybills": "重新下載運單",
    "re-index": "重新排序",
    "re-index all products": "重新排序所有產品",
    "re-login": "重新登入",
    "re-order qty": "再訂購數量",
    "re-sync product stats": "重新同步產品數據",
    "real qty sold": "實際銷售數量",
    "reason": "理由",
    "receipt": "收款",
    "receipts": "收款",
    "received qty": "已收數量",
    "received_exclusive_total": "已收未稅總額",
    "received_inclusive_total": "已收含稅總額",
    "receivers mobile number": "收件人手機",
    "recipient": "收件人",
    "recommended": "推薦排序",
    "record commissions": "紀錄抽成",
    "recurring order email": "定期購信件",
    "recurring orders": "定期購訂單",
    "recurring schedules": "定期購",
    "recurring settings": "定期購設定",
    "recurring-settings": "定期購設定",
    "recurring_discount_behavior": "定期購折扣",
    "recurring_discount_paymentReminder": "定期購付款提醒",
    "recurring_only": "不可單買",
    "recurring_options": "選項",
    "recurring_shipping_behavior": "定期購運費",
    "red": "紅色",
    "ref": "參照編號",
    "reference": "參照編號",
    "referenced field is not a date": "輸入日期格式錯誤",
    "referenced field is not a number": "輸入錯誤，須為數值",
    "referral email": "推薦信",
    "referral_reward": "推薦獎勵",
    "referral_reward_details": "詳細推薦獎勵",
    "referral_reward_limit per day": "每日推薦上限",
    "referral_reward_recipient": "被推薦人",
    "referral_reward_referrer": "推薦人",
    "refetch barcode": "重新下載條碼",
    "refresh": "重整",
    "refrigerated": "冷藏",
    "refund": "退款",
    "refund amount": "退款金額",
    "refund credit card": "信用卡退刷",
    "refund credit card total": "信用卡退刷總計",
    "refund line pay": "Line Pay退款",
    "refund payment": "退還已付款項",
    "refund reminder amount": "退款提醒金額",
    "refunds": "退款",
    "refunds require authorization": "退款需要授權",
    "refunds settings": "退款設定",
    "regenerate access code": "重新產生報表連結",
    "regenerate document number": "重新產生文件編號",
    "region": "地區",
    "region code": "地區代碼",
    "regions": "地區",
    "related": "相關的",
    "relationship": "關係",
    "relationships": "關係",
    "reload inventory reports": "重載存貨報告",
    "remember credit card": "記住信用卡",
    "reminder limits qty": "提醒數量(當產品低於數量時，提醒我)",
    "remove": "刪除",
    "remove all row": "刪除橫列",
    "remove bulk discount": "移除量販折扣",
    "remove cell": "刪除儲存格",
    "remove item from page": "自頁面移除項目",
    "remove product from shop": "下架商品",
    "remove selected products": "下架已選擇的商品",
    "repeat order info for each detail row": "每一列重複顯示訂單資訊",
    "replace": "更換",
    "report": "報表",
    "report link": "報表連結",
    "report password": "報表密碼",
    "reports": "報表",
    "reports->commissions": "報表 > 抽成",
    "reports->sales": "報表 > 銷貨",
    "request missing waybills": "request missing waybills",
    "request waybills": "產生托運單",
    "requestId": "請求ID (Request ID)",
    "requested a todo from": "請求待辦事項：",
    "required": "必填",
    "resend": "重寄",
    "resend order webhook event": "Webhook: 重新傳送訂單資料",
    "reset commission presets": "重設抽成",
    "reset inventory data": "Reset Inventory Data",
    "reset inventory date": "Reset Inventory Date",
    "resolved": "已解決",
    "resource": "資源",
    "restrict login using ip addresses": "限制登入IP位址",
    "results": "搜尋結果",
    "return": "退貨",
    "return all discount": "退還全部折扣",
    "return convenience store": "退件包裹送達門市",
    "return points spent": "退還該筆訂單使用的點數",
    "return qty": "退貨數量",
    "return storeAddress": "返回店家地址",
    "return storeId": "返回商店 ID",
    "return storeName": "返回店家名稱",
    "return_points_adjustment_explanation": "因為當次消費賺取的會員點數在其他次消費中被使用，退款總額中將會依使用情形調整。",
    "returningCustomer": "回購客/舊客",
    "returns": "退貨",
    "returns application email": "退貨申請信",
    "returns inclusive total": "折讓含稅總計",
    "revenue": "營業額",
    "revenue by age": "顧客年齡營業額",
    "revenue by campaign": "商店營業額",
    "revenue by channel": "通路營業額",
    "revenue by contact": "顧客營業額",
    "revenue by contact tags": "顧客標籤營業額",
    "revenue by customer type": "顧客類別營業額",
    "revenue by day": "每日營業額",
    "revenue by gender": "顧客性別營業額",
    "revenue by member type": "會員營業額",
    "revenue by month": "每月營業額",
    "revenue by payment method": "付款方式營業額",
    "revenue by product": "產品營業額",
    "revenue by promo code": "優惠代碼營業額",
    "revenue for campaign": "商店營業額",
    "revenue for month": "本月營業額",
    "revenue for year": "本年營業額",
    "revenue percentage": "營業額占比",
    "revert to draft": "還原成草稿",
    "review steps": "審查步驟",
    "reviewSettings": "審查設定",
    "reward": "獎勵",
    "reward earning rate from total order": "會員點數為訂單總價 {rate}％",
    "reward event": "獎勵類型",
    "reward event allow on sale": "允許特價",
    "reward event allow to earn points": "允許獲得購物點數",
    "reward event allow with promo code": "允許使用折扣碼",
    "reward event earn as promo code": "賺取優惠代碼",
    "reward event earning": "購物點數（買多少送多少點數）",
    "reward event expiry": "到期時間",
    "reward event max deduction": "每筆最高折抵金額",
    "reward event rate per Point": "每點點數兌換率（每點點數換多少購物金）",
    "reward event requirement": "獎勵最低購物金額（買多少才有購物點數）",
    "reward event start": "起始時間",
    "reward event status": "狀態",
    "reward event type": "獎勵類型",
    "reward for specific product tags": "特定商品標籤的獎勵",
    "reward history": "獎勵歷史紀錄",
    "reward name": "獎勵名稱",
    "reward notification email": "獎勵通知信",
    "reward points": "會員點數",
    "rewardInclTotal": "含稅購物金",
    "reward_adjustment": "調整",
    "reward_adjustment_points": "調整點數",
    "reward_birthday": "生日",
    "reward_birthday_points": "生日點數",
    "reward_current_points": "現有點數",
    "reward_earned_points": "會員點數",
    "reward_expire_soon": "即將到期",
    "reward_memberLevelAdjustment": "會員等級調整",
    "reward_no_data": "此期間內並無獎勵歷史紀錄",
    "reward_opening": "期初點數",
    "reward_opening_points": "初始點數",
    "reward_order": "訂單",
    "reward_pointsAdjustment": "會員點數調整",
    "reward_promotion": "促銷",
    "reward_promotion_points": "促銷點數",
    "reward_referral": "推薦",
    "reward_referral_points": "轉介點數",
    "reward_return": "返還",
    "reward_returns": "退貨",
    "reward_signup": "註冊",
    "reward_signup_points": "註冊點數",
    "reward_spent_points": "使用點數",
    "rewards": "獎勵",
    "rewards inclusive total": "含稅購物金",
    "ribbon": "斜角",
    "rid": "RID",
    "right": "右側",
    "role": "角色",
    "rotate": "旋轉",
    "rotateIn": "旋入",
    "row": "一般區塊",
    "rss feeds": "RSS 訂閱",
    "rss_description": "開啟Facebook 和 Google RSS訂閱",
    "rss_feed_name": "RSS 消費者名稱",
    "rss_feed_url": "RSS 網址",
    "rubberBand": "橡皮筋",
    "rules_notes": "你可以在這裡記錄內部規則或SOP，讓所有團隊的人做事可以有ㄧ致性以提高效率。",
    "sales": "銷貨",
    "sales consignment": "銷項寄賣",
    "sales consignment delivery": "寄賣出貨單",
    "sales consignment overview": "銷項寄賣槪要",
    "sales consignment return": "銷項寄賣退貨單",
    "sales invoice": "出貨單",
    "sales order": "銷貨訂單",
    "sales person": "業務人員",
    "sales persons": "業務人員",
    "sales price history": "售價歷史",
    "sales quote": "報價單",
    "sales report": "銷售報表",
    "sales return": "銷貨折讓單",
    "sales terms": "銷貨條件",
    "sales->consignments": "銷貨 > 寄賣",
    "sales->consignments->sales-consignments": "銷貨 > 寄賣 > 寄賣出貨單",
    "sales->consignments->sales-consignments-returns": "銷貨 > 寄賣 > 銷項寄賣退貨單",
    "sales->doc-pending": "銷貨 > 未交總表",
    "sales->sales-invoices": "銷貨 > 出貨單",
    "sales->sales-orders": "銷貨 > 銷貨訂單",
    "sales->sales-quotes": "銷貨 > 進貨： 報價單",
    "sales->sales-returns": "銷貨 > 銷貨折讓單",
    "sales-consignments": "寄賣出貨單",
    "sales-consignments-returns": "銷項寄賣退貨單",
    "sales-invoices": "出貨單",
    "sales-orders": "銷貨訂單",
    "sales-pending": "銷貨: 未交總表",
    "sales-pending: display tax method": "銷貨未交總表: 顯示未稅或含稅",
    "sales-quotes": "報價單",
    "sales-returns": "銷貨折讓單",
    "sales-sales-consignments": "銷售: 寄賣出貨單",
    "sales-sales-consignments-returns": "銷售: 銷項寄賣退貨單",
    "sales-sales-invoices": "銷售: 出貨單",
    "sales-sales-orders": "銷售: 銷貨訂單",
    "sales-sales-quotes": "銷售: 報價單",
    "sales-sales-returns": "銷售: 銷貨折讓單",
    "sales: on consignment": "銷售：寄賣",
    "sales: on consignment qty": "銷售：寄賣量",
    "sales_delivered_exclusive_total": "已出貨未稅額",
    "sales_delivered_inclusive_total": "已出貨含稅額",
    "same as date end": "同結束日期",
    "same as drop off": "與包裹寄件門市相同",
    "same line": "不分行",
    "saturday": "星期六",
    "saturday opening hours": "星期六營業時間",
    "save": "儲存",
    "save and go next": "儲存並下一步",
    "save and update next invoice number": "儲存更新下一個發票號碼",
    "save as color template": "存為顏色模板",
    "save as copy": "另存為副本",
    "save as draft": "儲存成草稿",
    "save as template": "儲存為模板",
    "save as void": "儲存成作廢",
    "save this search": "儲存搜尋",
    "saved": "已儲存",
    "schedule": "排程",
    "schedule status": "活動狀態",
    "scheduled": "排程",
    "scheduled payment": "排程付款",
    "scheduled_tasks": "預定任務",
    "scheduled_time": "排程時間",
    "schedules": "排程",
    "scratch card background": "刮刮樂(底層)",
    "scratch card background background color": "刮刮樂底層背景顏色",
    "scratch card background image": "刮刮樂底層圖片",
    "scratch card background text": "刮刮樂底層文字",
    "scratch card background text color": "刮刮樂底層文字顏色",
    "scratch card cover": "刮刮樂(封面)",
    "scratch card cover background color": "刮刮樂封面背景顏色",
    "scratch card cover image": "刮刮樂封面圖片",
    "scratch card cover text": "刮刮樂封面文字",
    "scratch card cover text color": "刮刮樂封面文字顏色",
    "scratch card image size": "刮刮樂圖片尺寸",
    "scratch card image size warning text": "如果圖片尺寸設定比例與圖片不一致，可能會導致圖片變形",
    "scratch card settings": "刮刮樂設定",
    "scratch card type": "類別",
    "scrolling-notification": "橫幅滾動通知",
    "seafood / meat": "水產/肉品",
    "search": "搜尋",
    "search bulk discounts": "搜尋量販折扣",
    "search filters": "搜尋篩選",
    "search flows": "Search Flows",
    "search images": "搜尋照片",
    "search products": "搜尋產品",
    "search reference": "搜尋參照編號",
    "search results": "搜尋結果",
    "search reward name": "搜尋獎勵名稱",
    "search shipping zones": "搜尋運費規則",
    "select": "選擇",
    "select all": "全選",
    "select all main island": "選取所有本島地區",
    "select all outlying": "選取所有離島地區",
    "select animation": "選擇動畫",
    "select company": "選擇公司",
    "select dispatch date": "選擇出貨日",
    "select dispatch or arrival date": "設定預計出貨日或預計到貨日",
    "select einvoice printer terminal": "選擇電子發票印表機主機",
    "select images": "選擇圖片",
    "select iteration count": "選擇迴圈次數",
    "select periods": "選擇期間",
    "select product": "選取產品",
    "select reward program first": "先選取獎勵計劃",
    "select specific dates": "選擇排除日期",
    "select stage": "選擇階段",
    "select store closed day": "選擇店休日（結帳頁日曆顯示反灰，即顧客不可選取日期",
    "select workflow": "選擇工作流程",
    "selectable": "可選",
    "selected campaigns review": "已選審查流程",
    "selected items": "已選擇",
    "selected orders": "已選擇的訂單",
    "selling exclusive price": "銷貨未稅價",
    "selling inclusive price": "銷貨含稅價",
    "send": "發送",
    "send a copy of email to addresses": "電子郵件的副本發送地址",
    "send a copy of order email to addresses": "電子郵件的副本發送地址",
    "send a copy of order messages to addresses": "訂單留言電子郵件的副本發送地址",
    "send all dispatched emails": "發送所有出貨通知信",
    "send all emails": "發送所有電子郵件",
    "send birthday reward": "寄送生日獎勵",
    "send birthday rewards manually": "手動寄送生日獎勵",
    "send dispatched email": "發送已出貨通知信",
    "send document": "發送電子郵件",
    "send email": "寄送電子郵件",
    "send email message": "寄送電子郵件",
    "send email to customer": "寄給顧客(需要顧客信箱)",
    "send emails": "寄送電子郵件",
    "send emails when successful": "成功送出問答或表單時寄送電子郵件",
    "send from website": "寄送網站",
    "send payment reminder when overdue by": "逾期幾日後發送付款提醒信",
    "send reward": "發送獎勵",
    "send reward manually": "手動寄送獎勵",
    "send test email": "寄送測試電子郵件",
    "send void email": "發送已取消通知信",
    "sender address": "寄件人地址",
    "sender company": "寄件人公司",
    "sender name": "寄件人姓名",
    "sender telephone": "寄件人電話",
    "sender_address": "集貨地址",
    "sent_member_level_adjustment": "已送出：會員等級調整",
    "sent_valid_points_adjustment": "已送出：會員點數調整",
    "seo": "SEO",
    "seo-settings": "SEO 設定",
    "separate documents based on customizations name": "根據客製化名稱單獨顯示",
    "separate each keyword using comma": "使用逗號分每個關鍵字",
    "separate line": "上下分行(電腦版限定)",
    "separator": "分隔符",
    "server logs": "伺服器紀錄(Server Logs)",
    "session expired due to inactivity": "憑證已過期，請重新登錄",
    "set as default": "設為預設",
    "set as default business": "設為預設公司",
    "set as default for": "設為預設",
    "set as shop default": "設為商店預設",
    "set commission amount": "設定抽成總額",
    "set commission base cost": "設定抽成底價",
    "set default": "設為預設值",
    "set link": "新增連結",
    "set member level": "設定會員等級",
    "set member level by points": "以點數設定會員等級",
    "set number of days": "指定可選取天數",
    "set period": "選擇期間",
    "set price": "設定價格",
    "set price on bundle": "設總價",
    "set prices later": "現不要設定價格",
    "set prices now": "立即設定價格",
    "set schedules": "點擊設定",
    "set shipping zones": "設定運費規則",
    "set shop default shipping zones": "設定商店預設運費規則",
    "set today as the first day": "日曆起算日包含當日",
    "setType_": "一般商品",
    "setType_hasVariants": "多規格商品",
    "setType_isSet": "組合商品",
    "setType_isSetWithChoices": "任選商品",
    "setType_isVariantChild": "產品變體子項",
    "setTypes": "組合種類",
    "set_price_confirmation": "您確定要使用 {price} 作為當前項目的價錢?",
    "settings": "設定",
    "settings_documents": "銷售及進貨",
    "settings_invoices": "電子發票",
    "settings_payments": "金流",
    "settings_shipping": "物流",
    "settings_social": "行銷追蹤及社群連結",
    "settings_style": "風格",
    "setup wizards": "快速建立精靈",
    "seven-eleven": "7-11",
    "seven-eleven contact": "7-11 聯絡資料",
    "seven-eleven credentials": "7-11憑證",
    "seven-eleven none payment on pickup": "7-11 取貨不付款廠商編號",
    "seven-eleven none payment on pickup frozen": "7-11 None payment on Pickup Frozen",
    "seven-eleven none payment on pickup frozen print password": "7-11 None payment on Pickup Frozen Print password",
    "seven-eleven none payment on pickup frozen shop id": "7-11 None payment on Pickup Frozen Shop Id",
    "seven-eleven none payment on pickup frozen sub shop id": "7-11 None payment on Pickup Frozen Sub Shop Id",
    "seven-eleven none payment on pickup print password": "7-11 取貨不付款廠列印密碼",
    "seven-eleven none payment on pickup shop id": "7-11 取貨不付款廠商店 IＤ",
    "seven-eleven none payment on pickup sub shop id": "7-11 取貨不付款廠子商店 IＤ",
    "seven-eleven pay on checkout": "結帳付款",
    "seven-eleven pay on checkout print password": "7-11 結帳付款列印密碼",
    "seven-eleven pay on checkout shop id": "7-11 結帳付款商店 IＤ",
    "seven-eleven pay on checkout sub shop id": "7-11 結帳付款子商店 IＤ",
    "seven-eleven pay on pickup": "取貨付款",
    "seven-eleven pay on pickup print password": "7-11 取貨付款列印密碼",
    "seven-eleven pay on pickup shop id": "7-11 取貨付款商店 IＤ",
    "seven-eleven pay on pickup sub shop id": "7-11 取貨付款子商店 IＤ",
    "seven-eleven payment on pickup": "7-11 取貨付款廠商編號",
    "seven-eleven print password": "列印密碼",
    "seven-eleven return store description": "您可指定退件包裹送達門市，若未填則預設為包裹寄件門市",
    "seven-eleven sftp": "7-11 SFTP",
    "seven-eleven sftp frozen": "7-11 SFTP 冷凍",
    "seven-eleven sftp frozen password": "7-11 SFTP 冷凍 密碼",
    "seven-eleven sftp frozen username": "7-11 SFTP 冷凍 使用者名稱",
    "seven-eleven sftp password": "7-11 SFTP 密碼",
    "seven-eleven sftp username": "7-11 SFTP 使用者名稱",
    "seven-eleven shop id": "商店 IＤ",
    "seven-eleven sub shop id": "子商店 IＤ",
    "seven-eleven waybill": "7-11 托運單",
    "seven-eleven: out of service": "7-11目前系統發生問題，請稍後再試。",
    "sevenEleven": "7-11",
    "shakeX": "水平搖晃",
    "shakeY": "垂直搖晃",
    "share": "分享",
    "shipWithCartItems": "贈品隨貨寄出，不拆送",
    "shipped": "已發送",
    "shipping": "運輸",
    "shipping days": "預估配送時間(預設為1，若您選擇結帳頁顯示預計出貨日，此設定為email發送出貨通知時計算預估到貨時間用)",
    "shipping default payment method": "預設付款方式",
    "shipping fee": "運費",
    "shipping fee adjustment": "運費調整",
    "shipping fee type": "運費種類",
    "shipping fee using zones": "地區運費",
    "shipping payment method": "付款方式",
    "shipping rate": "運費費率",
    "shipping rate method": "多條件運費模式設定",
    "shipping rates": "運費費率",
    "shipping rates warning": "運費不正確",
    "shipping settings": "配送設定",
    "shipping type": "運送類型",
    "shipping zone": "運費規則",
    "shipping zone details": "運費規則細節",
    "shipping zones": "運費規則",
    "shipping zones according to selected cart items": "依據購物車品項劃分運費規則",
    "shipping-settings": "配送設定",
    "shippingStatus_addressChangeRequired": "需要更改地址",
    "shippingStatus_attentionRequired": "需要手動處理",
    "shippingStatus_awaitingDispatch": "等待出貨",
    "shippingStatus_cancelled": "已取消",
    "shippingStatus_completed": "已完成",
    "shippingStatus_delivered": "已送達",
    "shippingStatus_exception": "異常",
    "shippingStatus_noWaybill": "未產生托運單",
    "shippingStatus_outForDelivery": "配送中",
    "shippingStatus_readyForPickupAtDestination": "已在目的地",
    "shippingStatus_readyForPickupAtReturnDestination": "已在退貨目的地",
    "shippingStatus_shipped": "已發送",
    "shippingStatus_toReturn": "已準備退貨",
    "shippingStatus_waybillCreated": "托運單已成立",
    "shipping_convenience_store": "超商運費",
    "shipping_multiple": "額外運費",
    "shipping_single": "運送區域",
    "shop": "商店",
    "shop checkout note character limit": "商店結帳備註字數限制",
    "shop filters": "商店篩選",
    "shop lists": "商店清單",
    "shop name": "商店名稱",
    "shop not found": "找不到商店",
    "shop or dispatch status is empty!": "商店狀態或出貨狀態未設定！",
    "shop or website": "商店或官網",
    "shop review": "商店總審查",
    "shop settings": "商店設定",
    "shop url": "商店網址",
    "shop url (at least 6 characters)": "商店網址 (至少6個字符)",
    "shop url prefix": "商店網址開頭",
    "shop->campaigns": "商店 > 活動",
    "shop->customer service": "商店 > 客服",
    "shop->promo codes": "商店 > 優惠代碼",
    "shop->rewards": "商店 > 獎勵",
    "shop->shipping zones": "商店 > 運費規則",
    "shop->web": "商店 > 官網",
    "shop.com": "Shop.com (美安)",
    "shop.com characters limit": "Shop.com (美安) 字數限制: ",
    "shop.com instruction": "只有在與美安(Shop.com)簽訂合作並取得 Offer_ID 和 Advertiser_ID 後才可啟用此功能",
    "shopDisplaySettings": "商店顯示設定",
    "shopDotComCommission": "Shop.com(美安) 佣金",
    "shop_dot_com_notyetsent": "尚未傳送",
    "shop_dot_com_successful": "成功傳送",
    "shop_dot_com_unsuccessful": "傳送失敗",
    "shop_products_overview_style_condensed": "已壓縮",
    "shop_products_overview_style_full": "原圖",
    "shop_status_expiredHaveCommissionData": "已結團 - 已算抽成",
    "shop_status_expiredNoCommissionData": "已結團",
    "shop_status_inProgress": "進行中",
    "shop_status_linked_all": "已連結活動: 全部",
    "shop_status_linked_out_of_sync": "已連結活動: 未同步",
    "shop_status_noDate": "進行中 - 長期開啟",
    "shop_status_upcoming": "未開團",
    "shopping is disallowed": "禁止購物",
    "shops": "商店",
    "short description": "商品摘要",
    "should be unique values": "數值重複",
    "show activity tab in guest view": "在報顯示所有訂單紀錄",
    "show advanced CSS settings": "顯示進階CSS設定",
    "show aging in statements": "在對帳表顯示帳齡表",
    "show all": "顯示所有",
    "show all colors": "顯示所有顏色",
    "show all customization prices (including 0)": "顯示所有客製價格（包含0元）",
    "show available quantity": "顯示剩餘數量",
    "show back to shopping button": "顯示「繼續選購」按鈕",
    "show bot": "顯示機器人",
    "show campaign header": "顯示活動頁首",
    "show commissions tab in guests view": "在報表公開抽成頁面",
    "show contents": "顯示內容",
    "show countdown timer": "顯示倒數計時器",
    "show data for campaign period only": "只顯示活動期間的資料",
    "show description": "顯示敘述",
    "show descriptions": "顯示敘述",
    "show discounted price vs original price": "顯示折扣價格與原始價格",
    "show estimated delivery date": "顯示預計送達日期",
    "show faqs on footer": "在頁尾顯示常見問題",
    "show form on checkout": "結帳時顯示表單",
    "show grouped documents from different shops": "顯示不同商店來源的已合併文件",
    "show grouped documents into one": "將已合併文件顯示為單一文件",
    "show groups": "顯示合併後",
    "show images": "顯示產品圖片",
    "show images on documents": "所有文件顯示產品圖片",
    "show imported waybills only": "顯示匯入托運單",
    "show item code": "在產品頁面顯示產品編碼",
    "show label \"more\" on product": "顯示「...更多...」",
    "show label more": "顯示「...更多...」",
    "show layer manager": "顯示圖層管理器",
    "show less": "檢視更少",
    "show limited qty": "顯示限制數量",
    "show limits": "顯示限制數量",
    "show logo on menu": "在主選單顯示Logo",
    "show more": "檢視更多",
    "show number of lines for description": "顯示描述行數",
    "show on sale tag": "顯示特價中標籤",
    "show one blog per slide on mobile": "Show one blog per slide on mobile",
    "show original price": "顯示原價",
    "show page settings": "頁面顯示設定",
    "show payouts details": "顯示支出細節",
    "show prices": "顯示價格",
    "show print modal after import": "匯入後開啟列印視窗",
    "show product code": "顯示編碼",
    "show product code below items": "在產品下方顯示編碼",
    "show product code next to items": "在產品旁顯示編碼",
    "show product description": "顯示產品商品摘要",
    "show product info": "顯示產品資料",
    "show product set with choices": "顯示任選商品主要名稱(原品名)",
    "show quick nav": "顯示快速結帳按鈕(右下角)",
    "show returns": "顯示折讓",
    "show revenue by activity": "依瀏覽顯示營收",
    "show revenue by channel and shop": "依通路和商店顯示營收",
    "show revenue by contact": "依聯絡人顯示營收",
    "show revenue by payment method": "依付款方式顯示營收",
    "show revenue by payment method and promo code": "依付款方式及折扣碼顯示營收",
    "show revenue by product": "依商品顯示營收",
    "show revenue by shop": "依商店顯示營收",
    "show sales quantity": "顯示銷售數量",
    "show set details on export tables": "在匯出表上顯示組合詳細資料",
    "show shipping options on dispatching": "顯示運輸方式",
    "show shipping waybills on dispatching": "顯示付款方式",
    "show shop.com commission": "顯示 Shop.com(美安) 佣金",
    "show style manager": "顯示樣式管理器",
    "show tags on filter": "在篩選清單顯示標籤",
    "show tags on page": "在頁面顯示標籤",
    "show total due": "顯示最低應繳總額",
    "show total due when printing contact statements": "列印對帳表時顯示最低應繳總額",
    "show total quantity when printing documents": "列印文件時顯示總數量",
    "show unit": "顯示單位",
    "show unit below items": "在產品下方顯示單位",
    "show unit next to items": "在產品旁顯示單位",
    "show units": "顯示單位",
    "show website name on menu": "在主選單顯示官網名稱",
    "sigint": "重新啟動",
    "sign up": "註冊",
    "signature": "簽名",
    "signup": "註冊",
    "signup reward email": "註冊會員獎勵通知信",
    "simple": "單一",
    "simpleEditor_blocks": "區塊",
    "single delivery date": "單次出貨日期",
    "single discount": "單一折扣",
    "single discount rate": "單一折扣率",
    "single time purchase": "單次購買",
    "single_month": "1個月",
    "sinopac": "SinoPac",
    "skip weekends": "跳過週末（週六/週日）",
    "slide": "輪播頁",
    "slideInDown": "向下滑入",
    "slider": "輪播圖",
    "slider active buttons color": "輪播按鈕活躍色",
    "slider buttons color": "輪播按鈕顏色",
    "slider frame": "所有輪播頁外框",
    "slides": "所有輪播頁",
    "small": "小",
    "smaller": "縮小",
    "social links": "社群媒體連結",
    "social-media": "社群媒體",
    "sold out": "已售完",
    "soldOut": "已售完",
    "some": "部分",
    "some items have incorrect shipping zones": "有些商品的運費規則不正確",
    "sort by": "排序方式",
    "sort customer order using shop product index": "依照商品上架順序排序顧客訂單",
    "sort detail row by campaign detail index": "依照商品上架順序排序",
    "sort direction": "排序方向",
    "spacing settings": "間距設定",
    "spec": "規格",
    "spec_detail": "規格詳情",
    "spec_name": "規格名稱",
    "specialty and local good": "名特產",
    "specify first and last date": "指定開始及結束日期",
    "specs": "規格",
    "spend reward points": "套用會員點數",
    "split": "拆單",
    "split from": "拆單自",
    "split from original": "拆單自原始文件",
    "split qty": "拆單數量",
    "split_document": "拆單",
    "split_shipping": "拆單",
    "stage": "階段",
    "stages": "階段",
    "starred": "書籤",
    "start": "從",
    "start date": "起始日期",
    "start from": "從",
    "start time": "開始時間",
    "startup": "啟動",
    "statement": "對帳表",
    "statement count": "Statement Count",
    "statements": "對帳表",
    "stats for campaign": "活動數據",
    "stats for month": "本月",
    "stats for year": "本年",
    "status": "狀態",
    "status for when over sales limited qty": "當已售出數量超過限制銷售數量狀態",
    "status will be applied to all schedules for current bulk discount": "將會套用至當前量販折扣的所有排程",
    "step": "步驟",
    "stereotyped contract content": "定型化契約內容",
    "sticky top": "置頂",
    "store location": "實體通路",
    "store location address": "地址",
    "store location pick up": "實體通路取貨",
    "store locations": "實體通路",
    "storeAddress": "商店地址",
    "storeId": "商店ID",
    "storeName": "商店名",
    "street": "街道名",
    "string": {
      "max": "\"{value}\" 最大字數 {limit}",
      "min": "\"{value}\" 最小字數 {limit}",
      "pattern": {
        "name": "\"{value}\" 不是有效的數字"
      }
    },
    "styles settings": "風格設定",
    "sub account number": "次要帳戶",
    "sub page": "子頁面",
    "subject": "主旨",
    "submit": "確定送出",
    "submit hct summary": "上傳新竹物流總表",
    "subtitle": "副標題",
    "subtotal": "小計",
    "success order description": "自訂訂單成功頁面訊息",
    "successful": "成功",
    "suggested": "建議",
    "sunday": "星期日",
    "sunday opening hours": "星期日營業時間",
    "sunny Bank": "Sunny Bank",
    "super": "超級用戶",
    "supplier": "供應商",
    "supplier account": "供應商帳號",
    "supplier activity": "供應商歷史",
    "supplier information": "供應商資料",
    "supplier payment method": "進貨付款方式",
    "supplier payment terms": "進貨付款條件",
    "supplier reconciliations": "供應商對帳表",
    "surcharge": "附加費用",
    "surcharge type": "附加費用類型",
    "surcharge_floor": "下限",
    "suspended": "暫停",
    "swing": "搖擺",
    "sync campaigns": "將主活動同步至所有子活動",
    "sync date": "同步日期",
    "sync limit qty with inventory": "共用商品庫存量",
    "sync master to all children": "將主活動同步至所有子活動",
    "sync product properties": "同步產品內容",
    "system generated": "系統產生",
    "systemlead": "e首發票",
    "systemlead_companyId": "公司ID",
    "systemlead_remind": "低於一定數量時通知",
    "systemlead_remindCount": "當字軌配號低於一定數量時發送通知",
    "systemlead_salt": "Salt",
    "systemlead_showZeroValueItems": "在電子發票顯示0元產品",
    "t-cat": "黑貓宅急便",
    "t-cat contact": "黑貓聯絡人",
    "t-cat credentials": "黑貓宅急便憑證",
    "t-cat custom product name": "自訂黑貓托運單顯示品名",
    "t-cat default package size": "黑貓預設包裹尺寸",
    "t-cat default product type": "黑貓預設產品類型",
    "t-cat default thermosphere": "黑貓預設配送溫層",
    "t-cat delivery time": "黑貓配送時間",
    "t-cat id": "黑貓代號",
    "t-cat package size": "黑貓包裹尺寸",
    "t-cat print type": "黑貓列印托運單類別",
    "t-cat temperature": "黑貓配送溫層",
    "t-cat thermosphere": "黑貓配送溫層",
    "t-cat token": "黑貓安全權杖",
    "t-cat use custom product name": "自訂黑貓托運單顯示品名",
    "t-cat waybill": "黑貓托運單",
    "tab": "頁籤按鈕",
    "tab container": "頁籤按鈕層",
    "tab content": "頁籤內容",
    "tab contents": "頁籤內容層",
    "tab name": "頁籤",
    "table lookup": "自定義",
    "tablet": "平板",
    "tabs": "頁籤",
    "tada": "搭啦!",
    "tag": "標籤",
    "tags": "標籤",
    "tags_filters_sort": "標籤分類排序",
    "taishin": "TaiShin",
    "taiwan cooperative bank": "Taiwan Cooperative Bank",
    "taiwan percentage": "台灣折數百分比",
    "tapPay": "Tap Pay",
    "tapPay_3ds": "3D驗證",
    "tapPay_isBig5": "僅允許 Big5",
    "tapPay_merchant_id": "商家編號",
    "tapPay_partner_key": "Partner Key",
    "task": "任務",
    "tax": "稅",
    "tax %": "稅 %",
    "tax after discount": "折扣後稅",
    "tax allowed": "允許稅率",
    "tax exclusive": "未稅",
    "tax inclusive": "含稅",
    "tax method": "計稅方式",
    "tax number": "統編",
    "tax on purchases": "進貨稅",
    "tax on sales": "銷貨稅",
    "tax rate": "稅率",
    "tax settings": "稅金設定",
    "tax total": "稅總計",
    "tax_auto": "自動",
    "tax_companyName": "公司抬頭",
    "tax_exclusive": "未稅",
    "tax_inclusive": "含稅",
    "tax_none": "無",
    "taxes": "稅",
    "tcat_sender_address": "速達前往集貨地址",
    "tel": "電話",
    "telephone": "電話",
    "template": "內文",
    "templates": "內文",
    "terms": "交易條件",
    "test code field is only for test purposes. it must be empty in production": "Test Code 只能在測試環境填入，正式上線時須保持空白",
    "test user": "Test user",
    "text": "文字",
    "text block": "文字區塊",
    "text-block": "標題及文字",
    "text-block-with-subtitle": "標題與副標題標題與副標題",
    "text_content": "內文",
    "the company name, {companyName}, does not match the company name for the tax number. Do you want to use {taxNumberCompanyName}?": "公司名稱 「{companyName}」與統編公司名稱不符，您要使用 「{taxNumberCompanyName}」 嗎？",
    "the current product is still a draft. The product schedule will not work.": "The current product is still a draft. The product schedule will not work.",
    "the system will schedule a refund after voiding the order": "訂單作廢後，系統將排程退刷。",
    "themeGeneralLetterSpacing": "總體文字間隔",
    "themeHeaderH1LetterSpacing": "H1標題文字間隔",
    "themeHeaderH2LetterSpacing": "H2標題文字間隔",
    "themeHeaderH3LetterSpacing": "H3標題文字間隔",
    "themeHeaderH4LetterSpacing": "H4標題文字間隔",
    "themeHeaderH5LetterSpacing": "H5標題文字間隔",
    "themeHeaderH6LetterSpacing": "H6標題文字間隔",
    "themeNavTreeItemMarginX": "主選單子元素水平方向外距",
    "themeNavTreeItemPaddingX": "主選單子元素水平方向內距",
    "themes": "設定",
    "thermal": "熱轉印宅配",
    "thermal printer": "熱感應印刷",
    "this comment will be public": "該留言將顯示在顧客頁面",
    "this image exceeds max dimensions": "圖片超過最大尺寸",
    "this is the legacy way to share shop reports. please use the new way": "舊版分享商店報表方式，請使用新版功能！",
    "this is the new way to share shop reports": "新版分享商店報表功能，只要提供合作夥伴連結，對方即可註冊登入查看商店報表。",
    "this may already have been dispatched on a previous day- are you sure you want to change its dispatch status": "這可能已經在前一天出貨了。您確定要更改出貨狀態嗎？",
    "this month": "本月",
    "this product exists in documents/campaigns! the products will be set as inactive instead": "已有銷售紀錄無法執行刪除，所有商店將同步改為無效狀態",
    "this product set is outdated": "這個組合商品已經過期",
    "this product set is outdated, click here to change it to the new type (set item prices individually)": "這個組合商品已經過期，請點擊這裡以更新資訊（分別設定各項商品價格",
    "this row contains invoices with partial returns and/or returns without linked invoice": "此行包含部分折讓的產品，或是折讓單裡手動輸入的名稱",
    "this status will override all schedule statuses": "此狀態將覆蓋所有排程狀態",
    "this window": "本視窗",
    "three per page": "A4三模宅配",
    "threeByFour": "3x4",
    "threeByOne": "3x1",
    "threeByTwo": "3x2",
    "threeToFiveDays": "3-5天",
    "three_months": "3個月",
    "threw an error when running default method": "輸入錯誤",
    "thursday": "星期四",
    "thursday opening hours": "星期四營業時間",
    "time": "時間",
    "times per order": "每筆訂單次數上限",
    "title": "標題",
    "to": "收方",
    "to change please check allow seo settings on website": "如要更改，請至官網設定",
    "to issue e-invoices": "可開立",
    "to print": "列印",
    "to send": "可送出",
    "to send birthday": "寄送生日獎勵",
    "to send promotion": "寄送促銷獎勵",
    "today": "今日",
    "toggle off all override": "所有商品皆不共用",
    "toggles_no": "沒有",
    "toggles_yes": "有",
    "too long": "太長",
    "top": "上方",
    "total": "總計",
    "total active contacts": "全部活躍的聯絡人",
    "total adjustment": "總調整",
    "total amount paid": "已付款金額",
    "total commission": "總抽成",
    "total commission base cost": "總抽成底價",
    "total commissions due": "應付總抽成",
    "total commissions due returned": "應付總退貨抽成",
    "total commissions due this month": "本月到期總抽成",
    "total commissions generated": "抽成總額",
    "total commissions generated returned": "總退貨抽成",
    "total commissions generated this month": "本月產生總抽成",
    "total credit card amount": "總信用卡金額",
    "total details without shipping zones": "還有 {total} 個商品未選擇運費規則 !",
    "total due": "總應付",
    "total due after adjustment": "調整後總應付抽成",
    "total due after payouts": "支出後總應付抽成",
    "total due at": "到期最低應繳總額 {date}",
    "total inclusive discounted price": "折扣後含稅總價",
    "total inclusive price": "含稅總價",
    "total limit": "次數上限",
    "total limited qty": "總限制數量",
    "total orders": "總訂單",
    "total periods": "總共期數",
    "total points expiring": "即將到期點數總計",
    "total qty": "總數量",
    "total qtys": "總數量",
    "total quantity": "總數量",
    "total returns": "總退貨",
    "total revenue": "總營業額",
    "total sales": "總銷售",
    "total times per person": "每人次數上限",
    "total_data": "總額",
    "total_dispatched": "已出貨金額",
    "total_invoices_amount": "電子發票金額",
    "total_invoices_attentionRequired": "需要手動處理",
    "total_invoices_cancelled": "已取消金額",
    "total_invoices_pending": "未完成金額",
    "total_invoices_pendingCancellation": "取消處理中金額",
    "total_invoices_successful": "電子發票金額",
    "total_rounding_difference_explanation": "總金額的差額是因四捨五入產生",
    "track inventory": "庫存追蹤",
    "tracking": "追蹤",
    "tracking code": "追蹤碼",
    "tracking codes": "追蹤碼",
    "transaction number": "交易編號",
    "transaction type": "交易類型",
    "transport": "貨運",
    "transporter": "物流公司",
    "transporter (optional)": "物流公司 (非必填)",
    "transporter account": "物流客代",
    "transporter account (optional)": "物流客代 (非必填)",
    "transporter account number": "物流客代",
    "transporter accounts": "物流客代",
    "transporter credentials": "物流業者憑證",
    "transporter notes": "貨運公司記事",
    "transporter package temperature": "物流包裹溫度",
    "transporter pickup location": "物流取貨點",
    "transporter pickup location name": "物流取貨點名稱",
    "transporter settings": "物流設定",
    "transporter type": "物流型態",
    "transporter-settings": "物流設定",
    "transporterAccount": "物流客代",
    "transporterPackageTemperature": "物流包裹溫度",
    "transporter_account_short": "物流客代",
    "try it now": "立即試用",
    "try latest version with latest features (beta)": "我們有新版本，包含新功能 (beta)",
    "tuesday": "星期二",
    "tuesday opening hours": "星期二營業時間",
    "twEInvoicePrint": "允許列印電子發票",
    "tw_auto_issue_eInvoices": "自動開立電子發票",
    "tw_auto_print_invoices": "允許手動建立訂單開立電子發票",
    "tw_auto_print_invoices_for_shop": "允許商店成立訂單開立電子發票",
    "tw_default_invoices_view": "預設電子發票檢視",
    "tw_eInvoice_auto_issue_fourteen_days_after": "出貨後14天自動開立",
    "tw_eInvoice_auto_issue_on_dispatch": "出貨時自動開立",
    "tw_eInvoice_auto_issue_seven_days_after": "出貨後7天自動開立",
    "tw_eInvoice_carrierType_app": "手機載具",
    "tw_eInvoice_carrierType_b2b": "營業人發票",
    "tw_eInvoice_carrierType_person": "自然人憑證",
    "tw_eInvoice_carrier_id": "載具編號",
    "tw_eInvoice_carrier_type": "載具",
    "tw_eInvoice_carrier_type_optional": "電子發票類別 (非必填)",
    "tw_eInvoice_company_name": "公司抬頭",
    "tw_eInvoice_print_custom_block": "列印自訂區域",
    "tw_eInvoice_print_custom_block_placeholder": "在列印附註或明細時每行限制字數為：\\\\n 英文34個字元，\\\\n 中文17個字元，\\\\n 如果中英夾雜則1個中文等於2個英文字元的長度，\\\\n 但我們不能保證中英文並用時列印格式是否能維持正確。\\\\n\n",
    "tw_eInvoice_print_reload": "重新載入電子發票印表機",
    "tw_eInvoice_provider_bigOrdr": "綠界科技",
    "tw_eInvoice_provider_systemlead": "e首發票",
    "tw_eInvoice_queued_successfully": "排程成功，發票將自動開立",
    "tw_eInvoice_reissue": "重新開立電子發票",
    "tw_eInvoice_reprint": "重新列印電子發票",
    "tw_eInvoices": "電子發票",
    "tw_eInvoices_allocate_numbers": "字軌切本",
    "tw_eInvoices_allow_numbers": "字軌可在本系統開立",
    "tw_eInvoices_allow_numbers_off_warning": "請注意:如字軌不在本系統開立，儲存後將無法修改",
    "tw_eInvoices_attention_required": "需要手動處理",
    "tw_eInvoices_available_any": "不指定列表機",
    "tw_eInvoices_blankUploaded_successful": "已上傳",
    "tw_eInvoices_blankUploaded_unsuccessful": "未上傳",
    "tw_eInvoices_book": "本號",
    "tw_eInvoices_captured": "已確認紀錄",
    "tw_eInvoices_captured_invoices": "已確認紀錄發票",
    "tw_eInvoices_captured_returns": "已確認紀錄發票折讓",
    "tw_eInvoices_count": "張數",
    "tw_eInvoices_custom_description": "自訂說明",
    "tw_eInvoices_disallowed": "不可開立",
    "tw_eInvoices_edit_and_reissue": "編輯並重新開立電子發票",
    "tw_eInvoices_first_number": "起始號",
    "tw_eInvoices_include_until_current_date": "所有未開電子發票至",
    "tw_eInvoices_issue_results": "開立電子發票結果",
    "tw_eInvoices_last_number": "終止號",
    "tw_eInvoices_last_number_used": "最後開立發票號碼",
    "tw_eInvoices_no_allocated": "未分配電子發票號碼",
    "tw_eInvoices_no_printer_setup": "印表機尚未設定。請登入 BigOrdr 印表機應用程式。",
    "tw_eInvoices_no_track_numbers": "無電子發票字軌",
    "tw_eInvoices_notify_email_address": "電子發票通知電子信箱",
    "tw_eInvoices_numbers": "字軌",
    "tw_eInvoices_numbers_auto_upload_blanks": "系統每單數月5號21:00自動上傳未使用空白發票",
    "tw_eInvoices_numbers_available_count": "剩餘發票數",
    "tw_eInvoices_numbers_blank_end": "空白終止號",
    "tw_eInvoices_numbers_blank_numbers": "空白號",
    "tw_eInvoices_numbers_blank_remaining_unused": "未使用空白號",
    "tw_eInvoices_numbers_blank_start": "空白起始號",
    "tw_eInvoices_numbers_blank_upload_all_remaining_unused": "含所有未使用空白號",
    "tw_eInvoices_numbers_books_end": "本號迄",
    "tw_eInvoices_numbers_books_range": "本號起迄",
    "tw_eInvoices_numbers_books_start": "本號起",
    "tw_eInvoices_numbers_current_books_range": "本字軌範圍",
    "tw_eInvoices_numbers_end": "終止號",
    "tw_eInvoices_numbers_enter_blanks": "輸入空白號",
    "tw_eInvoices_numbers_last": "最後取號",
    "tw_eInvoices_numbers_manager": "字軌管理",
    "tw_eInvoices_numbers_next": "下次取號",
    "tw_eInvoices_numbers_no_available_count": "無可開立發票字軌",
    "tw_eInvoices_numbers_no_available_count_create_docSales_without_eInvoice": "無可用電子發票號碼，是否直接建立出貨單而不開立電子發票？",
    "tw_eInvoices_numbers_remaining": "剩餘發票數",
    "tw_eInvoices_numbers_start": "起始號",
    "tw_eInvoices_numbers_upload_blanks": "上傳空白號",
    "tw_eInvoices_numbers_upload_blanks_not_uploaded": "未上傳",
    "tw_eInvoices_only_remaining": "所有部份補開發票",
    "tw_eInvoices_prefix": "字軌",
    "tw_eInvoices_print_aesKey": "電子發票 AESKey",
    "tw_eInvoices_print_allow_print": "允許列印電子發票",
    "tw_eInvoices_print_allow_shop": "允許在商店顯示是否列印電子發票",
    "tw_eInvoices_printed_count": "次數",
    "tw_eInvoices_printed_pending": "未列印",
    "tw_eInvoices_printed_successful": "已列印",
    "tw_eInvoices_scheduled": "電子發票預計在今晚九點自動發送",
    "tw_eInvoices_total_available": "全部可用發票總數",
    "tw_eInvoices_total_available_per_printer": "指定列表機",
    "tw_eInvoices_uncaptured_total": "未確認紀錄總額",
    "tw_eInvoicing": "電子發票",
    "tw_invoicing": "統一發票",
    "tw_issue_print_eInvoice": "開立且列印電子發票",
    "tw_print_eInvoices": "開立電子發票功能",
    "tw_print_eInvoices_now": "開立電子發票",
    "tw_print_invoices": "列印統一發票",
    "two factor authentication": "OTP認證",
    "two factor authentication code has been resent": "已重新發送驗證碼至您的信箱。",
    "two per page": "A4二模宅配",
    "twoByOne": "2x1",
    "twoByThree": "2x3",
    "two_factor_authentication_resendCode": "重新發送驗證碼",
    "txt verification": "txt verification",
    "type": "種類",
    "type delete to delete": "輸入「 刪除 」",
    "type_content": "輸入一些內容",
    "typing": "打字效果",
    "unable to add new option- please try again later-": "無法新增新的選項，請稍候再嘗試",
    "unable to create profile- please try again later-": "無法建立簡介，請稍候再嘗試",
    "unable to update profile- please try again later-": "無法更新簡介，請稍候再嘗試",
    "unable to verify account- please try again later-": "無法辨識帳號，請稍候再嘗試",
    "unallocated": "未分配",
    "unarchive": "取消封存",
    "unassigned": "未指定",
    "uncaughtException": "未捕捉的異常 (Uncaught Exception)",
    "underpaid": "不足額",
    "undispatched": "未出貨",
    "undispatched or due": "未出貨 / 未到期",
    "undo": "回復",
    "undo cancel invoice": "回復已取消發票",
    "undo void": "取消作廢",
    "unfinished": "未完成",
    "ungroup_document": "取消合併",
    "unhandledRejection": "未處理的拒絕 (Unhandled Rejection)",
    "unhold all": "全部取消暫停",
    "union bank Of taiwan": "Union Bank Of Taiwan",
    "uniqueTagsOnly": "Show Each Tag Separately (Unique Tags Only)",
    "unit": "單位",
    "units": "單位",
    "unlink": "去除連結",
    "unlock": "解鎖",
    "unpaid total": "未付款總額",
    "unprepared": "未準備",
    "unprinted": "未列印",
    "unrefrigerated": "常溫",
    "unsuccessful": "不成功",
    "unsuccessfulDetail": "任選、組合或多規格商品內容不符合",
    "untag": "取消標籤",
    "update": "更新",
    "update bulk discount": "編輯量販折扣",
    "update failed the data is out of date as it may have been changed by someone else please cancel and try again": "更新失敗，您的資料已過期，可能有遭到變動，請先取消並再試一次",
    "update failed the data is out of date as it may have been changed by someone else please try again": "更新失敗，您的資料已過期，可能有遭到變動，請再試一次",
    "update hct contact": "更新新竹物流聯絡資料",
    "update now": "立即更新最新版本",
    "update required, please reload": "已更新版本。請重新載入頁面",
    "update seven-eleven contact": "更新進階 7-11 聯絡資料",
    "update status": "更新狀態",
    "update t-cat contact": "更新黑貓聯絡人",
    "update waybill with latest changes. are you sure you want to update?": "將依最新的更動更新托運單，您是否確定要更新？",
    "update waybills": "更新托運單",
    "update waybills with the latest changes. the update will omit any expired waybill. are you sure you want to update?": "將依最新的更動更新托運單，此更動會移除所有過期的托運單，您是否確定要更新？",
    "updated": "已更新",
    "updated date": "更新日期",
    "updated time": "更新時間",
    "upload files": "上傳檔案",
    "upload image": "上傳圖片",
    "upload images": "上傳圖片",
    "upload images to campaigns": "上傳活動圖片",
    "upload images to products": "上傳產品圖片",
    "upload logo": "上傳商標",
    "upload status": "上傳狀態",
    "uploaded files": "檔案",
    "uploaded to shops": "銷售中",
    "uploading please wait": "上傳中請稍候...",
    "url": "網址",
    "usage limit": "使用次數總上限",
    "usage limit per person": "每人使用次數上限",
    "usage limits": "使用限制",
    "use advanced address": "使用進階地址",
    "use average prices for each product": "每個產品都使用平均售價",
    "use campaign period": "使用活動期間",
    "use company email address when sending email manually": "人工寄送郵件時使用公司郵件地址",
    "use custom date": "使用自訂時間",
    "use custom message for additional free shipping": "自訂未達免運門檻訊息內容",
    "use custom message for quoted rate shipping": "使用自訂訊息顯示報價運費",
    "use default auto redirect url from settings": "結團後自動到到不同的網址(使用預設網址)",
    "use default birthday message in rewards": "使用預設生日祝賀訊息（在獎勵）",
    "use default birthday message in settings": "使用預設生日祝賀訊息（在設定）",
    "use default description": "使用預設敘述",
    "use default message in settings": "使用設定預設",
    "use default products display settings from settings": "產品顯示: 使用設定預設",
    "use default promotion message in rewards": "使用預設促銷訊息（在獎勵）",
    "use default promotion message in settings": "使用預設促銷訊息（在設定）",
    "use default width": "使用預設寬度",
    "use defaults estimated delivery date in settings": "使用預設預計送達日期",
    "use defaults flat fee": "使用預設的固定運費",
    "use defaults from products": "使用產品預設",
    "use defaults from settings": "系統預設",
    "use defaults shipping zones": "使用預設的運費規則",
    "use defaults shop price filter": "使用預設價格篩選",
    "use exclusive selling price to calculate commission": "使用未稅售價計算抽成",
    "use expiry date instead": "改為使用期限",
    "use inventory qty as product limits": "共用商品庫存量",
    "use inventory qty as product limits defaults": "預設共用商品庫存量",
    "use normal percentage": "使用百分比折扣",
    "use original amount at dispatch date": "使用出貨日期的原始金額",
    "use payment terms": "使用付款條件",
    "use preset commissions to fill commission value": "將預設抽成設為抽成金額",
    "use product set children": "組合價以單品計算抽成",
    "use shipping fee adjustment": "使用調整運費",
    "use taiwan percentage": "使用折數（例如：8.5折）",
    "use text area": "使用文字區域",
    "use website logo for delivery note": "出貨單使用網站 Logo",
    "user": "使用者",
    "user colors": "使用者顏色",
    "user didnt complete the transaction": "未完成信用卡交易",
    "user-agreement-settings": "使用者同意設定",
    "username": "使用者名稱",
    "username or email": "使用者名稱或電子信箱",
    "users": "使用者",
    "users to authorize refunds": "授權退款使用者",
    "valid": "valid",
    "valid input": "可輸入數值",
    "valid points": "有效點數",
    "validate domain": "validate domain",
    "validity date": "有效期",
    "value based rates": "以訂單金額設定運費",
    "value is required": "必填",
    "variant": "多規格商品",
    "variant child": "產品變體子項",
    "variant children": "產品變體子項",
    "variant code": "規格編碼",
    "variant options": "規格名稱",
    "variant selector style": "多規格商品選項風格",
    "variant type": "產品規格",
    "variantOptionsNameChanges": "將從 {originalOptionName} 變為 {newOptionName}",
    "variant_options_save_warning": "您尚有未編輯選項，請完成所有編輯後再儲存",
    "variants": "多規格商品",
    "vat": "增值稅",
    "verified payment": "確認付款",
    "version": "版本",
    "vertical": "垂直",
    "video": "影片",
    "video_source": "影片來源",
    "view": "檢視",
    "view available placeholders": "顯示可插入的語法內容",
    "view locked": "檢視鎖住",
    "view schedules": "查看時間表",
    "view webpage": "檢視網頁",
    "virtual account": "虛擬帳號",
    "virtual account bank code": "分行",
    "virtual account bank name": "銀行",
    "virtual account expiry date": "最後可轉帳日期",
    "virtual account number": "虛擬帳號",
    "virtual content attachments": "虛擬內容附件",
    "virtualAccount": "授權啟用虛擬帳戶",
    "virtual_account_from_account": "轉出帳號",
    "virtual_account_provider_eSun": "玉山銀行虛擬帳號",
    "virtual_account_provider_ecPay": "綠界科技虛擬帳號",
    "virtual_account_provider_ubot": "聯邦銀行虛擬帳號",
    "virtual_content": "虛擬產品內容",
    "void": "作廢",
    "warnings": "Warnings",
    "waybill": "托運編碼",
    "waybill status": "Waybill Status",
    "waybills": "托運單",
    "waybills created": "已產生托運單",
    "waybills first row bottom padding": "托運單第一行底部間距",
    "waybills last row top padding": "托運單最後一行頂部間距",
    "waybills not yet created": "未產生托運單",
    "waybills per page": "每頁托運單張數",
    "waybills per page b2c": "每頁托運單張數B2C",
    "waybills per page c2c": "每頁托運單張數C2C",
    "waybills-settings": "托運單設定",
    "web": "官網",
    "webhook_event_sent_failed": "通知失敗",
    "webhook_event_sent_successful": "通知成功",
    "webhooks: authorization token": "Webhook: 通知訂單【Authorization Token】(Bearer ...)",
    "webhooks: enable notify on order": "Webhook: 開啟通知訂單",
    "webhooks: notify on order URL": "Webhook: 通知訂單 URL",
    "webpage": "網頁",
    "webpage name": "網頁名稱",
    "webpage seo description": "網頁搜尋引擎優化描述",
    "webpage seo keywords": "網頁搜尋引擎優化及關鍵字",
    "webpage settings": "網頁設定",
    "webpage_content": "網頁內容",
    "webpage_home_page_redirect": "跳轉至首頁",
    "webpage_show_breadcrumb": "顯示麵包屑導覽",
    "webpage_show_on_filters": "在產品篩選顯示",
    "webpage_show_on_filters_short": "產品篩選",
    "webpage_show_on_footer": "在頁尾顯示",
    "webpage_show_on_footer_short": "頁尾",
    "webpage_show_on_menu": "在主選單顯示",
    "webpage_show_on_menu_short": "主選單",
    "webpage_template": "網頁模板",
    "webpages": "網頁",
    "webpages_content": "網頁內容",
    "website": "官網",
    "website admin mode": "網站管理者模式",
    "website info": "網站資訊",
    "website template": "網頁模版",
    "website url": "官網網址",
    "website url (at least 6 characters)": "官網網址 (至少6個字符)",
    "websiteForAllCampaigns": "團購官網",
    "website_action_color": "加入購物車-按鈕底色",
    "website_action_final_color": "結帳-按鈕顏底色",
    "website_action_final_hover_color": "結帳-按鈕滑鼠滑過變色",
    "website_action_final_text": "結帳-按鈕顏色",
    "website_action_final_text_color": "結帳-按鈕文字顏色",
    "website_action_hover_color": "加入購物車-按鈕滑鼠滑過變色",
    "website_action_text_color": "加入購物車-按鈕字體色",
    "website_active_color": "選定顏色",
    "website_background_primary_color": "背景主色",
    "website_background_secondary_color": "背景第二次色",
    "website_border_color": "邊框顏色",
    "website_button_border_color": "按鈕邊框顏色",
    "website_button_border_color2": "按鈕邊框顏色2",
    "website_button_border_color3": "按鈕邊框顏色3",
    "website_button_border_radius": "按鈕圓角值",
    "website_button_border_radius_2": "按鈕圓角值2",
    "website_button_border_radius_3": "按鈕圓角值3",
    "website_button_color": "按鈕顏色",
    "website_button_color2": "按鈕顏色2",
    "website_button_color3": "按鈕顏色3",
    "website_button_hover_color": "滑鼠移到按鈕的顏色",
    "website_button_hover_color2": "滑鼠移到按鈕的顏色2",
    "website_button_hover_color3": "滑鼠移到按鈕的顏色3",
    "website_button_hover_text_color": "滑鼠移到文字的顏色",
    "website_button_hover_text_color2": "滑鼠移到文字的顏色2",
    "website_button_hover_text_color3": "滑鼠移到文字的顏色3",
    "website_button_text_color": "按鈕文字顏色",
    "website_button_text_color2": "按鈕文字顏色2",
    "website_button_text_color3": "按鈕文字顏色3",
    "website_campaign_header": "商店活動頁首",
    "website_campaign_header_background_color": "商店活動背景色",
    "website_campaign_header_notice_color": "商店活動日期顏色",
    "website_campaign_header_subtitle_color": "商店活動內文顏色",
    "website_campaign_header_title_color": "商店活動名稱顏色",
    "website_chat_button_color": "Line/FB-對話按鈕顏色",
    "website_chat_button_hover_color": "Line/FB-滑鼠移到對話按鈕的顏色",
    "website_chat_button_text_color": "Line/FB-對話內文文字顏色",
    "website_count_down_color": "倒數計時顏色",
    "website_count_down_text_color": "倒數計時文字顏色",
    "website_faqs_answer_color": "常見問題回答顏色",
    "website_faqs_question_color": "常見問題顏色",
    "website_faqs_question_hover_color": "滑鼠移到常見問題的顏色",
    "website_footer_background_color": "頁尾背景色",
    "website_footer_background_primary_color": "頁尾背景色",
    "website_footer_header_color": "頁尾標題景色",
    "website_footer_link_color": "頁尾連結景色",
    "website_footer_link_hover_color": "頁尾連結滑鼠滑過變色",
    "website_footer_message": "頁尾內容",
    "website_footer_text_color": "頁尾字體景色",
    "website_headers": "自訂HTML Head內容",
    "website_input_background_color": "輸入格背景色",
    "website_input_border_color": "欄位邊框顏色",
    "website_input_border_focus_color": "欄位邊框聚焦顏色",
    "website_input_text_color": "輸入格文字色",
    "website_link_color": "連結字體顏色",
    "website_link_hover_color": "滑鼠移到網站連結的顏色",
    "website_menu_background_primary_color": "主選單背景主要色",
    "website_menu_background_secondary_color": "主選單背景次要色",
    "website_menu_border_color": "主選單外框色",
    "website_menu_button_color": "主選單按鈕顏色",
    "website_menu_button_hover_color": "主選單按鈕滑過顏色",
    "website_menu_item_border_color": "垂直主選單選項下方分隔線顏色",
    "website_menu_link_color": "主選單字體色",
    "website_menu_link_hover_color": "主選單字體滑鼠滑過變色",
    "website_menu_type": "種類",
    "website_original_price_text_color": "原價價格文字顏色",
    "website_primary_color": "主顏色",
    "website_sale_color": "特賣標籤顏色",
    "website_sale_price_text_color": "特賣價格文字顏色",
    "website_sale_text_color": "特賣標籤文字顏色",
    "website_scripts": "Javascript程式語言",
    "website_section_action": "加入購物車",
    "website_section_action_final_btn": "結帳",
    "website_section_backgrounds": "背景",
    "website_section_button_1": "按鈕1",
    "website_section_button_2": "按鈕2",
    "website_section_button_3": "按鈕3",
    "website_section_buttons": "按鈕",
    "website_section_carousel_banner": "橫幅滾動文字",
    "website_section_carousel_banner_color": "橫幅滾動文字背景顏色",
    "website_section_carousel_banner_link_hover_color": "橫幅滾動文字連結滑鼠滑過變色",
    "website_section_carousel_banner_text_color": "橫幅滾動文字連結文字顏色",
    "website_section_chat": "Line/FB聊天",
    "website_section_count_down": "倒數",
    "website_section_faqs": "常見問題",
    "website_section_footer": "頁尾",
    "website_section_inputs": "輸入格",
    "website_section_links": "連結",
    "website_section_nav": "主選單",
    "website_section_other": "其他",
    "website_section_sales": "特賣",
    "website_section_submenu": "子選單",
    "website_section_tags": "標籤",
    "website_section_text": "文字",
    "website_section_text_basic": "一般",
    "website_section_variant_button": "Variant Button",
    "website_seo_broken_links": "舊網站失效鏈接",
    "website_seo_description": "搜尋引擎優化描述",
    "website_seo_keywords": "搜尋引擎優化及關鍵字",
    "website_seo_url_redirects": "輸入舊網站的URL重新導向至新的URL (舊的URL == 新的URL)",
    "website_submenu_background_primary_color": "子選單背景主要色",
    "website_submenu_background_secondary_color": "子選單背景次要色",
    "website_submenu_border_color": "子選單外框色",
    "website_submenu_item_border_color": "垂直子選單選項下方分隔線顏色",
    "website_submenu_link_color": "子選單字體色",
    "website_submenu_link_hover_color": "子選單字體滑鼠滑過變色",
    "website_tag_color": "標籤顏色",
    "website_tag_text_color": "標籤文字顏色",
    "website_text_2_color": "第二階層內文的顏色",
    "website_text_3_color": "第三階層內文的顏色",
    "website_text_color": "文字顏色",
    "website_type_category": "產品分類頁面",
    "website_type_link": "連結",
    "website_type_link_blogs": "文章",
    "website_type_link_flows": "聊天機器人",
    "website_type_link_news": "新聞",
    "website_type_link_page": "網站頁面",
    "website_type_link_pageAndCategory": "網站頁面 & 產品分類頁面",
    "website_type_link_product": "產品",
    "website_type_link_store-location": "實體通路",
    "website_type_link_url": "網址",
    "website_type_page": "網站頁面",
    "website_variant_button_border_color": "多規格按鈕邊框顏色",
    "website_variant_button_border_radius": "多規格按鈕圓角值",
    "website_variant_button_color": "多規格按鈕顏色",
    "website_variant_button_hover_border_color": "多規格滑鼠移到按鈕邊框顏色",
    "website_variant_button_hover_color": "多規格滑鼠移到按鈕的顏色",
    "website_variant_button_hover_text_color": "多規格滑鼠移到文字的顏色",
    "website_variant_button_selected_border_color": "多規格已選擇按鈕邊框顏色",
    "website_variant_button_selected_color": "多規格已選擇按鈕顏色",
    "website_variant_button_selected_text_color": "多規格已選擇按鈕文字顏色",
    "website_variant_button_text_color": "多規格按鈕文字顏色",
    "websites": "官網",
    "wednesday": "星期三",
    "wednesday opening hours": "星期三營業時間",
    "weekday1": "星期一",
    "weekday2": "星期二",
    "weekday3": "星期三",
    "weekday4": "星期四",
    "weekday5": "星期五",
    "weekday6": "星期六",
    "weekday7": "星期日",
    "weeks": "星期",
    "weight": "重量",
    "weight based rates": "以重量設定運費",
    "weight need to be set from each child items": "重量須由各個產品子項設定",
    "weight unit": "重量單位",
    "welcome to bigordr": "歡迎來到BigOrdr!",
    "white": "白色",
    "width": "寬度",
    "width height ratio": "寬高比",
    "will be autocompleted": "將會自動完成",
    "win percentage": "勝率",
    "with grand totals": "加總計",
    "with prices": "含價格",
    "without prices": "不含價格",
    "wizard": "快速建立精靈",
    "wizard pending": "未完成...",
    "wizard success": "已完成!",
    "wizard_start": "開始",
    "wizards": "快速建立精靈",
    "wobble": "搖動籤筒",
    "workflow": "工作流程",
    "workflows": "工作流程",
    "workflows_items_activities_attentionRequired": "需要手動處理",
    "workflows_items_activities_authorization": "授權",
    "workflows_items_activities_call": "電話通話",
    "workflows_items_activities_email": "電子信箱",
    "workflows_items_activities_event": "事件",
    "workflows_items_activities_meeting": "會議",
    "workflows_items_activities_notes": "注意事項",
    "workflows_items_activities_refund": "退款",
    "workflows_items_activities_review": "審查",
    "workflows_items_activities_todo": "待辦事項",
    "workflows_items_activity": "事件",
    "workflows_items_status_open": "進行中",
    "workflows_items_status_re-open": "重啟",
    "workflows_items_status_successful": "成功",
    "workflows_items_status_unsuccessful": "不成功",
    "year": "年",
    "years": "年",
    "years_old": "歲",
    "yellow": "黃色",
    "yes": "是",
    "you are currently using the new version (beta)": "您目前使用新版本 (beta)",
    "you have less than to issue": "您的電子發票剩餘「 {availableCountToIssue} 」 張",
    "youtube": "YouTube",
    "youtube channel url": "Youtube頻道連結",
    "zone priority": "運送區域優先程度",
    "zones fee": "地區運費",
    "zoomIn": "放大"
  };
});