define("client/pods/components/websites/links-editor-modal/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WebsitesLinksEditorModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _class = class WebsitesLinksEditorModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "tempModel", _descriptor3, this);
      this._setup();
    }
    _setup() {
      const data = {};
      const attributes = this.args?.attributes || {};
      data.linkType = R.pathOr('url', ['data-href-link-type'])(attributes);
      data.linkTitle = R.pathOr(this.constants.websitePageLinkTypes.pageAndCategory, ['data-href-title'])(attributes);
      data.linkUrl = R.pathOr('', ['data-href'])(attributes);
      data.linkResourceName = R.pathOr('', ['data-href-resource-name'])(attributes);
      data.linkResourceType = R.pathOr('', ['data-href-resource-type'])(attributes);
      data.linkResourceKey = R.pathOr('', ['data-href-resource-key'])(attributes);
      data.linkTarget = R.pathOr('_self', ['data-href-target'])(attributes);
      const tempModel = this.crud.setupNewRecord({
        adapterName: 'websites/links',
        data
      });
      Ember.set(this, 'tempModel', tempModel);
    }
    updateLink(onCloseDialogAction) {
      const previousAttributes = this.args?.attributes || {};
      const tempModel = this.tempModel;
      const linkType = tempModel?._data?.linkType || '';
      const linkTitle = tempModel?._data?.linkTitle || '';
      const linkUrl = tempModel?._data?.linkUrl || '';
      const linkResourceName = tempModel?._data?.linkResourceName || '';
      const linkResourceType = tempModel?._data?.linkResourceType || '';
      const linkResourceKey = tempModel?._data?.linkResourceKey || '';
      const linkTarget = tempModel?._data?.linkTarget || '';
      const updatedAttributes = R.mergeRight(previousAttributes, {
        'data-href-target': linkTarget,
        'data-href': linkUrl || linkResourceKey,
        'data-href-link-type': linkType,
        'data-href-title': linkTitle,
        'data-href-resource-name': linkResourceName,
        'data-href-resource-type': linkResourceType,
        'data-href-resource-key': linkResourceKey
      });
      this.args.onUpdateModel(updatedAttributes);
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tempModel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLink", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateLink"), _class.prototype), _class);
});