define("client/pods/components/products/products-editor/variants-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/errors", "client/utils/nventor"], function (_exports, R, RA, _errors, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj; // import nventor from '../../../../../utils/nventor'
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_errors.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    productToBeLinked: Ember.A([]),
    adapterName: 'products',
    hasPressedDone: false,
    init() {
      this._super(...arguments);
      this.set('productToBeLinked', []);
      if (this.isEditing || this.isNew) {
        const model = this.model;
        model.setData('productsToBeUnlinked', []);
        const variants = model.getData('variants') || [];
        R.forEach(variant => {
          const options = variant.getData('options') || [];
          R.forEach(option => {
            if (!option._data.optionUid) {
              const optionUid = _nventor.default.random.alphaNum(10);
              option.setData('optionUid', optionUid);

              // this is for legacy purposes. for when variant children were created without a optionUid
              option.set('noInitialOptionUid', true); // false until saved
            }
          })(options);
        })(variants);
        const originalModel = this.originalModel;
        model.updateVariantChildrenList({
          originalModel,
          setInitialValue: this.isEditing
        });
        if (RA.isNilOrEmpty(variants)) {
          model.addVariant();
        }
        if (this.isNew) {
          const originalVariants = model.getData('variants') || [];
          if (RA.isNotNilOrEmpty(originalVariants)) {
            const originalVariantsClone = R.map(variant => {
              return this.dataManager.copyRecord('products/variants', variant);
            })(originalVariants);
            this.set('originalVariants', originalVariantsClone);
          }
        }
      }
    },
    isReadonlyAndAvailable: Ember.computed('model._data.hasVariants', 'isReadonly', function () {
      if (!this.get('model._data.hasVariants')) {
        return true;
      }
      return R.propOr(false, 'isReadonly')(this);
    }),
    showCancelVariants: Ember.computed('hasPressedDone', 'isNew', function () {
      const isNew = this.isNew;
      if (!isNew) {
        return true;
      }
      if (this.hasPressedDone) {
        return true;
      }
      return false;
    }),
    updateVariantChildrenList({
      autoFill,
      autoFillCommand,
      isNew,
      onAfter
    }) {
      const model = this.model;
      const originalModel = this.originalModel || {};
      if (RA.isNilOrEmpty(isNew)) {
        isNew = R.pathOr(false, ['args', 'isNew'])(this);
      }
      if (RA.isNotNilOrEmpty(model)) {
        model.updateVariantChildrenList({
          originalModel,
          autoFill,
          autoFillCommand,
          isNew,
          onAfter
        });
      }
    },
    activeVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(variantChildren);
    }),
    inactiveVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', '_createVariantChildrenInactiveProxy.[]', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        const permanentlyUnavailable = R.pathOr(false, ['_data', 'permanentlyUnavailable'])(variantChild);
        if (R.equals(status, 'inactive') && !permanentlyUnavailable) {
          return true;
        }
        return false;
      })(variantChildren);
    }),
    permanentlyUnavailableVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', '_createVariantChildrenInactiveProxy.[]', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const permanentlyUnavailable = R.pathOr(false, ['_data', 'permanentlyUnavailable'])(variantChild);
        if (permanentlyUnavailable) {
          return true;
        }
        return false;
      })(variantChildren);
    }),
    _createVariantChildrenActiveProxy: Ember.computed('model._data._createVariantChildren.{[],@each.synced}', function () {
      const _createVariantChildren = this?.model?._data?._createVariantChildren || [];
      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(_createVariantChildren);
    }),
    _createVariantChildrenInactiveProxy: Ember.computed('model._data._createVariantChildren.{[],@each.synced}', function () {
      const _createVariantChildren = this?.model?._data?._createVariantChildren || [];
      return R.reject(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(_createVariantChildren);
    }),
    cancelVariantsDirty() {
      this.set('isEditingVariants', false);
      this.set('isDoneEditingVariants', true);
      if (this.isNew) {
        const originalVariants = this.originalVariants || [];
        const model = this?.model || {};
        model.setData('variants', originalVariants);
        this.set('originalVariants', null);
      }
      if (!this.isEditing) {
        return;
      }
      this.crud.cancelEditing({
        adapterName: 'products',
        dirty: this.dirtyClone
      });
      this.set('dirtyClone', null);
    },
    setupVariantsDirty(model) {
      if (this.isEditing) {
        const dirtyClone = this.crud.setupDirty({
          adapterName: 'products',
          model
        });
        this.set('dirtyClone', dirtyClone);
      }
      if (this.isNew) {
        const originalVariants = model.getData('variants') || [];
        if (RA.isNotNilOrEmpty(originalVariants)) {
          const originalVariantsClone = R.map(variant => {
            return this.dataManager.copyRecord('products/variants', variant);
          })(originalVariants);
          this.set('originalVariants', originalVariantsClone);
        }
      }
      this.set('isEditingVariants', true);
      this.set('isDoneEditingVariants', false);
    },
    doneEditingVariants(model) {
      const originalModel = this.originalModel;
      if (this.isEditing) {
        model.setData('variants', this?.dirtyClone?._data?.variants || []);
      }
      model.filterEmptyVariants();
      model.updateVariantChildrenList({
        originalModel,
        setInitialValue: false,
        isNew: this.isNew
      });
      this.set('hasPressedDone', true);
      this.set('isEditingVariants', false);
      this.set('isDoneEditingVariants', true);
      this.set('dirtyClone', null);
    },
    variantOptionsCanBeSaved: Ember.computed('model._data.variants.{[],@each.synced}', 'dirtyClone._data.variants.{[],@each.synced}', 'isEditing', function () {
      let isAllowed = true;
      let variantDataToCheck = this?.model?._data?.variants || [];
      if (this.isEditing) {
        variantDataToCheck = this?.dirtyClone?._data?.variants || [];
      }
      R.forEach(variant => {
        const name = variant.getData('name');
        if (RA.isNilOrEmpty(name)) {
          const options = variant?._data?.options || [];
          R.forEach(option => {
            const optionName = option?._data?.name || '';
            if (RA.isNotNilOrEmpty(optionName)) {
              isAllowed = false;
            }
          })(options);
        }
      })(variantDataToCheck);
      return isAllowed;
    }),
    actions: {
      addVariant() {
        const model = this.model;
        if (this.isEditing && RA.isNotNilOrEmpty(this.dirtyClone)) {
          this.dirtyClone.addVariant();
        } else {
          model.addVariant();
        }
      },
      removeVariant(model, variant) {
        const msg = this.intl.t('are you sure you want to remove');
        if (window.confirm(msg)) {
          model.removeVariant(variant);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "updateVariantChildrenList", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateVariantChildrenList"), _obj), _applyDecoratedDescriptor(_obj, "cancelVariantsDirty", [_dec2], Object.getOwnPropertyDescriptor(_obj, "cancelVariantsDirty"), _obj), _applyDecoratedDescriptor(_obj, "setupVariantsDirty", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setupVariantsDirty"), _obj), _applyDecoratedDescriptor(_obj, "doneEditingVariants", [_dec4], Object.getOwnPropertyDescriptor(_obj, "doneEditingVariants"), _obj), _obj));
});