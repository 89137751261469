define("client/pods/blogs/lists/font-weight/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'normal',
        label: 'font_weight_normal'
      }, {
        value: 'bold',
        label: 'font_weight_bold'
        // }, {
        //   value: 'lighter',
        //   label: 'font_weight_lighter'
        // }, {
        //   value: 'bolder',
        //   label: 'font_weight_bolder'
      }];
      return _nventor.default.resolve(data);
    }
  });
});