define("client/pods/components/transporters/payuni-seven-eleven/pickup-location/editor-extension/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersPayuniSevenElevenPickupLocationEditorExtensionComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('synced'), _dec4 = Ember._action, _class = class TransportersPayuniSevenElevenPickupLocationEditorExtensionComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "synced", _descriptor2, this);
    }
    get list() {
      const subType = this.args?.model?._data?.subType || '';
      const payuniLists = {
        transporterAccount: R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-payuni-seven-eleven-accounts'])(this),
        transporterType: R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-payuni-seven-eleven-type'])(this)
      };
      return R.pathOr([], [subType])(payuniLists);
    }
    get isDropdown() {
      const subType = this.args?.model?._data?.subType || '';
      const dropdownSubTypes = ['transporterAccount', 'transporterType'];
      return dropdownSubTypes.includes(subType);
    }
    get isC2C() {
      const details = this.args.model?._data?.details || [];
      const isC2C = details.some(detail => {
        if (detail._data.subType === 'transporterType' && detail._data.value === 'C2C') {
          return true;
        }
        return false;
      });
      return isC2C;
    }
    setTransporterType() {
      this.synced = Math.random().toString().slice(2);
      if (!this.isC2C) {
        return;
      }

      // Reset Return Store Data when B2C
      const resetKeyValues = ['storeId', 'storeName', 'storeAddress'];
      const details = this.args.model?._data?.details || [];
      R.forEach(key => {
        const detailModel = details.find(detail => detail._data.subType === key);
        if (!detailModel) {
          return;
        }
        detailModel.setData('value', '');
      })(resetKeyValues);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "synced", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isC2C", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isC2C"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTransporterType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterType"), _class.prototype), _class);
});