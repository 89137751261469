define("client/pods/dispatch/lists/processes/menu/waybills/seven-eleven/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: 'undispatched',
        statuses: [{
          showBtnForStatus: true,
          btnClassNames: 'u-margin-left-10',
          value: 'noWaybills',
          label: 'shippingStatus_noWaybill'
        }, {
          showBtnForStatus: true,
          btnClassNames: 'u-margin-left-10',
          value: 'awaitingDispatch',
          label: 'shippingStatus_awaitingDispatch'
        }]
      }, {
        label: 'dispatched',
        statuses: [{
          showBtnForStatus: false,
          value: 'shipped',
          label: 'shippingStatus_shipped'
        }]
      }, {
        label: 'returns',
        statuses: [{
          showBtnForStatus: false,
          value: 'returns',
          label: 'shippingStatus_toReturn'
        }]
      }];
      return _nventor.default.resolve(data);
    }
  });
});