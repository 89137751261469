define("client/pods/channels/shop/campaigns/details/filters/model", ["exports", "client/pods/filters/model", "client/utils/nventor", "client/pods/filters/sort/model", "client/constants"], function (_exports, _model, _nventor, _model2, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsDetailsFiltersModel = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _class = class ChannelsShopCampaignsDetailsFiltersModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "settings", _descriptor, this);
      _initializerDefineProperty(this, "results", _descriptor2, this);
      _initializerDefineProperty(this, "_data", _descriptor3, this);
    }
    defaults() {
      const baseDefaults = super.defaults() || {};
      return {
        ...baseDefaults,
        tags: [],
        setTypes: [],
        recurringFilters: [],
        hasImages: '',
        price: '',
        item: '',
        itemCode: '',
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        status: [],
        shopProductsSort: this.settings.settingsModel?._data?.shopProductsSort || 'recommended',
        statuses: [_constants.default.productsStatus.draft, _constants.default.productsStatus.soldOut, _constants.default.productsStatus.preOrder, _constants.default.productsStatus.inactive, _constants.default.productsStatus.active],
        sort: [_nventor.default.createModel(_model2.default, {
          by: 'index',
          direction: 'ASC'
        })]
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});