define("client/pods/grapesjs/plugins/slider-native/components/Slider", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "ramda"], function (_exports, _constants, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global lory */
  var _default = (dc, config = {}) => {
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const {
      frameName,
      prevSelector,
      nextSelector,
      sliderName,
      slidesName,
      prevName,
      nextName,
      dotsName,
      dotName,
      sliderId,
      prevId,
      nextId,
      frameId,
      slidesId,
      slideId
    } = _constants.default;
    const {
      onReIndex = foo => foo,
      onSetSchedule = foo => foo,
      onDbClick = foo => foo,
      intl = {
        t: foo => foo
      }
      /*    parentComponent */
    } = config;
    const defaultModelTraits = [{
      type: 'checkbox',
      label: intl.t('gjs-fix-visible-items'),
      name: 'fix-visible-items',
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'checkbox',
      label: intl.t('gjs-smooth-scroll'),
      name: 'smooth-scroll',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'checkbox',
      label: intl.t('gjs-infinite'),
      name: 'infinite',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'checkbox',
      label: intl.t('gjs-rewind'),
      name: 'rewind',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'select',
      label: intl.t('gjs-ease'),
      name: 'ease',
      changeProp: 1,
      options: ['ease', 'linear', 'ease-in', 'ease-out', 'ease-in-out'],
      allowWithSmoothScroll: false
    }, {
      type: 'number',
      label: intl.t('gjs-slide-speed'),
      name: 'slide-speed',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'number',
      label: intl.t('gjs-rewind-speed'),
      name: 'rewind-speed',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'number',
      label: intl.t('gjs-slides-to-scroll'),
      name: 'slides-to-scroll',
      changeProp: 1,
      allowWithSmoothScroll: false
    }, {
      type: 'number',
      label: intl.t('gjs-total-items'),
      name: 'total-items',
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-slides-per-page'),
      name: 'slides-per-page',
      changeProp: 1,
      options: ['1', '2', '3', '4'],
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-arrows-position'),
      name: 'arrows-position',
      changeProp: 1,
      options: [{
        id: 'outside',
        name: intl.t('gjs-outside')
      }, {
        id: 'inside',
        name: intl.t('gjs-inside')
      }],
      allowWithSmoothScroll: false
    }, {
      type: 'select',
      label: intl.t('gjs-arrows-display'),
      name: 'arrows-display',
      changeProp: 1,
      options: [{
        id: 'visible',
        name: intl.t('gjs-visible')
      }, {
        id: 'hidden',
        name: intl.t('gjs-hidden')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-arrows-size'),
      name: 'arrows-size',
      changeProp: 1,
      options: [{
        id: 'small',
        name: intl.t('small')
      }, {
        id: 'medium',
        name: intl.t('medium')
      }, {
        id: 'large',
        name: intl.t('large')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'color',
      label: intl.t('gjs-arrows-color'),
      name: 'arrows-color',
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'checkbox',
      label: intl.t('gjs-arrows-background-hidden'),
      name: 'arrows-background-hidden',
      changeProp: 1,
      allowWithSmoothScroll: true
    },
    // Dots
    {
      type: 'select',
      label: intl.t('gjs-dots-display'),
      name: 'dots-display',
      changeProp: 1,
      options: [{
        id: 'visible',
        name: intl.t('gjs-visible')
      }, {
        id: 'hidden',
        name: intl.t('gjs-hidden')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-dots-size'),
      name: 'dots-size',
      changeProp: 1,
      options: [{
        id: 'small',
        name: intl.t('small')
      }, {
        id: 'medium',
        name: intl.t('medium')
      }, {
        id: 'large',
        name: intl.t('large')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-dots-position'),
      name: 'dots-position',
      changeProp: 1,
      options: [{
        id: 'outside',
        name: intl.t('gjs-outside')
      }, {
        id: 'inside',
        name: intl.t('gjs-inside')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-dots-style'),
      name: 'dots-style',
      changeProp: 1,
      options: [{
        id: 'dot',
        name: intl.t('gjs-dots-style-dot')
      }, {
        id: 'line',
        name: intl.t('gjs-dots-style-line')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'color',
      label: intl.t('gjs-dots-color'),
      name: 'dots-color',
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'color',
      label: intl.t('gjs-dots-active-color'),
      name: 'dots-active-color',
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-default-sliders-status'),
      name: 'default-sliders-status',
      changeProp: 1,
      options: [{
        id: 'active',
        name: intl.t('gjs-status-active')
      }, {
        id: 'inactive',
        name: intl.t('gjs-status-inactive')
      }],
      allowWithSmoothScroll: true
    }, {
      type: 'button',
      label: intl.t('gjs-schedules'),
      name: 'schedules',
      full: true,
      command: () => onSetSchedule(),
      text: intl.t('set schedules'),
      changeProp: 1,
      allowWithSmoothScroll: true
    }, {
      type: 'button',
      label: intl.t('gjs-re-index'),
      name: 're-index',
      full: true,
      command: () => onReIndex(),
      text: intl.t('re-index'),
      changeProp: 1,
      allowWithSmoothScroll: true
    },
    // Extra options
    {
      type: 'checkbox',
      label: intl.t('gjs-autoplay'),
      name: 'autoplay',
      changeProp: true,
      allowWithSmoothScroll: true
    }, {
      type: 'select',
      label: intl.t('gjs-autoplay-delay'),
      name: 'autoplay-delay',
      changeProp: true,
      options: [{
        id: '1',
        name: 1
      }, {
        id: '2',
        name: 2
      }, {
        id: '3',
        name: 3
      }, {
        id: '5',
        name: 5
      }, {
        id: '8',
        name: 8
      }],
      allowWithSmoothScroll: true
    }];
    const defaultModelToolbar = [
    // @TODO: Move traits to modal
    // {
    //   attributes: { class: 'fas fa-pencil-alt' },
    //   command (editor) {
    //     const slider = editor.getSelected()
    //     onDbClick(slider.getEl(), {
    //       slider,
    //       editor
    //     })
    //   }
    // },
    {
      attributes: {
        class: 'fas fa-level-up-alt'
      },
      command: 'select-parent'
    }, {
      attributes: {
        class: 'fas fa-arrows-alt'
      },
      command: 'tlb-move'
    }, {
      attributes: {
        class: 'far fa-clone'
      },
      command: 'tlb-clone'
    }, {
      attributes: {
        class: 'fas fa-trash'
      },
      command: 'tlb-delete'
    }];
    const defaultArrowsColor = '#000';
    const defaultDotsColor = '#EEE';
    const defaultDotsActiveColor = '#41ABE5';
    dc.addType(sliderName, {
      model: defaultModel.extend({
        defaults: {
          ...defaultModel.prototype.defaults,
          name: intl.t('slider'),
          // Slider arrows color
          // <- [ img ] ->
          'arrows-background-hidden': true,
          // Slider arrows color
          // <- [ img ] ->
          'arrows-color': defaultArrowsColor,
          // Slider arrows
          // <- [ img ] ->
          'arrows-size': 'medium',
          // Slider arrows
          // <- [ img ] ->
          'arrows-position': 'inside',
          // Slider arrows
          //    [ img ]
          'arrows-display': 'visible',
          // Slider dots color
          // [ img ]
          // * * *
          'dots-style': 'dot',
          // Slider dots color
          // [ img ]
          // * * *
          'dots-color': defaultDotsColor,
          // Slider dots color
          // [ img ]
          // * * *
          'dots-active-color': defaultDotsActiveColor,
          // Slider dots
          // [ img ]
          // * * *
          'dots-size': 'medium',
          // Slider dots
          // [ img ]
          // * * *
          'dots-position': 'outside',
          // Slider dots
          //    [ img ]
          'dots-display': 'visible',
          // Slides scrolled at once
          'slides-per-page': '1',
          // Slides scrolled at once
          'total-items': 3,
          // Slides scrolled at once
          'slides-to-scroll': 1,
          // Enabled mouse events
          'enable-mouse-events': false,
          // Time in milliseconds for the animation of a valid slide attempt
          'slide-speed': 300,
          // Time in milliseconds for the animation of the rewind after the last slide
          'rewind-speed': 600,
          // Time for the snapBack of the slider if the slide attempt was not valid
          'snap-back-speed': 200,
          // Like carousel, works with multiple slides. (do not combine with rewind)
          infinite: false,
          // Like carousel, works with multiple slides. (do not combine with rewind)
          'smooth-scroll': true,
          // Like carousel, works with multiple slides. (do not combine with rewind)
          'fix-visible-items': true,
          // If slider reached the last slide, with next click the slider goes
          // back to the startindex. (do not combine with infinite)
          rewind: false,
          // Cubic bezier easing functions: http://easings.net/de
          ease: 'ease',
          // Cubic bezier easing functions: http://easings.net/de
          autoplay: false,
          // Cubic bezier easing functions: http://easings.net/de
          'autoplay-delay': '5',
          droppable: `${prevSelector}, ${nextSelector}`,
          classes: ['lory-slider', 'gjs-lory-slider-min-width'],
          traits: defaultModelTraits,
          toolbar: defaultModelToolbar,
          'script-deps': config.script,
          'class-frame': config.classFrame,
          'class-slides': config.classSlides,
          'class-prev': config.classPrev,
          'class-next': config.classNext,
          // Define which properties to pass (this will also reset your script on their changes)
          'script-props': ['autoplay', 'smooth-scroll', 'autoplay-delay'],
          script
        },
        init() {
          // update the amount of slides per page by resizing the slide
          this.on('change:slides-per-page', this.onSlidesPerPageUpdate);

          // Update the total amount of slides
          this.on('change:total-items', this.onSlidesCountUpdate);

          // Update the total amount of slides
          this.on('change:smooth-scroll', this.onSmoothScrollUpdate);

          // Update slider arrows position
          this.on('change:arrows-position', this._onArrowsPositionUpdate);

          // Update slider arrows display
          this.on('change:arrows-display', this._onArrowsDisplayUpdate);

          // Update slider arrows display
          this.on('change:arrows-size', this._onArrowsSizeUpdate);

          // Update slider arrows display
          this.on('change:arrows-color', this._onArrowsColorUpdate);

          // Update slider arrows display
          this.on('change:arrows-background-hidden', this._onArrowsBackgroundUpdate);

          // Update slider dots position
          this.on('change:dots-position', this._onDotsPositionUpdate);

          // Update slider dots display
          this.on('change:dots-display', this._onDotsDisplayUpdate);

          // Update slider dots size
          this.on('change:dots-size', this._onDotsSizeUpdate);

          // Update slider dots color
          this.on('change:dots-color', this._onDotsColorUpdate);

          // Update slider dots color
          this.on('change:dots-style', this._onDotsStyleUpdate);

          // Update slider dots color
          this.on('change:dots-active-color', this._onDotsActiveColorUpdate);

          // Update slider items display
          this.on('change:fix-visible-items', this._onFixVisibleItemsUpdate);

          // Update slider default sliders statuses
          this.on('change:default-sliders-status', this._onDefaultSlidersStatusUpdate);
          this.on('change:schedules', this._onSchedulesUpdate);

          // update trains base on previous user settings
          this.onSmoothScrollUpdate(this, true);
        },
        /**
         * Public adapter for the slider, to be called outside grapesjs
         * @param {boolean} value - display or hide arrows background
         */
        updateArrowsBackground(value) {
          this.set('arrows-background-hidden', value);
        },
        /**
         * Public adapter for the slider, to be called outside grapesjs
         * @param {string} value - hex color
         */
        updateArrowsColor(value) {
          this.set('arrows-color', value);
        },
        /**
         * Public adapter for the slider, to be called outside grapesjs
         * @param {string} value - size of arrows | small, medium, large
         */
        updateArrowsSize(value) {
          this.set('arrows-size', value);
        },
        /**
         * Public adapter for the slider, to be called outside grapesjs
         * @param {string} value - arrows position | insideImage, outsideImage, hide
         */
        updateArrowsPosition(value) {
          const arrowsPositionKey = 'arrows-position';
          const arrowsDisplayKey = 'arrows-display';
          if (value === 'outsideImage' || value === 'insideImage') {
            this.set(arrowsDisplayKey, 'visible');
          }
          if (value === 'hide') {
            this.set(arrowsDisplayKey, 'hidden');
          } else {
            value = value.replace(/Image$/g, '');
            this.set(arrowsPositionKey, value);
          }
        },
        updateScheddules(schedules = []) {
          const schedulesStr = JSON.stringify(schedules);
          const schedulesKey = 'schedules';
          this.set(schedulesKey, schedulesStr);
        },
        /**
         * Public adapter for the slider, to be called outside grapesjs
         * @param {string} value - dots Display | visible, hidden
         */
        updateDotsDisplay(value = 'visible') {
          const dotsDisplayKey = 'dots-display';
          this.set(dotsDisplayKey, value);
        },
        _onFixVisibleItemsUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const slideAutoWidth = 'gjs-lory-slide-auto-width';
          const slideArray = model?.findType('lory-slide') || [];
          if (value) {
            slideArray.forEach(slide => {
              slide.removeClass(slideAutoWidth);
            });
            // Dots are not allowed when more than one slides are visible
            this.updateDotsDisplay('visible');
          } else {
            slideArray.forEach(slide => {
              slide.addClass(slideAutoWidth);
            });
            this.updateDotsDisplay('hidden');
          }
        },
        _onArrowsBackgroundUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const loryPrev = model?.findType('lory-prev')[0];
          const loryNext = model?.findType('lory-next')[0];
          const arrowsBackgroundClassNames = 'hidden-background';
          if (value) {
            loryPrev.addClass(arrowsBackgroundClassNames);
            loryNext.addClass(arrowsBackgroundClassNames);
          } else {
            loryPrev.removeClass(arrowsBackgroundClassNames);
            loryNext.removeClass(arrowsBackgroundClassNames);
          }
        },
        _onArrowsColorUpdate(model, value) {
          const editor = this.em.getEditor();
          editor.Css.setRule('.lory-controls-arrows-color', {
            color: value
          });
        },
        _onArrowsSizeUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const loryPrev = model?.findType('lory-prev')[0];
          const loryNext = model?.findType('lory-next')[0];
          const classNameSizeSmall = 'size-small';
          const classNameSizeMedium = 'size-medium';
          const classNameSizeLarge = 'size-large';
          const arrowsSizeClassNames = [classNameSizeSmall, classNameSizeMedium, classNameSizeLarge];
          arrowsSizeClassNames.forEach(className => {
            loryPrev.removeClass(className);
            loryNext.removeClass(className);
          });
          switch (value) {
            case 'small':
              loryPrev.addClass(classNameSizeSmall);
              loryNext.addClass(classNameSizeSmall);
              break;
            case 'medium':
              loryPrev.addClass(classNameSizeMedium);
              loryNext.addClass(classNameSizeMedium);
              break;
            case 'large':
              loryPrev.addClass(classNameSizeLarge);
              loryNext.addClass(classNameSizeLarge);
              break;
            default:
              console.log('Unknown arrows size');
              break;
          }
        },
        _onArrowsDisplayUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const loryPrev = model?.findType('lory-prev')[0];
          const loryNext = model?.findType('lory-next')[0];
          const classControlsArrowsHidden = 'lory-controls-arrows--always-hidden';
          switch (value) {
            case 'visible':
              loryPrev.removeClass(classControlsArrowsHidden);
              loryNext.removeClass(classControlsArrowsHidden);
              break;
            case 'hidden':
              loryPrev.addClass(classControlsArrowsHidden);
              loryNext.addClass(classControlsArrowsHidden);
              break;
            default:
              console.log('Unknown value for arrows-display');
              break;
          }
        },
        _onArrowsPositionUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const slideArray = model?.findType('lory-slide') || [];
          const classNameFrameInsideArrows = 'gjs-lory-frame--inside-arrows';
          const classNameSlideInsideArrows = 'gjs-lory-slide--inside-arrows';
          switch (value) {
            case 'outside':
              frame.removeClass(classNameFrameInsideArrows);
              slideArray.forEach(slide => {
                slide.removeClass(classNameSlideInsideArrows);
              });
              break;
            case 'inside':
              frame.addClass(classNameFrameInsideArrows);
              slideArray.forEach(slide => {
                slide.addClass(classNameSlideInsideArrows);
              });
              break;
            default:
              console.log('Unknown arrows position!!!');
              break;
          }
        },
        _onDotsStyleUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const dotModelArray = model?.findType('lory-dot');
          const classNameDotStyleLine = 'lory-dot--style-line';
          switch (value) {
            case 'dot':
              dotModelArray.forEach(dotModel => {
                dotModel.removeClass(classNameDotStyleLine);
              });
              break;
            case 'line':
              dotModelArray.forEach(dotModel => {
                dotModel.addClass(classNameDotStyleLine);
              });
              break;
            default:
              console.log('Unknown arrows position!!!');
              break;
          }
        },
        _onDotsColorUpdate(model, value) {
          const editor = this.em.getEditor();
          editor.Css.setRule('.lory-dot-color', {
            'background-color': value
          });
        },
        _onDotsActiveColorUpdate(model, value) {
          const editor = this.em.getEditor();
          editor.Css.setRule('.lory-dot-color.dot-active-color', {
            'background-color': value
          });
        },
        _onDefaultSlidersStatusUpdate(model, value) {
          model.addAttributes({
            'data-gjs-default-sliders-status': value
          });
        },
        _onDotsSizeUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const dotModelArray = model?.findType('lory-dot');
          const classNameSizeSmall = 'size-small';
          const classNameSizeMedium = 'size-medium';
          const classNameSizeLarge = 'size-large';
          const arrowsSizeClassNames = [classNameSizeSmall, classNameSizeMedium, classNameSizeLarge];
          arrowsSizeClassNames.forEach(className => {
            dotModelArray.forEach(dotModel => {
              dotModel.removeClass(className);
            });
          });
          switch (value) {
            case 'small':
              dotModelArray.forEach(dotModel => {
                dotModel.addClass(classNameSizeSmall);
              });
              break;
            case 'medium':
              dotModelArray.forEach(dotModel => {
                dotModel.addClass(classNameSizeMedium);
              });
              break;
            case 'large':
              dotModelArray.forEach(dotModel => {
                dotModel.addClass(classNameSizeLarge);
              });
              break;
            default:
              console.log('Unknown dot size');
              break;
          }
        },
        _onDotsDisplayUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const dotsModel = model?.findType(dotsName)[0];
          const classControlsArrowsHidden = `${dotsName}--hidden`;
          const classNameMarginZero = 'lory-slider--margin-zero';
          switch (value) {
            case 'visible':
              dotsModel.removeClass(classControlsArrowsHidden);
              model.removeClass(classNameMarginZero);
              break;
            case 'hidden':
              dotsModel.addClass(classControlsArrowsHidden);
              model.addClass(classNameMarginZero);
              break;
            default:
              console.log('Unknown value for dot-display');
              break;
          }
        },
        _onDotsPositionUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const dotsModel = model?.findType(dotsName)[0];
          const classNameInsideDots = 'lory-dots--inside-dots';
          const classNameMarginZero = 'lory-slider--margin-zero';
          switch (value) {
            case 'outside':
              dotsModel.removeClass(classNameInsideDots);
              model.removeClass(classNameMarginZero);
              break;
            case 'inside':
              dotsModel.addClass(classNameInsideDots);
              model.addClass(classNameMarginZero);
              break;
            default:
              console.log('Unknown arrows position!!!');
              break;
          }
        },
        _onSchedulesUpdate(model, value) {
          const frame = model?.findType('lory-frame')[0];
          if (!frame) {
            return;
          }
          const slideArray = model?.findType('lory-slide') || [];
          if (value) {
            const schedules = R.pluck('schedulesAttributes')(JSON.parse(value)) || [];
            const applyToAll = R.pathOr('false', [0, 'data-gjs-model-applytoall'])(schedules);
            slideArray.map((slide, index) => {
              let schedule = {};
              if (applyToAll) {
                schedule = schedules[0] || {};
              } else {
                schedule = schedules[index] || {};
              }
              if (schedule) {
                const scheduleStr = schedule['data-gjs-model-schedule'];
                slide.addAttributes({
                  'data-gjs-model-applytoall': applyToAll,
                  'data-gjs-model-schedule': scheduleStr
                });
              }
            });
          }
        },
        onSmoothScrollUpdate(model, value) {
          const ulSlides = model.findType('lory-slides')?.[0];
          if (!ulSlides) {
            return;
          }

          // Remove all traits
          defaultModelTraits.forEach(({
            name
          }) => {
            model.removeTrait(name);
          });
          const classNameSlides = 'gjs-lory-slides';
          const classNameSlidesSmoothScroll = 'gjs-lory-slides__smooth-scroll';
          if (value) {
            ulSlides.removeClass(classNameSlides);
            ulSlides.addClass(classNameSlidesSmoothScroll);

            // @TODO: add traits on next release
            // add only compatible traits
            const traits = defaultModelTraits.filter(trait => {
              const allowWithSmoothScroll = trait.allowWithSmoothScroll;
              const sliderPerPage = parseInt(model.get('slides-per-page'), 10);
              const isDotTrait = trait.name.includes('dot');
              if (sliderPerPage > 1 && isDotTrait) {
                return false;
              }
              if (allowWithSmoothScroll) {
                return true;
              }
              return false;
            }) || [];
            traits.forEach(trait => {
              model.addTrait(trait);
            });
          } else {
            ulSlides.removeClass(classNameSlidesSmoothScroll);
            ulSlides.addClass(classNameSlides);

            // @TODO: add traits on next release
            // Add all default traits
            defaultModelTraits.forEach(trait => {
              model.addTrait(trait);
            });
          }
        },
        onSlidesCountUpdate(model, count) {
          count = parseInt(count, 10);
          const slides = model.findType('lory-slides')[0];
          const dotsModel = model?.findType(dotsName)[0];
          const totalSlides = slides.components().length || 0;
          const addSlides = count > totalSlides;
          const slideArray = slides.components();
          const dotsArray = dotsModel.components();
          if (addSlides) {
            const slidesToAdd = count - totalSlides;
            for (let i = 0; i < slidesToAdd; i++) {
              slideArray.add({
                tagName: 'li',
                type: 'lory-slide',
                classes: ['gjs-lory-slide-min-width', 'gjs-lory-slide', `gjs-lory-slide-id-${config.slideId}`, 'js_slide']
              });
              dotsArray.add({
                tagName: 'li',
                type: dotName
              });
            }
          } else {
            const slidesToRemoveCount = totalSlides - count;
            if (slidesToRemoveCount > 0) {
              const slidesToRemove = R.takeLast(slidesToRemoveCount)(slideArray);
              slidesToRemove.forEach(slide => {
                slides.components().remove(slide);
              });

              // Remove Dots
              const dotsToRemove = R.takeLast(slidesToRemoveCount)(dotsArray);
              dotsToRemove.forEach(slide => {
                dotsModel.components().remove(slide);
              });
            }
          }
          this.onSlidesPerPageUpdate(model, model.get('slides-per-page'));
        },
        onSlidesPerPageUpdate(model, slidesPerPage = 0) {
          slidesPerPage = parseInt(slidesPerPage, 10);
          const slidesPerPageClassNames = ['gjs-lory-slide-min-width', 'gjs-lory-slide-min-width__2_slides', 'gjs-lory-slide-min-width__3_slides', 'gjs-lory-slide-min-width__4_slides'];
          const innerSlides = model.findType('lory-slide') || [];
          innerSlides.forEach(slideModel => {
            slidesPerPageClassNames.forEach(className => {
              slideModel.removeClass(className);
            });
            if (slidesPerPage === 1) {
              slideModel.addClass(slidesPerPageClassNames[0]);
              // Dots are not allowed with 1 slide per page
              this.updateDotsDisplay('visible');
            } else {
              this.updateDotsDisplay('hidden');
              slideModel.addClass(slidesPerPageClassNames[slidesPerPage - 1]);
            }
          });
        }
      }, {
        isComponent(el) {
          if (el.hasAttribute && el.hasAttribute(sliderId)) {
            return {
              type: sliderName
            };
          }
        }
      }),
      view: defaultView.extend({
        init() {
          const props = ['fix-visible-items', 'dots-style', 'dots-color', 'dots-active-color', 'dots-size', 'dots-display', 'dots-position', 'arrows-color', 'arrows-size', 'arrows-display', 'arrows-position', 'arrows-background-hidden', 'total-items', 'slides-per-page', 'smooth-scroll', 'slides-to-scroll', 'enable-mouse-events', 'slide-speed', 'rewind-speed', 'snap-back-speed', 'infinite', 'rewind', 'ease'];
          const reactTo = props.map(prop => `change:${prop}`).join(' ');
          this.listenTo(this.model, reactTo, this.render);
          const comps = this.model.components();

          // Add a basic template if it's not yet initialized
          if (!comps.length) {
            comps.add(`
          <div data-gjs-type="${frameName}" class="${frameName}">
            <ul class="slides js_slides" data-gjs-type="${slidesName}">
                ${config.slideEls}
            </ul>
          </div>
          <span data-gjs-type="${prevName}">${config.prevEl}</span>
          <span data-gjs-type="${nextName}">${config.nextEl}</span>
          <ul data-gjs-type="${dotsName}">
            <li data-gjs-type="${dotName}" class="${dotName} lory-dot-color dot-active-color"></li>
            <li data-gjs-type="${dotName}" class="${dotName} lory-dot-color"></li>
            <li data-gjs-type="${dotName}" class="${dotName} lory-dot-color"></li>
          </ul>
        `);
          }
        },
        onRender({
          model,
          editor,
          el
        }) {
          model.onSmoothScrollUpdate(model, true);
          model._onArrowsPositionUpdate(model, 'inside');

          // Set autoplay off on GJS
          // this.model.set('should-autoplay', false)
          el.setAttribute('data-gjs-should-autoplay', false);
        }
      })
    });
    function script(props) {
      if (!window.GJSOptions) {
        window.GJSOptions = {};
      }
      let shouldAutoplay = false;
      const shouldAutoplayEl = this.getAttribute('data-gjs-should-autoplay');
      if (!shouldAutoplayEl) {
        shouldAutoplay = true;
      }
      // const shouldAutoplay = props['should-autoplay'] === true
      const autoplay = props.autoplay;
      const autoplayDelay = props['autoplay-delay'] || 3;
      const smoothScroll = props['smooth-scroll'];
      const sliderKey = 'sliderLory';
      const el = this;
      // const deps = '{[ script-deps ]}'
      // const falsies = ['0', 'false']
      // let infinite = '{[ infinite ]}'
      // infinite = infinite === 'true' ? 1 : parseInt(infinite, 10)
      // let fixVisibleItems = '{[ fix-visible-items ]}'
      // fixVisibleItems = fixVisibleItems === 'true' ? 1 : parseInt(fixVisibleItems, 10)
      // let smoothScroll
      // try {
      //   smoothScroll = JSON.parse('{[ smooth-scroll ]}') || false
      // } catch (error) {
      //   smoothScroll = false
      // }
      // const options = {
      //   slidesToScroll: parseInt('{[ slides-to-scroll ]}', 10),
      //   totalItems: parseInt('{[ total-items ]}', 10),
      //   enableMouseEvents: falsies.indexOf('{[ enable-mouse-events ]}') >= 0 ? 0 : 1,
      //   infinite: isNaN(infinite) ? false : infinite,
      //   fixVisibleItems: isNaN(fixVisibleItems) ? false : fixVisibleItems,
      //   rewind: !(falsies.indexOf('{[ rewind ]}') >= 0),
      //   slideSpeed: parseInt('{[ slide-speed ]}', 10),
      //   rewindSpeed: parseInt('{[ rewind-speed ]}', 10),
      //   snapBackSpeed: parseInt('{[ snap-back-speed ]}', 10),
      //   slidesPerPage: '{[ slides-per-page ]}',
      //   ease: '{[ ease ]}',
      //   arrowsPosition: '{[ arrows-position ]}',
      //   arrowsBackground: '{[ arrows-background-hidden ]}',
      //   arrowsDisplay: '{[ arrows-display ]}',
      //   classNameFrame: '{[ class-frame ]}',
      //   classNameSlideContainer: '{[ class-slides ]}',
      //   classNamePrevCtrl: '{[ class-prev ]}',
      //   classNameNextCtrl: '{[ class-next ]}'
      // }

      const classNameActiveDot = 'dot-active-color';
      const classNameLoryControlsArrowsHidden = 'lory-controls-arrows--hidden';
      const getCurrentSlides = () => el.querySelectorAll('.lory-dot') || [];
      const allDotsArray = getCurrentSlides();
      const querySelectorSlides = el.querySelector('.lory-slides');
      const getPixelsToMove = () => {
        try {
          const result = el.querySelector('.gjs-lory-slide').clientWidth;
          return Math.round(result);
        } catch (error) {
          console.error('Error getting pixels to move', error);
          return 0;
        }
      };
      const setDotAsActive = ({
        index,
        shouldUpdatePosition = false,
        isArrowClick = false,
        isDotClick = false
      } = {}) => {
        if (allDotsArray.length === 0) {
          el.style.display = 'none';
          return;
        }
        if (allDotsArray.length === 1) {
          const allDotsArrayContainer = el.querySelector('.lory-dots');
          allDotsArrayContainer.style.display = 'none';
        }
        if (!isArrowClick && !isDotClick) {
          // Remove active class from all dots
          allDotsArray.forEach(dot => {
            dot.classList.remove(classNameActiveDot);
          });
          allDotsArray[index].classList.add(classNameActiveDot);
          // updateArrowVisibility(index)
        }
        if (shouldUpdatePosition) {
          const pixelsToMove = getPixelsToMove();
          querySelectorSlides.scrollLeft = index * pixelsToMove;
        }
        try {
          const pixelsToMove = getPixelsToMove();
          const slidesCount = getCurrentSlides().length;
          const scrollLeft = pixelsToMove * index;
          const scrollLeftRounded = Math.round(scrollLeft);
          const scrollWidthRounded = pixelsToMove * slidesCount;
          const clientWidthRounded = pixelsToMove;
          const hideLArrows = scrollLeftRounded === 0;
          const hideRArrows = scrollWidthRounded - clientWidthRounded === scrollLeftRounded;
          // Show both arrows
          // el.querySelector('.lory-prev').classList.remove(classNameLoryControlsArrowsHidden)
          // el.querySelector('.lory-next').classList.remove(classNameLoryControlsArrowsHidden)

          if (hideLArrows) {
            // Hide left arrow
            el.querySelector('.lory-prev').classList.add(classNameLoryControlsArrowsHidden);
          } else {
            el.querySelector('.lory-prev').classList.remove(classNameLoryControlsArrowsHidden);
          }
          if (hideRArrows) {
            // Hide right arrow
            el.querySelector('.lory-next').classList.add(classNameLoryControlsArrowsHidden);
          } else {
            el.querySelector('.lory-next').classList.remove(classNameLoryControlsArrowsHidden);
          }
        } catch (error) {
          console.error('Error while updating arrows visibility', error);
        }
      };
      setDotAsActive({
        index: 0
      });

      // Always remove the previous interval so the new one can be added
      const autoplayIntervalId = window.GJSOptions[this.id]?.autoplayIntervalId;
      if (autoplayIntervalId) {
        clearInterval(autoplayIntervalId);
        delete window.GJSOptions[this.id];
      }
      if (!smoothScroll) {
        return;
      }

      // remove bind
      if (window[sliderKey] && window[sliderKey].destroy) {
        window[sliderKey].destroy();
      }
      const onClickPrev = () => {
        const activeDot = el.querySelector(`.${classNameActiveDot}`);
        let selectedDotIndex = Array.from(allDotsArray).indexOf(activeDot);
        if (selectedDotIndex !== 0) {
          selectedDotIndex--;
        }
        setDotAsActive({
          index: selectedDotIndex,
          shouldUpdatePosition: true,
          isArrowClick: true,
          isSettingActiveSlide: true
        });
      };
      const onClickNext = () => {
        const activeDot = el.querySelector(`.${classNameActiveDot}`);
        let selectedDotIndex = Array.from(allDotsArray).indexOf(activeDot);
        const indexOfLastDot = allDotsArray.length - 1;
        if (selectedDotIndex < indexOfLastDot) {
          selectedDotIndex++;
        }
        setDotAsActive({
          index: selectedDotIndex,
          shouldUpdatePosition: true,
          isArrowClick: true,
          isSettingActiveSlide: true
        });
      };
      el.querySelector('.lory-prev').addEventListener('click', onClickPrev);
      el.querySelector('.lory-next').addEventListener('click', onClickNext);

      // Dots events
      allDotsArray.forEach(dotElement => {
        dotElement.onclick = event => {
          const searchElement = event.target;
          const selectedDotIndex = Array.from(allDotsArray).indexOf(searchElement);
          setDotAsActive({
            index: selectedDotIndex,
            shouldUpdatePosition: true,
            isDotClick: true
          });
        };
      });
      const onScrollEvent = event => {
        const slides = event.target;
        const pixelsToMove = getPixelsToMove();
        if (slides.scrollLeft === 0 && pixelsToMove === 0) {
          return;
        }
        const rawValue = slides.scrollLeft / pixelsToMove;
        const selectDotIndex = Math.round(rawValue);
        setDotAsActive({
          index: selectDotIndex,
          shouldUpdatePosition: false
        });
      };
      querySelectorSlides.addEventListener('scroll', event => {
        const GJSOptionsByComponentId = window.GJSOptions[this.id] || {};
        const throttlePause = GJSOptionsByComponentId.throttlePause;
        if (throttlePause) {
          return;
        }
        GJSOptionsByComponentId.throttlePause = true;
        window.GJSOptions[this.id] = Object.assign({}, GJSOptionsByComponentId);
        setTimeout(() => {
          GJSOptionsByComponentId.throttlePause = false;
          window.GJSOptions[this.id] = Object.assign({}, GJSOptionsByComponentId);
          onScrollEvent(event);
        }, 500);
      });
      if (shouldAutoplay && autoplay) {
        const intervalMs = autoplayDelay * 1000;
        const intervalId = setInterval(() => {
          const GJSOptionsByComponentId = window.GJSOptions[this.id] || {};
          // const autoplayIntervalId = GJSOptionsByComponentId.autoplayIntervalId
          const GJSOptionsByComponentIdCount = GJSOptionsByComponentId.count || 1;

          // when gets to the end should jump to the start
          if (GJSOptionsByComponentIdCount >= querySelectorSlides.childElementCount) {
            setDotAsActive({
              index: 0,
              shouldUpdatePosition: true,
              isArrowClick: true
            });
            // console.log('props.autoplayIntervalId::end of slide', autoplayIntervalId)
            // clearInterval(autoplayIntervalId)
            window.GJSOptions[this.id] = Object.assign({}, window.GJSOptions[this.id], {
              count: 1
            });
            // delete window.GJSOptions[this.id]
            return;
          }
          onClickNext();
          GJSOptionsByComponentId.count = GJSOptionsByComponentIdCount + 1;
          window.GJSOptions[this.id] = Object.assign({}, window.GJSOptions[this.id], GJSOptionsByComponentId);
        }, intervalMs);
        const GJSOptionsByComponentId = window.GJSOptions[this.id] || {};
        window.GJSOptions[this.id] = Object.assign({}, GJSOptionsByComponentId, {
          autoplayIntervalId: intervalId
        });
      }
      return;
    }
  };
  _exports.default = _default;
});