define("client/pods/components/documents/editor-main/editor-btns-display/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: '',
    server: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    documentsService: Ember.inject.service('documents'),
    showVerticalDispatchProcessBar: false,
    isReQueued: false,
    showReQueueBtn: Ember.computed('model._data.isTempDocNo', function () {
      const twoMinLater = this.dateService.getMoment(this.model?._data?.timestampZ).add(30, 'seconds');
      const nowZ = this.dateService.getNowZ();
      if (twoMinLater.isAfter(nowZ)) {
        return false;
      }
      if (this.model?._data?.isTempDocNo) {
        return true;
      }
      return false;
    }),
    reQueueToGenerateDocumentNumber: (0, _emberConcurrency.task)(function* (model) {
      const results = yield this.server.call('PATCH', `api/protected/documents/${model?._data?.docType}/${model?._data?.docName}/number/${model._data._key}`, {
        tempDocNo: model?._data?.docNo
      });
      this.set('isReQueued', true);
    }).drop(),
    setupDirty() {
      // @TODO: Move to load instructions
      const model = this.model;
      const dirty = this.crud.setupDirty({
        adapterName: model.get('adapterName'),
        model
      });
      this.set('dirtyModel', dirty);
    }
  }, _applyDecoratedDescriptor(_obj, "setupDirty", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupDirty"), _obj), _obj));
});